import { makeStyles } from '@material-ui/core/styles';

export const useFieldStyles = makeStyles(() => ({
  contentForm: {
    display: 'flex',
    flexDirection: 'column',
    '& > hr': {
      padding: 0,
    }
  },
  actions: {
    '&.MuiGrid-container ': {
      paddingTop: '3rem',
      '& > div:first-child': {
        marginRight: 16
      }
    }
  },
  actionsOnly: {
    boxSizing: 'border-box',
    display: 'flex',
    gap: '1.875rem',
    justifyContent: 'flex-end',
    padding: '1.875rem',
    width: '100%',
    '&.MuiGrid-container ': {
      padding: 24,
      '& > div:first-child': {
        marginRight: 16
      }
    },
  },
  content: {
    padding: '8px 24px'
  },
  circularProgress: {
    display: 'flex',
    padding: '0 18px',
  },
  buttonWithIcon: {
    padding: 0,
  },
  tooltip: {
    // TODO make a tooltip component that we can use accros our app
    backgroundColor: 'white',
    border: '1px solid #C0C3CF',
    color: '#000000',
    fontSize: 14
  },
  removePadding: {
    padding: 0
  }
}));