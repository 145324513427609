import React from 'react';
import { Tooltip, Typography } from '@mui/material';

import { StatusChip } from 'components';
import { FILE_CATEGORY_LABELS, FILE_STATUS_LABELS } from 'enums';
import { formatDateTimeWith12HourClock } from 'helpers';
import { Column, FileError, FileType } from 'types';

export const FilesTableColumns : Partial<Column[]> = [
  { id: 'name', label: 'Name', sortable: false},
  { id: 'created_by', label: 'Created By', sortable: false},
  { id: 'created_at', label: 'Created At', sortable: false, customRender: (file: FileType) => formatDateTimeWith12HourClock(file.created_at)},
  { id: 'category', label: 'Category', sortable: false, customRender: (file: FileType) => FILE_CATEGORY_LABELS[file.category]},
  { id: 'status', label: 'Status', sortable: false, customRender: (file: FileType) => <StatusChip status={FILE_STATUS_LABELS[file.status]} />},
];

export const FilesErrorsTableColumns : Partial<Column[]> = [
  { id: 'row', label: 'Row Number', sortable: false},
  { id: 'message', label: 'Error Message', sortable: false, customRender: (error: FileError) =>
    <Tooltip title={error.message} placement='top'>
      <Typography noWrap style={{ cursor: 'pointer' }}>{error.message}</Typography>
    </Tooltip>},
];