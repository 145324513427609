import React, { useMemo, useState } from 'react';
import { QueryObserverResult, RefetchOptions, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  ConfirmationDialog,
  ModalComponent,
  TableMenu
} from 'components';
import { WORKING_COPY_PERMISSIONS } from 'permissions';
import { DeleteIcon, Pencil } from 'resources';
import { useDeleteWorkingCopyMutation} from 'services';
import { PaginatedQueryResult, WorkingCopy } from 'types';

type DeleteWorkingCopyModalProps = {
  workingCopyId: number,
  onCancel: () => void,
  refetchData: (options?: RefetchOptions) => Promise<QueryObserverResult<PaginatedQueryResult<WorkingCopy>, unknown>>
};

const DeleteWorkingCopyModal = ({workingCopyId, onCancel, refetchData} : DeleteWorkingCopyModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteWorkingCopy, isLoading } = useDeleteWorkingCopyMutation(queryClient, {
    onSuccess: () => {
      refetchData();
      onCancel();
      toast.success('Working copy successfully deleted.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={() => deleteWorkingCopy(workingCopyId)}
      onCancel={onCancel}/>
  );
};

type WorkingCopiesTableMenuProps = {
  workingCopy: WorkingCopy,
  refetchData: (options?: RefetchOptions) => Promise<QueryObserverResult<PaginatedQueryResult<WorkingCopy>, unknown>>
};

export const WorkingCopiesTableMenu = ({workingCopy, refetchData} : WorkingCopiesTableMenuProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isDeleteWorkingCopyModalOpen, setIsDeleteWorkingCopyModalOpen ] = useState<boolean>(false);

  const deleteWorkingCopyHandler = () => {
    setIsDeleteWorkingCopyModalOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [WORKING_COPY_PERMISSIONS.UPDATE],
      text: 'Edit',
      icon: Pencil,
      urlTo: `/working-copies/${workingCopy.id}/edit`,
    },
    {
      permissions: [WORKING_COPY_PERMISSIONS.DELETE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteWorkingCopyHandler,
    },
  ], [workingCopy]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>
      <ModalComponent
        message={`Are you sure you want to delete working copy "${workingCopy.name}"?`}
        isOpen={isDeleteWorkingCopyModalOpen}
        onCancel={() => setIsDeleteWorkingCopyModalOpen(false)}>
        <DeleteWorkingCopyModal workingCopyId={workingCopy.id} onCancel={() => setIsDeleteWorkingCopyModalOpen(false)} refetchData={refetchData}/>
      </ModalComponent>
    </>
  );
};