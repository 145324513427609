import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    padding: '0 1.8rem',
    '& button': {
      height: '2.5rem',
      minWidth: '2.5rem',
      padding: 0,
    },
    '& span': {
      margin: 0
    },
  },
  container: {
    borderRadius: '20px',
    boxShadow: '0rem 0.24rem 1rem rgba(0, 0, 0, 0.04)',
    marginBottom: '1.5rem',
    padding: '1.5rem 0',
    width: '100%',
  },
  divider: {
    margin: '0.5rem 0'
  },
  editIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    padding: '0 1.8rem',
    '& h4': {
      color: theme.palette.neutral.neutral700,
    }
  },
  inputContent: {
    boxSizing: 'border-box',
    padding: '0 1.8rem',
    '& fieldset': {
      display: 'none'
    },
    '& textarea': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral400,
      marginTop: '1.25rem',
      maxHeight: '10rem',
      overflowY: 'auto !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& ::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: theme.palette.neutral.neutral400,
      borderRadius: '10px',
    },
  },
  iconContainer: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '2.5rem',
    justifyContent: 'center',
    width: '2.5rem',
    '& svg': {
      color: theme.palette.common.white,
      fontSize: '1.5rem',
    }
  },
  notesHeight: {
    '& textarea': {
      height: '10rem !important',
    },
  },
  purpleGradientBackground: {
    background: 'linear-gradient(rgba(243, 232, 255, 100), rgba(204, 157, 255, .37))',
  },
  purpleBackground: {
    background: '#A93AFF',
  },
  removeHeaderMargin: {
    marginBottom: 0
  },
  textareaCursor: {
    '& textarea': {
      cursor: 'default',
    },
  },
  yellowGradientBackground: {
    background: 'linear-gradient(rgba(255, 242, 215, 100), rgba(255, 217, 142, .55))',
  },
  yellowBackground: {
    background: '#FF947A',
  },
})
);
