import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_URL } from '../config';
import { IntegrationLog, IntegrationLogDetail, PaginatedQueryResult } from 'types';

const INTEGRATION_LOG_API_URL = '/v1/integration-log';
const INTEGRATION_LOG_QUERY_KEY = 'integration-log';

export const api = {
  integrationLogs: {
    get: (id: number) => axios.get(`${API_URL}${INTEGRATION_LOG_API_URL}/${id}/`),
    list: (filters: string, size: number, page: number, ordering: string) => axios.get(`${API_URL}${INTEGRATION_LOG_API_URL}/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
  }
};

export const useIntegrationLogsQuery = (filters: string, size: number, page: number, ordering: string, queryOptions= {}) => {
  return useQuery<PaginatedQueryResult<IntegrationLog>>([ INTEGRATION_LOG_QUERY_KEY, { filters, size, page, ordering }],
                                                        () => api.integrationLogs.list(filters, size, page, ordering),
                                                        { ...queryOptions, enabled: !!filters, keepPreviousData: true }
  );
};

export const useIntegrationLogQuery = (integrationLogId: number) => {
  return useQuery<AxiosResponse<IntegrationLogDetail>>([ INTEGRATION_LOG_QUERY_KEY, integrationLogId ], () => api.integrationLogs.get(integrationLogId), { enabled: !!integrationLogId, });
};