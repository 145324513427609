import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  equipmentIcon: {
    color: '#0d6ba4',
    fontSize: '2.175rem'
  },
  hazmatIcon: {
    height: '2.7rem',
    paddingBottom: '5px',
    paddingLeft: '5px',
    width: '3rem'
  },
  quoteStops: {
    marginRight: '.4rem',
    '&.MuiGrid-item': {
      paddingLeft: 16
    }
  },
}));

