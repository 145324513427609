import PropTypes from 'prop-types';

export const SadStates = ({ states = [], children = null }) => {
  const sadState = states.find(c => (typeof c.when === 'function' ? c.when() : c.when));

  if (sadState) {
    return sadState.render();
  }

  return typeof children === 'function' ? children() : children;
};

SadStates.propTypes = {
  states: PropTypes.arrayOf(
    PropTypes.shape({
      when: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
      render: PropTypes.func.isRequired
    })
  )
};
