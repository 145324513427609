import { makeStyles} from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  equipmentIconText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral700,
    backgroundColor: theme.palette.primary.primary100,
    width: 'auto',
    minHeight: '1.5rem',
    borderRadius: '1.25rem',
    padding: '0 0.7rem',
    gap: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  equipmentEnabled: {
    color: theme.palette.primary.primary700,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.primary200,
      color: theme.palette.primary.primary700,
    },
  },
  equipmentDisabled: {
    cursor: 'default',
  }
}));