import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  auto_response: {
    '& .MuiTypography-root': {
      ...theme.typography.bodyMediumS,
    },
  },
  enabled_auto_response: {
    color: theme.palette.success.main,
  },
}));