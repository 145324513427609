import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  root: {
    '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
      ...theme.typography.bodyRegularS,
      backgroundColor: theme.palette.neutral.neutral0,
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
      color: theme.palette.neutral.neutral700,
      flexWrap: 'nowrap',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.primary700}`,
      },
      '&.MuiInput-underline:after': {
        content: 'none'
      },
      '&.MuiInput-underline:before': {
        content: 'none'
      },
      '& .MuiIconButton-root:hover': {
        backgroundColor: theme.palette.neutral.neutral0,
      },
      '& .MuiAutocomplete-popupIndicator': {
        marginRight: '1rem',
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.primary.primary700}`,
        '& .MuiTouchRipple-child': {
          backgroundColor: theme.palette.neutral.neutral0,
        },
        '& > div.MuiAutocomplete-endAdornment': {
          '& > button.MuiAutocomplete-popupIndicator': {
            backgroundColor: theme.palette.neutral.neutral0,
          },
          '& .MuiAutocomplete-clearIndicator': {
            backgroundColor: theme.palette.neutral.neutral0,
          },
        },
      },
      '& > .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
        padding: (isHeader) => isHeader ? '17px 9px' : '0.5rem 1rem'
      },
    },
    '& .MuiFormHelperText-root': {
      ...theme.typography.bodyRegularXS,
      color: theme.palette.error.main,
    },
  },
  option: {
    ...theme.typography.bodyRegularS,
    padding: '0.5rem 1rem',
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
  },
  noOption: {
    ...theme.typography.bodyRegularS,
    padding: '0.5rem 1rem',
  },
  paper: {
    border: `1px solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    padding: 0,
  },
  hiddenElement: {
    display: 'none',
  },
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
  errorField: {
    '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
      borderColor: theme.palette.error.main,
    }
  },
  placeholder: {
    '& input::placeholder': {
      color: theme.palette.neutral.neutral400,
      opacity: 1,
    },
  },
  listbox: {
    ...scrollStyle as CSSProperties,
    overflowY: 'scroll',
    padding: 0,
  }
}));