import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  saveFiltersIconsContainer: {
    alignItems: 'center',
    gap: '0.62rem',
    display: 'flex',
    width: '2.375rem',
    '& .MuiCircularProgress-svg': {
      color: theme.palette.primary.primary700,
      width: '1.125rem',
    },
  },
  saveFiltersIcon: {
    color: theme.palette.primary.primary700,
    cursor: 'pointer',
    fontSize: '1.25rem',
    fontWeight: 900,
  },
  saveFiltersMenuArrowIcon: {
    color: theme.palette.neutral.neutral700,
    cursor: 'pointer',
  },
  menu: {
    borderRadius: '8px',
    boxShadow: `0px 2px 8px ${theme.palette.secondary.secondary200}`,
    marginLeft: '-2rem',
    marginTop: '2.25rem',
    overflowY: 'hidden',
    width: '14rem',
    '& ul': {
      border: `1px solid ${theme.palette.primary.primary700}`,
      borderRadius: '0.5rem',
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
      padding: 0,
      '& .MuiDivider-root': {
        margin: 0
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: theme.palette.primary.primary100,
      borderRadius: '0.5rem',
      color: theme.palette.primary.primary700,
    },
  },
  savedFilterSetsContainer: {
    ...scrollStyle as CSSProperties,
    maxHeight: '18rem',
    width: '100%',
  },
  menuItem: {
    alignItems: 'start',
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral700,
    display: 'flex',
    '&.MuiButtonBase-root': {
      padding: '0.5rem 1rem',
      width: '100%',
      '&.MuiListItem-button:hover': {
        background: theme.palette.primary.primary100,
        borderRadius: '0.5rem',
        color: theme.palette.primary.primary700,
      },
      '& .MuiListItemText-root': {
        width: '90%',
        '& .MuiTypography-root': {
          ...theme.typography.bodyRegularS,
        },
      },
    },
    '&.MuiButtonBase-root:hover': {
      background: `${theme.palette.primary.primary100} !important`,
      borderRadius: '0.5rem',
    },
    '&.MuiButtonBase-root:focus': {
      background: theme.palette.neutral.neutral0,
    },
    '& svg': {
      fill: theme.palette.neutral.neutral700,
      '& path': {
        fill: theme.palette.neutral.neutral700,
      },
    },
    '& svg:hover': {
      fill: theme.palette.primary.primary700,
      '& path': {
        fill: theme.palette.primary.primary700,
      },
    },
    '&.MuiButtonBase-root:has(svg:hover):not(:has(.MuiListItemText-root:hover)):hover': {
      color: theme.palette.neutral.neutral700,
    },
  },
  menuItemText: {
    ...theme.typography.bodyRegularS,
    lineClamp: 1,
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'min-content',
    whiteSpace: 'nowrap',
  },
  disabledMenuItem: {
    borderRadius: '0.5rem',
    '& .MuiTypography-root': {
      ...theme.typography.bodyRegularS,
    },
    '&.MuiButtonBase-root:hover': {
      background: theme.palette.neutral.neutral0,
      cursor: 'default',
    },
  },
  filterSetsActionsContainer: {
    borderBottom: `1px solid ${theme.palette.primary.primary200}`,
  },
  divider: {
    alignItems: 'center',
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral400,
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    '&.MuiButtonBase-root': {
      padding: '0.5rem 1rem',
    },
    '& .MuiTypography-root': {
      ...theme.typography.bodyRegularXS,
    },
    '&.MuiListItem-button:hover': {
      background: theme.palette.neutral.neutral0,
    },
    '& hr': {
      backgroundColor: theme.palette.neutral.neutral100,
    },
    '& svg': {
      color: theme.palette.primary.primary700,
    },
  },
  circuralProgressStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center !important',
    padding: 20,
    '& .MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.primary700,
    },
  },
  menuItemIconContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
  },
}));