import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  cell: {
    padding: 0
  },
  skeleton: {
    height: '48px !important'
  },
  tableContainer: {
    maxHeight: '68vh',
    [theme.breakpoints.down(1537)]: {
      maxHeight: '60vh',
    },
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    background: theme.palette.neutral.neutral0,
    boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.04)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: theme.palette.neutral.neutral100,
      borderRadius: '1rem',
    },
    '&::-webkit-scrollbar-track:horizontal': {
      background: 'transparent'
    }
  },
  tableMessage: {
    fontSize: 18,
    padding: '1rem',
  },
  headerCell: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    background: theme.palette.neutral.neutral0,
    whiteSpace: 'nowrap',
    borderBottomColor: theme.palette.primary.primary200,
  },
  pointer: {
    cursor: 'pointer'
  },
  tableRow: {
    '&.MuiTableRow-root ': {
      padding: 0,
      '&.MuiTableCell-root': {
        ...theme.typography.bodyRegularS,
        color: theme.palette.neutral.neutral700,
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.primary.primary200,
        alignItems: 'center',
        padding: '0.25rem 1rem',
        height: '3rem',
        '&.MuiTableCell-root:first-child': {
          paddingRight: 10,
          minWidth: '3.125rem',
        },
        '& > div, b': {
          ...theme.typography.bodyRegularS,
          lineHeight: '140%',
        }
      }
    },
    '&.MuiTableRow-hover:hover': {
      background: theme.palette.primary.primary100
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
  },
  errorRow: {
    backgroundColor: '#f78f8f',
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: '#fee2e2'
    }
  },
  sortLabel: {
    '&.MuiTableSortLabel-root': {
      ...theme.typography.bodyRegularS,
      lineHeight: '140%',
      color: theme.palette.neutral.neutral400,
      gap: '0.3rem',
      display: 'flex',
      alignItems: 'center'
    },
  },
  checkboxTableCell: {
    width: '2rem',
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      borderRadius: 'inherit',
      color: theme.palette.secondary.secondary500,
      margin: '0.5rem',
      padding: 0,
      width: '1rem',
      height: '1rem',
    }
  }
}));