import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { CreateWorkingCopy, Opts, PaginatedQueryResult, UpdateWorkingCopy, WorkingCopy } from 'types';
import { API_URL } from '../config';

const WORKING_COPY_API_URL = '/v1/working-copy';
const WORKING_COPY_QUERY_KEY = 'working-copy';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  workingCopies: {
    list: (filters: string, size: number, page: number, ordering: string) => axios.get(`${API_URL}${WORKING_COPY_API_URL}/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
    get: (id: number) => axios.get(`${API_URL}${WORKING_COPY_API_URL}/${id}`),
    delete: (id: number) => axios.delete(`${API_URL}${WORKING_COPY_API_URL}/${id}`),
    create: (payload: CreateWorkingCopy[]) => axios.post(`${API_URL}${WORKING_COPY_API_URL}/`, payload),
    update: ({ id, payload }: { id:number, payload: UpdateWorkingCopy }) => axios.patch(`${API_URL}${WORKING_COPY_API_URL}/${id}/`, payload)
  }
};

export const useWorkingCopiesQuery = (filters: string, size: number, page: number, ordering: string, queryOptions= {}) => {
  return useQuery<PaginatedQueryResult<WorkingCopy>>([ WORKING_COPY_QUERY_KEY, { filters, size, page, ordering }],
                                                     () => api.workingCopies.list(filters, size, page, ordering),
                                                     { ...queryOptions, enabled: !!filters, keepPreviousData: true }
  );
};

export const useGetWorkingCopyById = (id: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<WorkingCopy>>([ WORKING_COPY_QUERY_KEY, id ], () => api.workingCopies.get(id), { ...queryOptions, enabled: !!id });
};

export const useDeleteWorkingCopyMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation<unknown, AxiosError<{error: string}>, unknown>(api.workingCopies.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(WORKING_COPY_QUERY_KEY);
    },
    onError: (error) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useEditWorkingCopyMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation<unknown, AxiosError<{error: string}>, unknown>(api.workingCopies.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(WORKING_COPY_QUERY_KEY);
      opts.onSuccess();
    },
    onError: (error) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });