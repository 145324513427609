import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Card, CardContent } from '@mui/material';
import clsx from 'clsx';

import {
  AccessControl,
  ActionButton,
  ActionMenuButton,
  ConfirmationDialog,
  MarkQuoteLostModal,
  MarkQuoteRejectedModal,
  MarkQuoteRespondedModal,
  MarkQuoteWonModal,
  ModalComponent
} from 'components';
import { useAuth } from 'contexts';
import {
  CURRENCY_TYPE,
  DIRECT_EMAIL_RESPONDED_HEADER,
  DIRECT_EMAIL_REJECTED_HEADER,
  MARK_QUOTE_LOST_HEADER,
  MARK_QUOTE_REJECTED_HEADER,
  MARK_QUOTE_WON_HEADER,
  MAX_PRICE_VALUE,
  QUOTE_COST_TYPE,
  QUOTE_SOURCE,
  QUOTE_STATUS,
  MARK_QUOTE_RESPONDED_HEADER
} from 'enums';
import {
  checkPermissions,
  getDirectMarkModalHeader,
  useCountdown,
} from 'helpers';
import { QUOTE_PERMISSIONS } from 'permissions';
import {
  useMarkQuoteMutation,
  useSearchTransplaceAuction
} from 'services';
import { ActionMenuButtonConfig, PriceCurrency, QuoteDetails as QuoteDetailsType } from 'types';
import { QuotedPriceForm } from './components/QuotedPriceForm';

import { useStyles } from './QuoteDetailsPage.css';

type QuoteActionsProps = {
    quote: QuoteDetailsType
  };

export const QuoteActions = ({ quote } : QuoteActionsProps) => {
  const formRef = useRef();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const [ priceCurrency, setPriceCurrency ] = useState<PriceCurrency>({
    price: Math.round(quote.quoted_price) || 0,
    currency: quote.currency || CURRENCY_TYPE.USD
  });
  const [ costType, setCostType ] = useState<QUOTE_COST_TYPE>(quote.cost_type || QUOTE_COST_TYPE.ALLIN);
  const isPriceValid = priceCurrency.price > 0 && priceCurrency.price <= MAX_PRICE_VALUE && typeof Number(priceCurrency.price);

  const [ isWonDialogOpen, setIsWonDialogOpen ] = useState<boolean>(false);
  const [ isLostDialogOpen, setIsLostDialogOpen ] = useState<boolean>(false);
  const [ isRespondedDialogOpen, setIsRespondedDialogOpen ] = useState<boolean>(false);
  const [ isMarkAsRespondedDialogOpen, setIsMarkAsRespondedDialogOpen ] = useState<boolean>(false);
  const [ isRejectedDialogOpen, setIsRejectedDialogOpen ] = useState<boolean>(false);
  const [ isMarkAsRejectedDialogOpen, setIsMarkAsRejectedDialogOpen ] = useState<boolean>(false);

  const { mutate: markAsResponded, isLoading: isMarkingQuote } = useMarkQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        setIsMarkAsRespondedDialogOpen(false);
        toast.success('Quote successfully marked as Responded');
      }
    }
  );

  const { isSuccess: hasFetchedAuctionData, data: auctionData, refetch: refetchSearchTransplaceAuction } = useSearchTransplaceAuction(quote.id, quote.quote_source === QUOTE_SOURCE.TRANSPLACE);
  const { data: auction } = auctionData || {};
  const [ counter, hasCounterExpired ] = useCountdown(auction?.timeLeft);

  const handleMarkAsResponded = () => {
    markAsResponded({
      id: quote.id,
      status: QUOTE_STATUS.RESPONDED,
      price: priceCurrency.price,
      currency: priceCurrency.currency,
      cost_type: costType,
    });
  };

  const hasQuoteBestBidPrice = auction?.bestBidAmount && auction.bestBidAmount >= quote.quoted_price;

  const markRespondedOptions: ActionMenuButtonConfig[] = [
    {
      main: true,
      label: 'Mark Responded',
      onClick: () => setIsMarkAsRespondedDialogOpen(true),
    },
    {
      label: 'Mark Rejected',
      isVisible: checkPermissions(currentUser.permissions, [QUOTE_PERMISSIONS.REJECT]),
      onClick: () => setIsMarkAsRejectedDialogOpen(true),
    },
  ];

  const directRespondOptions: ActionMenuButtonConfig[] = [
    {
      main: true,
      label: 'Direct Respond',
      onClick: () => setIsRespondedDialogOpen(true),
    },
    {
      label: 'Direct Reject',
      isVisible: checkPermissions(currentUser.permissions, [QUOTE_PERMISSIONS.REJECT]),
      onClick: () => setIsRejectedDialogOpen(true),
    },
  ];

  const removeMarginForStatusButtons = () => {
    return quote.quote_source === QUOTE_SOURCE.TRANSPLACE ? quote.status !== QUOTE_STATUS.OPEN && quote.status !== QUOTE_STATUS.RESPONDED : quote.status !== QUOTE_STATUS.OPEN;
  };

  return (
    <>
      <Card className={classes.contentContainer}>
        <CardContent>
          <Grid container direction='row' justify='space-between' spacing={4}>
            <Grid item>
              <QuotedPriceForm
                quote={quote}
                formRef={formRef}
                priceCurrency={priceCurrency}
                setPriceCurrency={setPriceCurrency}
                costType={costType}
                setCostType={setCostType} />
            </Grid>
            <Grid item className={classes.markActionsWrapper}>
              <Grid container direction='row' spacing={3}>
                {quote.status === QUOTE_STATUS.OPEN && (
                  <AccessControl permissions={[QUOTE_PERMISSIONS.RESPOND]}>
                    <ActionMenuButton
                      disabled={!isPriceValid}
                      config={markRespondedOptions}
                      variant='primary' />
                  </AccessControl>
                )}
                {(quote.status === QUOTE_STATUS.OPEN || (quote.quote_source === QUOTE_SOURCE.TRANSPLACE && quote.status === QUOTE_STATUS.RESPONDED)) && (
                  <AccessControl permissions={[QUOTE_PERMISSIONS.RESPOND]}>
                    <ActionMenuButton
                      disabled={!isPriceValid}
                      config={directRespondOptions}
                      variant='secondary' />
                  </AccessControl>
                )}
                <Divider orientation='vertical' variant='middle' flexItem
                  className={clsx({
                    [classes.divider]: true,
                    [classes.margin0]: removeMarginForStatusButtons(),
                  })} />
                <AccessControl permissions={[QUOTE_PERMISSIONS.MARK_QUOTE]}>
                  <Grid item className={clsx({
                    [classes.detailMarkActionsWrapper]: true,
                    [classes.margin0]: removeMarginForStatusButtons(),
                  })}>
                    <ActionButton
                      startIcon={<CheckIcon />}
                      text='Mark as Won'
                      colorVariant='green'
                      disabled={quote.status === QUOTE_STATUS.WON || quote.status === QUOTE_STATUS.REJECTED || !quote.quoted_price}
                      handleClick={() => setIsWonDialogOpen(true)} />
                    <ActionButton
                      startIcon={<CloseIcon />}
                      text='Mark as Lost'
                      colorVariant='red'
                      disabled={!(quote.status === QUOTE_STATUS.RESPONDED || quote.status === QUOTE_STATUS.OPEN || quote.status === QUOTE_STATUS.EXPIRED) || !quote.quoted_price}
                      handleClick={() => setIsLostDialogOpen(true)} />
                  </Grid>
                </AccessControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' justify='flex-start'>
            {hasFetchedAuctionData && (quote.quote_source === QUOTE_SOURCE.TRANSPLACE && (quote.status === QUOTE_STATUS.OPEN || quote.status === QUOTE_STATUS.RESPONDED)) &&
                <Grid className={classes.quoteAuctionDetails}>
                  {!hasQuoteBestBidPrice && (
                    <>
                      <Typography>Current best bid amount: {auction?.bestBidAmount ? `$${auction?.bestBidAmount}` : 'No current bid'}</Typography>
                      <Typography>Next possible bid amount: {auction?.maxBid === null ? 'N/A' : `$${auction?.maxBid}`}</Typography>
                    </>
                  )}
                  <Typography>{ hasCounterExpired ? 'Auction time expired' : `Time left to bid: ${counter}`}</Typography>
                </Grid>
            }
          </Grid>
        </CardContent>
      </Card>

      <ModalComponent
        message={MARK_QUOTE_WON_HEADER}
        isOpen={isWonDialogOpen}
        onCancel={() => setIsWonDialogOpen(false)}>
        <MarkQuoteWonModal quoteId={quote?.id} price={priceCurrency.price} onCancel={() => setIsWonDialogOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={MARK_QUOTE_LOST_HEADER}
        isOpen={isLostDialogOpen}
        onCancel={() => setIsLostDialogOpen(false)}>
        <MarkQuoteLostModal quoteId={quote?.id} onCancel={() => setIsLostDialogOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={MARK_QUOTE_RESPONDED_HEADER}
        isOpen={isMarkAsRespondedDialogOpen}
        onCancel={() => setIsMarkAsRespondedDialogOpen(false)}>
        <ConfirmationDialog
          isActionInProgress={isMarkingQuote}
          onSubmit={handleMarkAsResponded}
          onCancel={() => setIsMarkAsRespondedDialogOpen(false)}
          primaryButtonLabel='Confirm'
          cancelButtonLabel='Cancel' />
      </ModalComponent>

      <ModalComponent
        message={getDirectMarkModalHeader(quote.quote_source, DIRECT_EMAIL_RESPONDED_HEADER)}
        isOpen={isRespondedDialogOpen}
        onCancel={() => setIsRespondedDialogOpen(false)}>
        <MarkQuoteRespondedModal
          quote={quote}
          isDirect
          refetchTransplaceAuction={refetchSearchTransplaceAuction}
          priceCurrency={priceCurrency}
          onCancel={() => setIsRespondedDialogOpen(false)} />
      </ModalComponent>

      <ModalComponent
        message={MARK_QUOTE_REJECTED_HEADER}
        isOpen={isMarkAsRejectedDialogOpen}
        onCancel={() => setIsMarkAsRejectedDialogOpen(false)}>
        <MarkQuoteRejectedModal quote={quote} onCancel={() => setIsMarkAsRejectedDialogOpen(false)} />
      </ModalComponent>

      <ModalComponent
        message={getDirectMarkModalHeader(quote.quote_source, DIRECT_EMAIL_REJECTED_HEADER)}
        isOpen={isRejectedDialogOpen}
        onCancel={() => setIsRejectedDialogOpen(false)}>
        <MarkQuoteRejectedModal quote={quote} isDirect onCancel={() => setIsRejectedDialogOpen(false)} />
      </ModalComponent>
    </>
  );
};