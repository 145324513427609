import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  circuralProgressStyle: {
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    padding: 20,
    '& .MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.primary700,
    },
  },
  chipContainer: {
    display: 'flex',
    gap: '.4rem',
    height: '1.5rem',
    maxWidth: 300,
    minWidth: 120,
    overflow: 'hidden',
    width: '100%',
    '& svg': {
      fill: theme.palette.primary.primary500,
      width: '1rem',
      cursor: 'move'
    }
  },
  labelText: {
    ...theme.typography.bodyRegularS,
    transform: 'translate(8px, 6px)',
  },
  placeholderText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    transform: 'translate(14px, 13px)',
  },
  primary: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-root': {
        borderColor: 'transparent',
      },
      '& .MuiPaper-root': {
        borderColor: theme.palette.primary.primary700,
      },
    },
  },
  secondary: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-root': {
        borderColor: theme.palette.secondary.secondary200,
      },
      '& .MuiPaper-root': {
        borderColor: theme.palette.primary.primary700,
      },
    }
  },
  select: {
    border: '1px solid',
    borderColor: 'transparent',
    '&.Mui-focused': {
      borderColor: (isError) => isError ? 'transparent' : theme.palette.primary.primary700,
      borderRadius: '8px',
    },
    '& .MuiPaper-root': {
      border: '1px solid',
      borderRadius: '8px',
      marginTop: '.5rem',
      '& .MuiList-padding': {
        padding: 0,
      },
      '& .MuiList-root': {
        maxWidth: '100%',
      },
      '& li': {
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.primary.primary100,
          color: theme.palette.primary.primary700,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.primary100,
        },
      },
    },
    '& .MuiPopover-root': {
      cursor: 'default'
    },
    '& .MuiSelect-root': {
      ...theme.typography.bodyRegularS,
      backgroundColor: theme.palette.neutral.neutral0,
      border: '1px solid',
      borderRadius: '8px',
      boxSizing: 'border-box',
      height: '2.5rem',
      justifyContent: 'center',
      overflow: 'hidden',
      paddingLeft: '0.5rem',
      paddingRight: '2.5rem',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.primary700}`,
      },
      '&:focus': {
        border: `1px solid ${theme.palette.primary.primary200}`,
      },
    },
    '& .MuiSelect-icon': {
      margin: 'auto 1rem',
    },
  },
  optionsContainer: {
    ...scrollStyle as CSSProperties,
    maxHeight: '18rem',
  },
  search: {
    width: '100%',
    '& > div:first-child': {
      width: 'auto',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      '& > div': {
        display: 'flex',
        justifyContent: 'center'
      }
    }
  },
  selectedChip: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.neutral.neutral0,
    border: `1px solid ${theme.palette.primary.primary500}`,
    borderRadius: '12px',
    height: '1.5rem',
  },
  selectError: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-root': {
        border: `1px solid ${theme.palette.error.main}`
      }
    }
  },
  selectMenuItem: {
    ...theme.typography.bodyRegularS,
    height: '2.5rem',
    boxSizing: 'border-box',
    '& .MuiButtonBase-root': {
      padding: 0
    },
    '& svg': {
      fill: theme.palette.primary.primary700,
      marginRight: '.8rem'
    },
    '& .MuiTypography-body1': {
      ...theme.typography.bodyRegularS,
    },
  },
  singleSelectedValue: {
    height: '1.5rem',
  },
  validationError: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.error.main
  },
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
}));
