import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  app: {
    backgroundColor: theme.palette.primary.primary100,
    display: 'flex',
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '.6rem',
    transition: 'transform 0.3s',
    '& h2': {
      color: theme.palette.neutral.neutral700,
    },
    '& svg': {
      color: theme.palette.primary.primary700,
      cursor: 'pointer',
      fontSize: '1.25rem',
    },
  },
  main: {
    backgroundColor: theme.palette.primary.primary100,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    letterSpacing: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.5rem'
  },
  headerContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 0 1rem',
    },
  },
  content: {
    margin: '0.5rem 2rem 2rem 2rem',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& > h5': {
      paddingLeft: '18px'
    }
  },
  user: {
    display: 'flex',
  },
  logout: {
    color: theme.palette.primary.primary100,
    textTransform: 'none',
    fontSize: '1rem'
  },
  training: {
    color: theme.palette.primary.primary100,
    background: 'linear-gradient(to top left,#ffb900 0,#f95d8f 60%,#f95353);',
    letterSpacing: '1px',
    padding: '10px 50px',
    position: 'absolute',
    right: '45%',
    textShadow: '0 1px rgba(0, 0, 0, .3)',
    textTransform: 'uppercase',
  },
}));