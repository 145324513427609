import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

type Props = {
  showStartIcon: boolean;
};

export const useStyles = makeStyles<ThemeDefault, Props>((theme: ThemeDefault) => ({
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
  titleMargin: {
    marginLeft: '-1.5rem',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
      color: theme.palette.neutral.neutral700,
    },
    '& .MuiInputBase-root:has(.MuiInputBase-input:focus)': {
      border: `1px solid ${theme.palette.primary.primary700}`,
      borderRadius: '0.5rem',
    },
    '& .MuiInputBase-root:hover': {
      border: `1px solid ${theme.palette.primary.primary700}`,
      borderRadius: '0.5rem',
    },
    '& .MuiInputAdornment-root:focus': {
      border: 'none',
    },
    '& .MuiInputAdornment-root:hover': {
      border: 'none',
    },
    '& .MuiInputBase-input:focus': {
      border: 'none',
    },
    '& .MuiInputBase-input:hover': {
      border: 'none',
    },
    '& > div.MuiInput-underline:after': {
      content: 'none'
    },
    '& > div.MuiInput-underline:before': {
      content: 'none'
    },
    '& > div > input': {
      ...theme.typography.bodyRegularS,
      padding: ({showStartIcon}) => showStartIcon ? '0.5rem 0' : '0.5rem 1rem',
    },
    '& input::placeholder': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral400,
      opacity: 1,
    },
  },
  startAdornment: {
    border: 'none',
    cursor: 'pointer',
    height: '1rem',
    marginLeft: '1rem',
    width: '1rem',
    '& svg': {
      fill: theme.palette.neutral.neutral400,
      height: '1rem',
      overflow: 'visible',
      '& path': {
        stroke: theme.palette.neutral.neutral400,
        strokeWidth: '0.3rem',
      },
    },
  },
  closeIconButton: {
    width: '0.8rem',
    '& path': {
      strokeWidth: '0.05rem !important',
    },
  },
  errorField: {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: '0.5rem',
    },
  },
  dropdown: {
    position: 'relative',
    '& .MuiBox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      border: `1px solid ${theme.palette.primary.primary700}`,
      borderRadius: '0.5rem',
      marginTop: '0.5rem',
      position: 'absolute',
      width: '100%',
      zIndex: 10,
      '& li': {
        borderRadius: '0.5rem',
        '&:hover': {
          backgroundColor: theme.palette.primary.primary100,
        },
      },
    },
    '& .MuiTypography-root': {
      ...theme.typography.bodyMediumS,
    },
  },
}));