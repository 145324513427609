import * as Yup from 'yup';

export const getValidationSchema = () => {
  const validationSchema = Yup.object({
    loadNumber: Yup.string().test({
      name: 'atLeastOneRequired',
      test: function(value) {
        const isValid = value || this.parent.shipperRefNumber;
        if (isValid) {
          return true;
        }
        return this.createError({
          path: 'loadNumber',
          message: 'At least one field must be set',
        });
      }
    }),
    shipperRefNumber: Yup.string().test({
      name: 'atLeastOneRequired',
      test: function(value) {
        const isValid = value || this.parent.loadNumber;
        if (isValid) {
          return true;
        }
        return this.createError({
          path: 'shipperRefNumber',
          message: 'At least one field must be set',
        });
      }
    })
  });

  return validationSchema;
};