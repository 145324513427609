import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  rowContaier: {
    alignItems: 'center',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  columnContaier: {
    columnGap: '0.5rem',
    display: 'contents',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));