import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useStyles } from './PageStates.css';

export const PageLoadingState = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={classes.container}>
      <Grid item><CircularProgress thickness={1} size={80} color='primary'/></Grid>
      <Grid item className={classes.text}>Loading data...</Grid>
    </Grid>
  );
};