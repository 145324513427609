import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';

import { SelectFilter } from 'components';
import { Option } from 'types';

import { useStyles } from './SelectField.css';

type SelectFieldProps = {
  id: string,
  label: string,
  placeholder?: string,
  defaultValue?: string | number | string[] | number[] | (() => number | ''),
  options: Option[],
  allOption?: string,
  multiple?: boolean,
};

export const SelectField = ({ id, label, placeholder, defaultValue, options, allOption, multiple = false }: SelectFieldProps) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.title}>{label}</Typography>
      <Field name={id}>
        {({ form } : FieldProps) => (
          <FormControl error={!!form.errors[id]} className={classes.formControl}>
            <SelectFilter
              placeholder={placeholder}
              defaultValue={defaultValue || ''}
              onChange={value => form.setFieldValue(id, value)}
              options={options}
              multiple={multiple}
              allOption={allOption} />
            <FormHelperText className={classes.errorMsg}>{(form.errors as Record<string, string>)[id] || ' '}</FormHelperText>
          </FormControl>
        )}
      </Field>
    </div>
  );
};