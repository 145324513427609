export const scrollStyle = {
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    background: 'transparent',
    width: '0.35rem',
    height: '0.35rem'
  },
  '&::-webkit-scrollbar-track:horizontal': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb:horizontal': {
    background: '#D6E2ED',
    borderRadius: '1rem',
  },
  '&::-webkit-scrollbar-thumb:vertical': {
    background: '#D6E2ED',
    borderRadius: '1rem',
  },
  '&::-webkit-scrollbar-track:vertical': {
    background: 'transparent',
    margin: '0.2rem 0',
  },
};

export const themeDefault = {
  palette: {
    neutral: {
      main: '#28a0dc',
      neutral700: '#263238',
      neutral400: '#6E7F88',
      neutral100: '#F1F3F4',
      neutral0: '#FFF',
    },
    primary: {
      main: '#0d6ba4',
      primary700: '#007AFF',
      primary500: '#68B0FF',
      primary200: '#D9E9FF',
      primary100: '#F0F8FF'
    },
    secondary: {
      main: '#b1d4e5',
      secondary700: '#416388',
      secondary500: '#A0B1C4',
      secondary200: '#D6E2ED',
    },
    open: {
      main: '#BD00FF',
      light: '#F6DEFF'
    },
    warning: {
      main: '#FFB800',
      light: '#FFF7DB'
    },
    info: {
      main: '#007AFF',
      light: '#D9E9FF'
    },
    inactive: {
      main: '#6E7F88',
      light: '#F1F3F4'
    },
    error: {
      dark: '#FCCAD4',
      main: '#F3153D',
      light: '#FDDEE4'
    },
    success: {
      dark: '#C1EFD6',
      main: '#28C76F',
      light: '#DCF6E8',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Inter',
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '110%',
    },
    h2: {
      fontFamily: 'Inter',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '110%',
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '110%',
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '110%',
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '110%',
    },
    bodyM: {
      fontFamily: 'Public Sans',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '110%',
    },
    bodyMediumS: {
      fontFamily: 'Public Sans',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '110%',
    },
    bodyMediumXS: {
      fontFamily: 'Public Sans',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '140%',
    },
    bodyRegularS: {
      fontFamily: 'Public Sans',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '110%',
    },
    bodyRegularXS: {
      fontFamily: 'Public Sans',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    bodySemiboldS: {
      fontFamily: 'Public Sans',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '110%',
    },
    bodyXXS: {
      fontFamily: 'Public Sans',
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: '140%',
    }
  },
  overrides: { // Temporary workaround for rule editor
    MuiMenu: {
      list: {
        '& > li': {
          display: 'flex !important',
          padding: '6px 16px',
          justifyContent: 'flex-start'
        }
      },
    },
    MuiDialogContent: {
      root: {
        ...scrollStyle,
      },
    },
    MuiTableContainer: {
      root: {
        ...scrollStyle,
      },
    },
  },
};