import React from 'react';
import { useAuth } from 'contexts';
import { checkPermissions } from 'helpers';
import { Unauthorized } from './Unauthorized';

type AccessControlProps = {
    permissions: string[],
    children: React.ReactNode,
    isPageProtected?: boolean
}

export const AccessControl = ({
  permissions,
  children,
  isPageProtected = false
}: AccessControlProps) => {

  const { currentUser } = useAuth();

  const permitted = checkPermissions(currentUser.permissions, permissions);

  if (permitted) {
    return (
      <>
        { children }
      </>
    );
  }
  return (
    <>
      { isPageProtected ? <Unauthorized/> : null }
    </>
  );
};