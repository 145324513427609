import React, { FC } from 'react';

import ValueEditor from 'components/RuleEngine/ValueEditor';
import { ExpressionEditorBaseProps, Literal, Value} from 'types';

// TODO: Improve typing
interface LiteralEditorProps extends Omit<ExpressionEditorBaseProps, 'config'> {
  expression?: Literal;
}

const LiteralEditor: FC<LiteralEditorProps> = ({ resultType, expression, onUpdate, isReadOnly = false}) => {
  const onValueUpdate = (value: Value): void => {
    onUpdate({ ...expression, value });
  };

  return (
    <ValueEditor
      value={expression.value}
      onUpdate={onValueUpdate}
      valueType={resultType}
      isReadOnly={isReadOnly}/>
  );
};

export default LiteralEditor;
