import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { DEFAULT_ROWS_PER_PAGE, QUOTE_COST_TYPE, QUOTE_SERVICE_TYPE, QUOTE_STATUS } from 'enums';
import { Carrier, CreateQuote, ExpressionEditorConfig, Opts, PaginatedQueryResult, Quote, QuoteActivity, QuoteDetails, QuoteHistory, TransplaceAuction, UpdateQuote } from 'types';
import { API_URL } from '../config';

const QUOTE_ACTIVITIES_QUERY_KEY = 'quote_activities';
const QUOTE_CARRIER_PREFERENCE_QUERY_KEY = 'quote_carrier_preference';
const QUOTE_HISTORY_QUERY_KEY = 'quote_history';
const QUOTE_QUERY_KEY = 'quote';
const QUOTE_API_URL = '/v1/quote';
const QUOTE_SEARCH_TRANSPLACE_AUCTION_KEY= 'quote_search_transplace_auction';
const QUOTE_PRICING_RULES_EDITOR_CONFIG_QUERY_KEY = 'quote_pricing_rules_editor_config';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  quotes: {
    get: (id: number) => axios.get(`${API_URL}${QUOTE_API_URL}/${id}/`),
    list: (filters: string, size: number, page: number, ordering: string) => axios.get(`${API_URL}${QUOTE_API_URL}/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
    create: (payload: CreateQuote) => axios.post(`${API_URL}${QUOTE_API_URL}/`, payload),
    update: ({ id, payload } : {id: number, payload: UpdateQuote}) => axios.put(`${API_URL}${QUOTE_API_URL}/${id}/`, payload),
    delete: (id: number) => axios.delete(`${API_URL}${QUOTE_API_URL}/${id}/`),
    respond: ({ id, price, email, service_type, cost_type } : {id: number, price: number, email: string, service_type: QUOTE_SERVICE_TYPE, cost_type: QUOTE_COST_TYPE}) => axios.post(`${API_URL}${QUOTE_API_URL}/${id}/respond/`, { quoted_price: price, email, service_type, cost_type }),
    reject: ({id, rejection_reason, email} : {id: number, rejection_reason: string, email: string}) => axios.post(`${API_URL}${QUOTE_API_URL}/${id}/reject/`, { rejection_reason, email }),
    mark_quote: ({ id, reason, status, load_number, winning_bid, shipper_ref_number, price, currency, cost_type } :
      { id: number, reason?: string, status: QUOTE_STATUS, load_number?: string, winning_bid?: number, shipper_ref_number?: string, price?: number, currency?: string, cost_type?: QUOTE_COST_TYPE}) => axios.post(`${API_URL}${QUOTE_API_URL}/${id}/mark_quote/`, { reason, status, load_number, winning_bid, shipper_ref_number, price, currency, cost_type }),
    activities: (id: number) => axios.get(`${API_URL}${QUOTE_API_URL}/${id}/activities/`),
    savePrice: ({ id, price, currency, cost_type } : {id: number, price: number, currency: string, cost_type: QUOTE_COST_TYPE}) => axios.patch(`${API_URL}${QUOTE_API_URL}/${id}/`, { quoted_price: price, currency: currency, cost_type: cost_type }),
    saveNotes: ({ id, notes } : {id: number, notes: string}) => axios.patch(`${API_URL}${QUOTE_API_URL}/${id}/`, { notes }),
    history: ({ id, filters, offset, ordering } : {id: number, filters: string, offset: number, ordering: string}) => axios.get(`${API_URL}${QUOTE_API_URL}/${id}/history/?${filters}&ordering=${ordering}&limit=${DEFAULT_ROWS_PER_PAGE}&offset=${offset}`),
    duplicate: (id: number) => axios.post(`${API_URL}${QUOTE_API_URL}/${id}/duplicate_quote/`),
    searchTransplaceAuction: (id: number) => axios.get(`${API_URL}${QUOTE_API_URL}/${id}/transplace_auction_search`),
    update_margin: ({ id, margin }: { id: number, margin: number }) => axios.post(`${API_URL}${QUOTE_API_URL}/${id}/update_margin/`, { margin: margin }),
    carrierPreference: ({ id, offset }: { id:number, offset: number}) => axios.get(`${API_URL}${QUOTE_API_URL}/${id}/carrier_preference/?limit=${DEFAULT_ROWS_PER_PAGE}&offset=${offset}`),
    pricingRulesEditorConfig: () => axios.get(`${API_URL}${QUOTE_API_URL}/pricing_rules_editor_config/`),
  }
};

export const useQuotesQuery = (filters: string, size: number, page: number, ordering: string) => {
  return useQuery<PaginatedQueryResult<Quote>>([ QUOTE_QUERY_KEY, { filters, size, page, ordering }],
                                               () => api.quotes.list(filters, size, page, ordering),
                                               { enabled: !!filters, keepPreviousData: true }
  );
};

export const useQuoteQuery = (quoteId: number = null, queryOptions = {}) => {
  return useQuery<AxiosResponse<QuoteDetails>, AxiosError<{message: string}>>([ QUOTE_QUERY_KEY, quoteId ], () => api.quotes.get(quoteId), { ...queryOptions, enabled: !!quoteId, });
};

export const useQuoteActivitesQuery = (quoteId: number) => {
  return useQuery<AxiosResponse<QuoteActivity[]>>([ QUOTE_ACTIVITIES_QUERY_KEY, quoteId ], () => api.quotes.activities(quoteId));
};

export const useQuoteCreateMutation = (queryClient: QueryClient, opts: Opts<QuoteDetails, Record<string, string>>) =>
  useMutation(api.quotes.create, {
    onSuccess: response => {
      opts.onSuccess(response);
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_CARRIER_PREFERENCE_QUERY_KEY);
    },
    onError: (error: AxiosError<Record<string, string>>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useQuoteUpdateMutation = (queryClient: QueryClient, opts: Opts<QuoteDetails, Record<string, string>>) =>
  useMutation(api.quotes.update, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_CARRIER_PREFERENCE_QUERY_KEY);
    },
    onError: (error: AxiosError<Record<string, string>>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useQuoteUpdatePriceMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.quotes.savePrice, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useQuoteUpdateNotesMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.quotes.saveNotes, {
    onSuccess: () => {
      opts.onSuccess();
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useRespondMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.quotes.respond, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useRejectMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.quotes.reject, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useMarkQuoteMutation = (queryClient: QueryClient, opts: Opts<unknown, Record<string, string>>) =>
  useMutation(api.quotes.mark_quote, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_ACTIVITIES_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
    },
    onError: (error: AxiosError<Record<string, string>>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDeleteQuoteMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.quotes.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_CARRIER_PREFERENCE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    },
  });

export const useQuoteHistoryQuery = (quoteId: number, filters: string, offset: number, ordering: string) => {
  return useQuery<PaginatedQueryResult<QuoteHistory>>([ QUOTE_HISTORY_QUERY_KEY, { quoteId, filters, offset, ordering }],
                                                      () => api.quotes.history({ id: quoteId, filters, offset, ordering }),
  );
};

export const useDuplicateQuoteMutation = (queryClient: QueryClient, opts: Opts<{id: number}>) =>
  useMutation(api.quotes.duplicate, {
    onSuccess: (response) => {
      opts.onSuccess(response);
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
      queryClient.invalidateQueries(QUOTE_HISTORY_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    },
  });

export const useSearchTransplaceAuction = (quoteId: number, enabled: boolean) => {
  return useQuery<AxiosResponse<TransplaceAuction>>(
    [ QUOTE_SEARCH_TRANSPLACE_AUCTION_KEY, quoteId ],
    () => api.quotes.searchTransplaceAuction(quoteId),
    { enabled: enabled }
  );
};

export const useQuoteUpdateMarginMutation = (queryClient: QueryClient, opts: Opts) => {
  return useMutation(api.quotes.update_margin, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(QUOTE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });
};

export const useQuoteCarrierPreferenceQuery = (quoteId: number, offset: number) => {
  return useQuery<PaginatedQueryResult<Carrier>>([ QUOTE_CARRIER_PREFERENCE_QUERY_KEY, { quoteId, offset }],
                                                 () => api.quotes.carrierPreference({ id: quoteId, offset }),
  );
};

export const usePricingRulesEditorConfigQuery = () => {
  return useQuery<AxiosResponse<ExpressionEditorConfig>>([QUOTE_PRICING_RULES_EDITOR_CONFIG_QUERY_KEY], () => api.quotes.pricingRulesEditorConfig());
};