import React from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import {
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { RadioButtonGroupOption } from 'types';

import { useStyles } from './RadioButtonField.css';

type SelectFieldProps = {
  id: string,
  name?: string,
  label: string,
  disabled?: boolean,
  className?: string,
  defaultValue?: string,
  row?: boolean,
  color?: 'default' | 'primary' | 'secondary',
  size?: 'small' | 'medium',
  options: RadioButtonGroupOption[],
  onChange?: (v?: string) => void
};

export const RadioButtonField = ({
  id,
  label,
  className,
  disabled = false,
  size = 'small',
  color = 'default',
  defaultValue,
  name,
  options,
  onChange = null,
}: SelectFieldProps) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, form: FormikProps<unknown>, id: string) => {
    form.setFieldValue(id, event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <div>
      <p className={classes.title}>{label}</p>
      <Field name={id}>
        {({ form } : FieldProps<unknown>) => (
          <FormControl error={!!form.errors[id]} className={classes.formControl}>
            <RadioGroup
              defaultValue ={ defaultValue }
              name={name || ''}
              row
              onChange={(e) => handleChange(e, form, id)}>
              {options.map((option: RadioButtonGroupOption, index ) => (
                <FormControlLabel key={index} value={option.value} className={className} label={option.label} control={<Radio disabled={disabled} size={size} color={color} />} />
              ))}
            </RadioGroup>
            <FormHelperText>{form.errors[id] as string}</FormHelperText>
          </FormControl>
        )}
      </Field>
    </div>
  );
};