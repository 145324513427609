import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  sidebar: {
    '& > .MuiPaper-root': {
      backgroundColor: theme.palette.primary.primary100,
      borderRight: `0.063rem solid ${theme.palette.primary.primary200}`,
    },
    '.MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    width: '3rem',
    display: 'inline-flex',
    padding: '1.25rem 0.25rem',
    alignItems: 'flex-start',
    gap: '0.625rem',
    flexShrink: 0,
  },
  list: {
    '&  .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.primary200,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem'
  },
  logoItem: {
    justifyContent: 'center',
    paddingBottom: '2rem'
  },
  listItemButton: {
    display: 'flex',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.5rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.primary.primary100,
  },
  listItemAddButton: {
    backgroundColor: theme.palette.primary.primary700,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.primary500} !important`,
    }
  },
  listItemIcon: {
    justifyContent: 'center'
  },
  sidebarDivider: {
    width: '100%',
    height: '0.125rem',
    background: `linear-gradient(to right, ${theme.palette.primary.primary100}, ${theme.palette.secondary.secondary200}, ${theme.palette.primary.primary100})`,
    marginTop: '15px',
    marginBottom: '15px',
  },
  lightTooltip: {
    ...theme.typography.bodyRegularXS,
    display: 'inline-flex',
    padding: '0.5rem 1rem',
    alignItems: 'flex-start',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral.neutral700,
    boxShadow: `0rem 0.5rem 0.5rem 0rem ${theme.palette.primary.primary200}`
  }
}));
