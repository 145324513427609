import { makeStyles} from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  actionBtnSize: {
    height: '2.5rem',
    width: '3.25rem',
  },
  arrow: {
    width: 16,
    height: 16,
    margin: '4px 15px 0 15px'
  },
  hazmatIcon: {
    width: '3rem',
    height: '2.7rem',
    paddingLeft: '5px',
    paddingBottom: '5px'
  },
  customerInitial: {
    border: '1px solid #0E70B9',
    borderRadius: '50%',
    width: 21,
    height: 21,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#0E70B9',
    marginRight: 12
  },
  primaryText: {
    fontSize: 18
  },
  secondaryText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
  },
  contentContainer: {
    backgroundColor: theme.palette.neutral.neutral0,
    borderRadius: '1rem',
    boxShadow: `0px 4px 16px 0px ${theme.palette.neutral.neutral100}`,
    width: '100%',
  },
  markActionsWrapper: {
    display: 'grid',
  },
  divider: {
    backgroundColor: theme.palette.secondary.secondary200,
    height: '4rem',
    display: 'flex',
    margin: 'auto 0.5rem',
    marginBottom: '2rem',
  },
  whiteBackground: {
    backgroundColor: theme.palette.common.white,
  },
  quoteDetailTitle: {
    marginBottom: 4
  },
  cardBorder: {
    background: 'linear-gradient(0deg, white 0%, white 100%), linear-gradient(0deg,  0%,  100%)',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
    borderRadius: '1rem'
  },
  cardTitle: {
    color: theme.palette.neutral.neutral700,
  },
  cardSubtitle: {
    color: theme.palette.secondary.secondary700,
  },
  cardContentDivider: {
    height: '0.063rem',
    background: theme.palette.secondary.secondary200,
  },
  infoLine: {
    paddingTop: '0.75rem !important',
    paddingBottom: '0.75rem !important'
  },
  predictionInfoTitle: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
  },
  predictionInfoValue: {
    ...theme.typography.bodyMediumS,
    color: theme.palette.common.black,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1.5rem',
  },
  title: {
    marginBottom: '1.5rem',
  },
  priceMarginResolverTitle: {
    ...theme.typography.h5,
    marginBottom: '1.5rem',
  },
  quotedPriceFormContainer: {
    width: 'min-content',
  },
  selectAdornment: {
    '& .MuiInput-input': {
      padding: 0,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '1.5rem',
    }
  },
  quoteAuctionDetails: {
    paddingTop: '0.5rem',
    '& .MuiTypography-root': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral700,
    },
  },
  containerWidth: {
    width: 'auto'
  },
  detailActionsWrapper: {
    '&.MuiGrid-item': {
      padding: '20.5px 8px'
    },
    '& > *': {
      marginRight: 12,
    }
  },
  priceEditIcon: {
    cursor: 'pointer',
  },
  detailMarkActionsWrapper: {
    alignItems: 'end',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'start',
    marginBottom: '1.5rem',
    width: 'auto',
  },
  margin0: {
    marginBottom: 0,
  },
  notesBtn: {
    width: 'fit-content'
  },
  notesEditIcon: {
    paddingTop: 4,
    cursor: 'pointer'
  },
  quoteDetailsActions: {
    '& button:first-child': {
      marginRight: 36,
      padding: '6px 16px',
    },
    '& button:nth-child(2)': {
      marginRight: 8
    },
    [theme.breakpoints.down(1662)]: {
      marginTop: 36,
    },
  },
  quoteStops: {
    '&.MuiGrid-item': {
      paddingLeft: 16
    }
  },
  priceContainerWidth: {
    width: 'auto',
    '& .MuiOutlinedInput-input': {
      color: theme.palette.neutral.neutral700,
      marginLeft: '0.1rem',
      width: '6rem',
    },
  },
  flexEnd: {
    alignItems: 'end',
    display: 'flex',
  },
  currencyContainer: {
    border: 'none',
    height: '2.5rem',
    width: '13rem',
    '& .MuiOutlinedInput-root': {
      height: '2.5rem',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.primary.primary700}`,
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary700}`,
    },
  },
  headerButton: {
    height: '40px'
  },
  quotePriceButtonWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    marginLeft: '1rem !important',
    width: 'auto',
    '& > div:first-child': {
      gap: '1rem',
      display: 'flex',
      '& button': {
        height: '2.5rem',
        margin: 0,
        minWidth: '2.5rem',
        padding: '6px 0',
        width: 'auto',
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
    },
  },
  selectWrapper: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'end',
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.neutral.neutral0,
    },
  },
  selectPaper: {
    ...theme.typography.bodyRegularS,
    border: `1px solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    marginTop: '1.25rem',
    maxHeight: 'calc(100vh / 2)',
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
    '& ul': {
      padding: 0,
    },
    '& li': {
      ...theme.typography.bodyRegularS,
      padding: '0.5rem 1rem',
    }
  },
  currencyInput: {
    ...theme.typography.bodyRegularS,
    borderRadius: '0.5rem',
    height: '2.5rem',
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      '&:input': {
        border: `1px solid ${theme.palette.primary.primary200}`,
        borderRadius: '0.5rem',
        borderWidth: 1,
      },
    },
    '& .fieldset-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
    '& .MuiInputBase-input': {
      height: 'unset',
    },
  },
  currencyInputSize: {
    width: '13rem !important',
  },
  wonCurrency: {
    color: theme.palette.success.main,
    '& .MuiOutlinedInput-input': {
      color: theme.palette.success.main,
    },
  },
  lostCurrency: {
    color: theme.palette.error.main,
    '& .MuiOutlinedInput-input': {
      color: theme.palette.error.main,
    },
  },
  softmodalDryVanRates: {
    paddingLeft: 0,
  },
  softmodalDryVanRatesRate: {
    paddingTop: 0,
  },
}));

