import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  arrowBase: {
    fontSize: '1.25rem',
    lineHeight: 'normal',
    textAlign: 'center',
    transition: 'all 0.2s',
  },
  arrowDown: {
    transform: 'rotate(-180deg)',
    color: theme.palette.primary.primary700
  },
  arrowUp: {
    transform: 'rotate(0deg)',
    color: theme.palette.neutral.neutral700
  },
  card: {
    background: 'linear-gradient(0deg, white 0%, white 100%), linear-gradient(0deg,  0%,  100%)',
    borderRadius: '1rem',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
  },
  cardContent: {
    padding: '0.5rem 1rem 1rem'
  },
  cardTitle: {
    color: theme.palette.neutral.neutral700
  },
  collapseHeader: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: '1rem 1rem 0.75rem',
  },
}));