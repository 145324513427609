import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import { Card, CardContent } from '@mui/material';
import clsx from 'clsx';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { ActionButton } from 'components';
import { ActionMenuButtonConfig } from 'types';

import { useStyles } from './ActionMenuButton.css';

type ActionMenuButtonProps = {
  config: ActionMenuButtonConfig[],
  disabled: boolean,
  variant: 'primary' | 'secondary',
};

export const ActionMenuButton = ({ config, disabled, variant } : ActionMenuButtonProps) => {
  const classes = useStyles();

  const [ expanded, setExpanded ] = useState<boolean>(false);

  const handleOpenMenu = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const mainAction = config.find(item => item.main);
  const additionalActions = config.filter(item => !item.main && item.isVisible);

  return (
    <Grid item className={clsx({
      [classes.actionsWrapper]: true,
      [classes.actionsWrapperExpanded]: expanded,
    })}>
      <Card className={clsx({
        [classes.collapsibleCard]: true,
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
      })}>
        <CardContent className={classes.collapsibleCardContent}>
          <ActionButton
            endIcon={additionalActions.length > 0 && <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} onClick={handleOpenMenu} />}
            text={mainAction.label}
            variant={variant}
            disabled={disabled}
            handleClick={mainAction.onClick} />
          {additionalActions.map((item, index) => (
            <Grid
              key={index}
              onClick={() => item.onClick()}
              className={clsx({
                [classes.collapsibleOptionWrapper]: true,
                [classes.collapsibleOption]: expanded,
                [classes.collapsibleOptionCollapsed]: !expanded,
                [classes.primaryCardContent]: variant === 'primary',
                [classes.secondaryCardContent]: variant === 'secondary',
              })}>
              <Typography variant='h5'>{item.label}</Typography>
            </Grid>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};
