/* global google */
import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { SearchIcon, XMark } from 'resources';
import { Location } from 'types';

import { useStyles } from './LocationSearch.css';

type LocationSearchProps = {
  defaultValue?: string,
  label?: string,
  placeholder?: string
  onSelect: (location: Location) => void,
  hasError?: boolean,
  alignedLabel?: boolean,
  showStartIcon?: boolean,
};

export const LocationSearch = ({ defaultValue = '', label, placeholder = 'City, ST', hasError = false, onSelect, alignedLabel = true, showStartIcon = false }: LocationSearchProps) => {
  const gcode = new window.google.maps.Geocoder();

  const classes = useStyles({showStartIcon});
  const [ address, setAddress ] = useState(defaultValue);

  useEffect(() => {
    setAddress(defaultValue);
  }, [defaultValue]);

  const handleChange = (address: string) => {
    if (!address) {
      onSelect({
        city: '',
        zipCode: '',
        state: '',
        country: '',
        address: '',
        geo: {
          lat: null,
          lng: null
        }
      });
    }

    setAddress(address);
  };

  const getAddressTypeValue = (results: google.maps.GeocoderResult[], componentType: string, addressType: string, isShort = false) => {
    const addressComponents = results.find(r => r.types.includes(componentType))?.address_components;
    const addressComponent = addressComponents?.find(r => r.types.includes(addressType));
    return isShort ? addressComponent?.short_name : addressComponent?.long_name;
  };

  const isComponentValid = (result: google.maps.GeocoderResult, address: string, type: string) => {
    const addressComponent = result.address_components.find(component => component.types.includes(type));
    return address.includes(addressComponent?.short_name) || address.includes(addressComponent?.long_name);
  };

  const handleSelect = async (address: string) => {
    /*
      Known issue:

      Sometimes when user selects an address geocodeByAddress will
      return a wrong geo for a given address.

      Exp:
      Go to -> https://developers.google.com/maps/documentation/geocoding/overview
      Enter -> Columbus, IN, USA
      Expected -> Columbus, IN, USA
      Result -> Columbus, OH, USA
    */
    let results = await geocodeByAddress(address);
    results = results.filter(element => isComponentValid(element, address, 'administrative_area_level_1'));

    let index = -1;
    if (results.length > 1) {
      index = results.findIndex(element => isComponentValid(element, address, 'locality') || isComponentValid(element, address, 'sublocality'));
    } else if (results.length === 1) {
      index = 0;
    }

    if (index === -1) {
      onSelect({
        city: undefined,
        zipCode: undefined,
        state: undefined,
        country: undefined,
        address: address,
        geo: undefined
      });
    } else {
      gcode.geocode({'location': results[index].geometry.location}, function(results, status) {
        if (status === 'OK') {
          onSelect({
            city: address.split(',')[0],
            zipCode: getAddressTypeValue(results, 'postal_code', 'postal_code'),
            state: getAddressTypeValue(results, 'postal_code', 'administrative_area_level_1', true),
            country: getAddressTypeValue(results, 'postal_code', 'country', true),
            address: address,
            geo: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }
          });
        }
      });
    }

    setAddress(address);
  };

  const searchOptions = {
    types: [ 'locality', 'postal_code' ],
    componentRestrictions: {
      country: [ 'us', 'ca' ],
    }
  };

  return (
    <Grid>
      {label &&
        <Typography className={clsx({
          [classes.title]: true,
          [classes.titleMargin]: !alignedLabel})}>
          {label}
        </Typography>}
      <Grid container direction='row' justify='flex-start'>
        <Grid item xs>
          <Grid container direction='row'>
            <Grid item xs>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                searchOptions={searchOptions}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <Box className={clsx({
                    [classes.dropdown]: suggestions.length > 0
                  })}>
                    <TextField
                      className={clsx({
                        [classes.input]: true,
                        [classes.errorField]: hasError
                      })}
                      error={!address}
                      {...getInputProps()}
                      placeholder={placeholder}
                      InputProps={{
                        startAdornment: showStartIcon &&
                        <InputAdornment position='start' className={classes.startAdornment}>
                          { address ?
                            <XMark
                              className={classes.closeIconButton}
                              onClick={() => {
                                setAddress('');
                                onSelect(null);
                              }} /> :
                            <SearchIcon /> }
                        </InputAdornment>
                      }} />
                    <Box>
                      {suggestions.map((suggestion, index) => (
                        <MenuItem key={index} {...getSuggestionItemProps(suggestion)}><Typography>{suggestion.description}</Typography></MenuItem>
                      ))}
                    </Box>
                  </Box>
                )}
              </PlacesAutocomplete>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
