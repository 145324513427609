import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  blueText: {
    color: theme.palette.primary.primary700
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    marginTop: '3rem',
    width: '100%',
  },
  formControl: {
    display: 'grid',
    gap: '0.5rem',
    marginTop: '0.875rem',
    width: '100%',
    '& .MuiInputBase-root': {
      marginTop: 0,
    }
  },
  importText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral700,
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  inputArea: {
    backgroundColor: theme.palette.primary.primary100,
    border: `1px dashed ${theme.palette.secondary.secondary500}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.5rem',
    '& .MuiButtonBase-root': {
      margin: '0 auto',
      width: 'fit-content'
    }
  },
  validationError: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main
  },
  uploadButton: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary.secondary500}`,
    color: theme.palette.neutral.neutral700,
    '&:hover': {
      '& svg': {
        '& path': {
          fill: theme.palette.primary.primary700,
        },
      },
    },
  },
  widthMax: {
    width: '100%'
  }
}));
