import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import clsx from 'clsx';

import {
  AWARD_STATUS_TYPE,
  AWARD_STATUS_TYPE_LABELS,
  FILE_STATUS,
  FILE_STATUS_LABELS,
  QUOTE_STATUS,
  QUOTE_STATUS_LABELS,
} from 'enums';

import { useStyles } from './StatusChip.css';

export const StatusChip = ({status}: {status: string} ) => {
  const classes = useStyles();

  const className = useMemo(() => {
    let className;
    switch (status) {
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.OPEN]:
        className=classes.purple;
        break;
      case FILE_STATUS_LABELS[FILE_STATUS.PROCESSING]:
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.RESPONDED]:
        className=classes.blue;
        break;
      case FILE_STATUS_LABELS[FILE_STATUS.PENDING]:
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.REJECTED]:
        className=classes.yellow;
        break;
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.WON]:
      case FILE_STATUS_LABELS[FILE_STATUS.SUCCESS]:
      case AWARD_STATUS_TYPE_LABELS[AWARD_STATUS_TYPE.ACTIVE]:
        className=classes.green;
        break;
      case FILE_STATUS_LABELS[FILE_STATUS.ERROR]:
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.LOST]:
        className=classes.red;
        break;
      case QUOTE_STATUS_LABELS[QUOTE_STATUS.EXPIRED]:
      case AWARD_STATUS_TYPE_LABELS[AWARD_STATUS_TYPE.INACTIVE]:
        className=classes.grey;
        break;
    }
    return className;
  }, [status]);

  return (
    <Chip
      label={status}
      size='small'
      className={clsx({[classes.chip]: true, [className]: className})}/>
  );
};