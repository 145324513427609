import {SIDEBAR_ICON_TYPE as ICON_TYPE} from 'enums';

import {
  AWARD_PERMISSIONS,
  CUSTOMER_PERMISSIONS,
  FILE_PERMISSIONS,
  GLOBAL_RULE_PERMISSIONS,
  INTEGRATION_LOG_PERMISSIONS,
  QUOTE_PERMISSIONS,
  WORKING_COPY_PERMISSIONS
} from 'permissions/index';
import { useRouteMatch } from 'react-router-dom';

type SidebarConfig = {
    items: SidebarButtonConfig []
    matches: MatchConfig []
};

export type SidebarButtonConfig = {
    iconType: ICON_TYPE
    permissions: string[]
    urlTo: string,
    tooltip: string,
    iconName: string,
    urlRoutes: string [],
    priority: number
};

type MatchConfig = {
  iconType: ICON_TYPE,
  match: object|null,
  priority: number
}

const items : SidebarButtonConfig [] = [
  {iconType: ICON_TYPE.QUOTE_DASHBOARD, permissions: [QUOTE_PERMISSIONS.RETRIEVE], urlTo: '/quotes', tooltip: 'Quote Dashboard', iconName: 'quoteDashboardIcon', urlRoutes: ['/quotes'], priority: 100},
  {iconType: ICON_TYPE.SHIPPERS, permissions: [CUSTOMER_PERMISSIONS.RETRIEVE], urlTo: '/shippers', tooltip: 'Shippers', iconName: 'shippersIcon', urlRoutes: [ '/shippers', '/shippers/:id/fuel-sheets' ], priority: 1},
  {iconType: ICON_TYPE.PRICE_RULES, permissions: [GLOBAL_RULE_PERMISSIONS.RULESET_RETRIEVE], urlTo: '/price-rules', tooltip: 'Price rules', iconName: 'rulesIcon', urlRoutes: [ '/price-rules', '/shippers/:id/price-rules' ], priority: 2},
  {iconType: ICON_TYPE.AWARDS, permissions: [AWARD_PERMISSIONS.RETRIEVE], urlTo: '/awards', tooltip: 'Awards', iconName: 'awardsIcon', urlRoutes: [ '/awards', '/awards/:id/lanes' ], priority: 3},
  {iconType: ICON_TYPE.WORKING_COPIES, permissions: [WORKING_COPY_PERMISSIONS.RETRIEVE], urlTo: '/working-copies', tooltip: 'Working copies', iconName: 'workingCopiesIcon', urlRoutes: ['/working-copies'], priority: 4},
  {iconType: ICON_TYPE.INTEGRATION_LOGS, permissions: [INTEGRATION_LOG_PERMISSIONS.RETRIEVE], urlTo: '/integration-logs', tooltip: 'Integration Logs', iconName: 'integrationLogsIcon', urlRoutes: ['/integration-logs'], priority: 5},
  {iconType: ICON_TYPE.FILES, permissions: [FILE_PERMISSIONS.RETRIEVE], urlTo: '/files', tooltip: 'Files', iconName: 'filesIcon', urlRoutes: ['/files'], priority: 6},
];

export const UseSidebarConfig = (): SidebarConfig => {
  const matches: MatchConfig[] = items
    // eslint-disable-next-line react-hooks/rules-of-hooks
    .map(item => ({ iconType: item.iconType, match: useRouteMatch(item.urlRoutes), priority: item.priority}))
    .sort((a, b) => a.priority - b.priority);

  return { items: items, matches: matches } as SidebarConfig;
};