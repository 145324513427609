import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Layout } from 'components';
import { FILTER_PAGE_SOURCE } from 'enums';
import { getPageAppliedFilters } from 'helpers';
import { GLOBAL_RULE_PERMISSIONS } from 'permissions';
import {
  useGetGlobalRulesetQuery,
  useUpdateRuleset
} from 'services';
import { Ruleset } from 'types';
import { RuleTable } from './components/RuleTable';

export const GlobalRulesPage = () => {
  const queryClient = useQueryClient();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.GLOBAL_RULE);

  // Currently, one global rule is inserted in the db with id 1. Should be changed for tenant-specific global rules.
  const { isSuccess, isError, isFetching, data: rulesData} = useGetGlobalRulesetQuery(1);
  const { data: rules } = rulesData || {};
  const ruleset = rules?.ruleset ? [...rules?.ruleset] : [];

  const { mutate: updateRuleset} = useUpdateRuleset(queryClient, {
    onSuccess: () => {
      toast.success('Rules successfully updated!');
    },
    onError: (error) => {
      toast.error('Rules unsuccessfully updated!');
      console.error(error);
    }
  });

  return (
    <Layout title='Rules'>
      <RuleTable
        isSuccess={isSuccess}
        isFetching={isFetching}
        isError={isError}
        ruleEditorConfig={rulesData?.data?.rule_editor_config}
        ruleset={ruleset}
        handleSaveChanges={(rules: Ruleset) => updateRuleset({ id: 1, ruleset: rules })} // Same here.
        permissions={[GLOBAL_RULE_PERMISSIONS.RULESET_UPDATE]}
        filterPageSource={FILTER_PAGE_SOURCE.GLOBAL_RULE}
        appliedFiltersAndSearch={appliedFiltersAndSearch}/>
    </Layout>
  );
};