import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles(((theme: ThemeDefault) => ({
  rowContaier: {
    alignItems: 'start',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  radioButtonLabel: {
    '& .MuiRadio-root': {
      color: theme.palette.primary.primary700,
    },
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
  },
})));
