import React from 'react';

import { TooltipComponent } from 'components';
import { EQUIPMENT_LABEL } from 'enums';
import { dryVanIcon, flatbed, intermodal, powerOnlyIcon, redExclamationIcon, reefer, stepDeckTrailer } from 'resources';

import { useStyles } from './EquipmentIcon.css';

type EquipmentIconProps = {
  equipmentType: string,
  equipmentName: string,
  disabled: boolean
}

export const EquipmentIcon = ({ equipmentType, equipmentName, disabled }: EquipmentIconProps) => {
  const classes = useStyles();

  const getEquipmentIcon = () => {
    switch (equipmentType) {
      case EQUIPMENT_LABEL.DRY_VAN:
        return dryVanIcon;
      case EQUIPMENT_LABEL.FLATBED:
        return flatbed;
      case EQUIPMENT_LABEL.INTERMODAL:
        return intermodal;
      case EQUIPMENT_LABEL.REEFER:
        return reefer;
      case EQUIPMENT_LABEL.STEP_DECK:
        return stepDeckTrailer;
      case EQUIPMENT_LABEL.POWER_ONLY:
        return powerOnlyIcon;
      default:
        return redExclamationIcon;
    }
  };

  const getEquipmentTypeAndName = () => {
    if (equipmentType === equipmentName) {
      return equipmentType;
    } else if (equipmentType) {
      return `${equipmentType} - ${equipmentName}`;
    } else {
      return equipmentName;
    }
  };

  const equipment = (
    <div className={`${classes.equipmentIconText} ${disabled ? classes.equipmentDisabled : classes.equipmentEnabled}`}>
      <img src={getEquipmentIcon()}/>
      {getEquipmentTypeAndName()}
    </div>
  );

  return (
    disabled ? (
      equipment
    ) : (
      <TooltipComponent title='You need to choose the Equipment type.'>
        {equipment}
      </TooltipComponent>
    )
  );
};
