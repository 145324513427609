import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { ActionButton, InputField } from 'components';
import { FILTER_SET_SOURCE } from 'enums';
import { useFilterSetCreateMutation, useFilterSetUpdateMutation } from 'services';
import { FilterFormType, FilterParamsType, FilterSet } from 'types';
import { getSaveFilterSetValidationSchema } from 'validations';

import { useStyle } from './NewFilterSetModal.css';

type NewFilterSetModalProps = {
  filterSource: FILTER_SET_SOURCE,
  filterParams: FilterParamsType,
  targetFilterSet: FilterSet,
  onCancel: () => void,
  onUpsertSuccess: (filterSet: FilterSet) => void,
}

export const NewFilterSetModal = ({ filterSource, filterParams, targetFilterSet, onCancel, onUpsertSuccess }: NewFilterSetModalProps) => {
  const classes = useStyle();
  const queryClient = useQueryClient();

  const { mutate: createFilter, isLoading: isCreatingFilter } = useFilterSetCreateMutation(queryClient, {
    onSuccess: (response) => {
      onCancel();
      onUpsertSuccess(response.data);
      toast.success('Filters successfully saved.');
    },
    onError: () => {
      onCancel();
      toast.error('Filters creation failed.');
    }
  });

  const { mutate: updateFilter, isLoading: isUpdatingFilter } = useFilterSetUpdateMutation(queryClient, {
    onSuccess: (response) => {
      onCancel();
      onUpsertSuccess(response.data);
      toast.success('Filters successfully renamed.');
    },
    onError: () => {
      onCancel();
      toast.error('Filters renaming failed.');
    }
  });

  const onSubmit = (values: FilterFormType) => {
    const payload = {
      name: values.name,
      filter_source: filterSource,
      filter_values: filterParams,
    };

    targetFilterSet ? updateFilter({id: targetFilterSet.id, payload}) : createFilter(payload);
  };

  return (
    <Formik
      initialValues={{
        name: targetFilterSet ? targetFilterSet.name : '',
      }}
      validateOnMount
      validationSchema={getSaveFilterSetValidationSchema}
      onSubmit={onSubmit}>
      {({ isValid }) => (
        <Form>
          <InputField
            id='name'
            label='Name'
            placeholder='Enter filter set name'
            reserveErrorMsgSpace />
          <Box className={classes.buttonContainer}>
            <ActionButton
              className={classes.blueText}
              text='Cancel'
              variant='secondary'
              handleClick={onCancel} />
            <ActionButton
              type='submit'
              isLoading={isCreatingFilter || isUpdatingFilter}
              disabled={!isValid || isCreatingFilter || isUpdatingFilter}
              text='Confirm' />
          </Box>
        </Form>
      )}
    </Formik>
  );
};