import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  tableContainer: {
    maxHeight: '31.25rem',
  },
  tableRow: {
    '&.MuiTableRow-root ': {
      '&.MuiTableCell-root': {
        ...theme.typography.bodyRegularS,
        color: theme.palette.neutral.neutral700,
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.neutral.neutral100,
        alignItems: 'center',
      }
    },
  },
  headerRow: {
    '&.MuiTableRow-root ': {
      '&.MuiTableCell-root': {
        ...theme.typography.bodyRegularS,
        color: theme.palette.neutral.neutral400,
        background: theme.palette.neutral.neutral0,
        whiteSpace: 'nowrap',
        borderBottomColor: theme.palette.neutral.neutral100,
      }
    },
  }
}));