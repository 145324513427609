import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import { AccessControl, ActionButton, DeleteCustomersModal, FilterComponent, ImportCustomerModal, Layout, MergeCustomersModal, TableComponent } from 'components';
import { useAuth } from 'contexts';
import { FILTER_PAGE_SOURCE } from 'enums';
import { checkPermissions, getPageAppliedFilters, setPageAppliedFilters } from 'helpers';
import { CUSTOMER_PERMISSIONS, FILE_PERMISSIONS } from 'permissions';
import { DeleteIcon, MergeIcon, UploadIcon } from 'resources';
import { useGetCustomersQuery } from 'services';
import { Customer, Filter, PaginatedQueryResult, QueryResult } from 'types';

import { CustomerTableConfig } from './components/CustomerTableConfig';
import { CustomerTableMenu } from './components/CustomerTableMenu';

import { useStyles } from './CustomersPage.css';

const PAGE_SIZE = 10;

export const CustomersPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser } = useAuth();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.SHIPPER);

  const mapSelectedFiltersToQuery = (search: string) => {
    return { search };
  };

  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ filter, setFilter ] = useState<Filter>(mapSelectedFiltersToQuery(search) || {});
  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ ordering, setOrdering ] = useState<string>('');
  const [ selectedRows, setSelectedRows ] = useState<Customer[]>([]);
  const [ isMergeCustomersModalOpen, setIsMergeCustomersModalOpen ] = useState<boolean>(false);
  const [ isDeleteCustomersModalOpen, setIsDeleteCustomersModalOpen ] = useState<boolean>(false);
  const [ isImportCustomerModalOpen, setIsImportCustomerModalOpen ] = useState<boolean>(false);

  const { isSuccess, isError, isFetching, data }: QueryResult<PaginatedQueryResult<Customer>>= useGetCustomersQuery(stringify(filter), rowsPerPage, page * rowsPerPage, ordering);
  const { data: customers } = data || {};

  const handleBulkActionSuccess = (items: Customer[]) => setSelectedRows(items);

  useEffect(() => {
    const query = mapSelectedFiltersToQuery(search);
    debounceFilters(query);
  }, [search]);

  const debounceFilters = useCallback(
    debounce(query => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.SHIPPER, query.search);
    }, 300),
    []
  );

  useEffect(() => {
    setPage(0);
    setSelectedRows([]);
  }, [filter]);

  return (
    <Layout title='Shippers'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.SHIPPER}
        searchText={search}
        setSearchText={setSearch}
        showFilters={false}>
        <Grid className={classes.actionsWrapper}>
          <AccessControl permissions={[FILE_PERMISSIONS.IMPORT]}>
            <ActionButton
              text='Upload Shippers'
              variant='default'
              colorVariant='white'
              handleClick={() => setIsImportCustomerModalOpen(true)}
              startIcon={<UploadIcon />}
              className={classes.headerButton} />
          </AccessControl>
          <AccessControl permissions={[CUSTOMER_PERMISSIONS.CREATE]}>
            <ActionButton
              text='New Shipper'
              variant='primary'
              handleClick={() => history.push('/shippers/create')}
              startIcon={<FontAwesomeIcon icon={faPlus}/>}
              className={classes.headerButton} />
          </AccessControl>
        </Grid>
      </FilterComponent>
      <TableComponent
        columns={CustomerTableConfig()}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={customers?.results}
        rows={customers?.count}
        areRowsSelectable={checkPermissions(currentUser.permissions, [CUSTOMER_PERMISSIONS.DELETE])}
        page={page}
        setPage={setPage}
        pageRows={rowsPerPage}
        setPageRows={setRowsPerPage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setOrdering={ordering => setOrdering(ordering)}
        defaultOrderBy={'customer_name'}
        defaultOrder={'asc'}
        bulkMenuConfig={[
          {
            icon: MergeIcon,
            permissions: [CUSTOMER_PERMISSIONS.MERGE],
            label: 'Merge Shippers',
            handleClick: () => setIsMergeCustomersModalOpen(true),
          },
          {
            icon: DeleteIcon,
            permissions: [CUSTOMER_PERMISSIONS.DELETE],
            label: 'Delete Shippers',
            handleClick: () => setIsDeleteCustomersModalOpen(true),
          },
        ]}
        renderMenu={(customer: Customer) => {
          return (
            <CustomerTableMenu customer={customer} onActionSuccess={() => setSelectedRows([])}/>
          );
        }}/>
      <MergeCustomersModal isOpen={isMergeCustomersModalOpen} onCancel={() => setIsMergeCustomersModalOpen(false)} selectedCustomers={selectedRows} onMergeSuccess={handleBulkActionSuccess} />
      <DeleteCustomersModal isOpen={isDeleteCustomersModalOpen} onCancel={() => setIsDeleteCustomersModalOpen(false)} selectedCustomers={selectedRows} onDeleteSuccess={handleBulkActionSuccess} />

      <ImportCustomerModal
        isModalOpen={isImportCustomerModalOpen}
        onCancel={() => setIsImportCustomerModalOpen(false)}
        onUploadSuccess={() => setSelectedRows([])}/>
    </Layout>
  );
};
