import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Divider, Drawer, List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import clsx from 'clsx';

import { AccessControl } from 'components';
import { SidebarButton } from './components/SidebarButton';
import { UseSidebarConfig } from './components/SidebarConfig';

import {SIDEBAR_ICON_TYPE as ICON_TYPE} from 'enums';

import {
  QUOTE_PERMISSIONS
} from 'permissions/index';

import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {logo} from 'resources';

import { useStyles } from './Sidebar.css';

export const Sidebar =() => {
  const classes = useStyles();
  const history = useHistory();
  const [ activeIcon, setActiveIcon ] = useState<ICON_TYPE>(ICON_TYPE.QUOTE_DASHBOARD);
  const sidebarConfig = UseSidebarConfig();

  useEffect(() => {
    for (const item of sidebarConfig.matches) {
      if (item.match) {
        setActiveIcon(item.iconType);
        return;
      }
    }
  }, [...sidebarConfig.matches]);

  return (
    <>
      <Drawer
        anchor='left'
        className={classes.sidebar}
        variant='permanent'>
        <List className={classes.list}>
          <ListItemIcon className={classes.logoItem}>
            <Link to='/quotes' onClick={() => window.location.href = '/quotes'}>
              <img src={logo} />
            </Link>
          </ListItemIcon>
          {sidebarConfig.items.map((button) => (
            <SidebarButton key={button.iconType} active={activeIcon===button.iconType} {...button} />
          ))}
          <AccessControl permissions={[QUOTE_PERMISSIONS.CREATE]}>
            <Divider className={classes.sidebarDivider}/>
            <Tooltip title='Add New Quote' classes={{ tooltip: classes.lightTooltip }}>
              <ListItem button onClick={() => history.push('/quotes/create')} className={clsx([ classes.listItemButton, classes.listItemAddButton ])} disableGutters>
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faPlus} style={{ color: 'white' }}/>
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </AccessControl>
        </List>
      </Drawer>
    </>);
};