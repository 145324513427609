import React from 'react';
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useStyles } from './TablePagination.css';

type TablePaginationProps = {
  count: number,
  page: number,
  onChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void,
  rowsPerPage: number,
  rowsPerPageOptions: number[],
  onChangeRowsPerPage: (event: SelectChangeEvent<number>) => void
}

export const TablePagination = ({count, rowsPerPage, onChangePage, page, rowsPerPageOptions, onChangeRowsPerPage}: TablePaginationProps) => {
  const classes = useStyles();
  const numberOfPages = Math.ceil(count/rowsPerPage);

  const handleGoToPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = Number(e.target.value);
    if (value && value <= numberOfPages) {
      onChangePage(e, value);
    }
  };

  return (
    <Stack direction='row' className={classes.container} justifyContent='space-between'>
      <Stack direction='row' spacing={2} className={classes.inputContainer}>
        <Typography className={classes.text}>Showing</Typography>
        <FormControl size='small'>
          <Select
            className={classes.select}
            id='rowsPerPage'
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{
              classes: {
                paper: classes.paper,
              },
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                  },
                },
              },
            }}>
            {rowsPerPageOptions.map( option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={classes.text}>of {count}</Typography>
      </Stack>
      <Stack direction='row' spacing={3}>
        <Pagination
          className={classes.pagination}
          size='small'
          shape='rounded'
          variant='outlined'
          count={numberOfPages || 0}
          onChange={onChangePage}
          page={page}/>
        <Stack direction='row' spacing={2} className={classes.inputContainer}>
          <Typography className={classes.text}>Go to page</Typography>
          <OutlinedInput className={classes.input} defaultValue={page} onChange={handleGoToPage}/>
        </Stack>
      </Stack>
    </Stack>
  );
};