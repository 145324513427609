import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  actionsWrapper: {
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
  },
  customerActions: {
    display: 'flex',
  },
  headerButton: {
    height: '2.5rem',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon > *:first-child': {
        fontSize: '0.875rem',
      },
    },
  },
});