import React from 'react';

import { CollapsibleWidget } from 'components';
import { getDryVanRateTitle } from 'helpers';
import { SoftmodalResponse } from 'types';

import { SoftmodalRateContent } from './SoftmodalRateContent';

import { useStyles } from '../../QuoteDetailsPage.css';

type DryVanRateDetailsProps = {
  data: SoftmodalResponse,
  currency: string,
};

export const DryVanRateDetails = ({ data, currency } : DryVanRateDetailsProps) => {
  const classes = useStyles();

  const {
    error,
    rates,
    quote_id,
  } = data || {};

  return (
    <CollapsibleWidget headerTitle={getDryVanRateTitle(quote_id, rates[0]?.total, currency)} isClosed={true}>
      <SoftmodalRateContent rates={rates} error={error} currency={currency} className={classes.softmodalDryVanRates} />
    </CollapsibleWidget>
  );
};