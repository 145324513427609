import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  /*Classes for the displayed toast*/
  toastifyToastContainer: {
    width: '24rem',
    '& .Toastify__toast': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral700,
      borderRadius: '0.375rem',
      padding: '0 1rem',
      textAlign: 'start',

      '--toastify-color-info': theme.palette.info.main,
      '--toastify-color-success': theme.palette.success.main,
      '--toastify-color-warning': theme.palette.warning.main,
      '--toastify-color-error': theme.palette.error.main,

      '--toastify-icon-color-info': theme.palette.info.main,
      '--toastify-icon-color-success': theme.palette.success.main,
      '--toastify-icon-color-warning': theme.palette.warning.main,
      '--toastify-icon-color-error': theme.palette.error.main,

      '--toastify-toast-min-height': '3.125rem',
      '--toastify-toast-max-height': '3.125rem',
    },
    '& .Toastify__toast-body': {
      borderRadius: '0.5rem',
      gap: '0.5rem',
      width: '80%',
      '& div': {
        boxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'break-spaces',
        '-webkit-line-clamp': 2,
      },
    },
    '& .Toastify__close-button': {
      alignSelf: 'center',
      opacity: 1,
      '& svg': {
        height: '1.5rem',
        width: '1.5rem',
      },
    },
    '& .Toastify__toast--info': {
      backgroundColor: theme.palette.primary.primary100,
      border: `1px solid ${theme.palette.info.main}`,
      '& .Toastify__close-button': {
        '& svg': {
          fill: theme.palette.info.main,
          stroke: theme.palette.primary.primary100,
        },
      },
    },
    '& .Toastify__toast--success': {
      backgroundColor: theme.palette.success.light,
      border: `1px solid ${theme.palette.success.main}`,
      '& .Toastify__close-button': {
        '& svg': {
          fill: theme.palette.success.main,
          stroke: theme.palette.success.light,
        },
      },
    },
    '& .Toastify__toast--warning': {
      backgroundColor: theme.palette.warning.light,
      border: `1px solid ${theme.palette.warning.main}`,
      '& .Toastify__close-button': {
        '& svg': {
          fill: theme.palette.warning.main,
          stroke: theme.palette.warning.light,
        },
      },
    },
    '& .Toastify__toast--error': {
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.main}`,
      '& .Toastify__close-button': {
        '& svg': {
          fill: theme.palette.error.main,
          stroke: theme.palette.error.light,
        },
      },
    },
  },
}));
