export enum AWARD_STATUS_TYPE {
    ACTIVE,
    INACTIVE
  }

export const AWARD_STATUS_TYPE_LABELS: Record<string, string> = {
  [AWARD_STATUS_TYPE.ACTIVE]: 'Active',
  [AWARD_STATUS_TYPE.INACTIVE]: 'Inactive',
};

export const REGIONS = [
  { id: 'U.S.', label: 'U.S.'},
  { id: 'East Coast', label: 'East Coast'},
  { id: 'New England', label: 'New England'},
  { id: 'Central Atlantic', label: 'Central Atlantic'},
  { id: 'Lower Atlantic', label: 'Lower Atlantic'},
  { id: 'Midwest', label: 'Midwest'},
  { id: 'Gulf Coast', label: 'Gulf Coast'},
  { id: 'Rocky Mountain', label: 'Rocky Mountain'},
  { id: 'West Coast', label: 'West Coast'},
  { id: 'West Coast Less California', label: 'West Coast Less California'},
  { id: 'California', label: 'California'}
];

export const AWARD_RATE_TYPE_LABEL: Record<string, string> = {
  LINE_HAUL: 'Line haul',
  RPM: 'RPM',
  FLAT_RATE: 'Flat rate',
};
