import firebase from 'firebase/app';
import 'firebase/auth';

export const app = firebase.initializeApp({
  apiKey: window.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: window.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window.REACT_APP_FIREBASE_APP_ID
});

export const auth = app.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();