import { Func } from '@react-awesome-query-builder/core';

export const RELATIVE_NUMBER: Func = {
  label: 'Relative',
  returnType: 'number',
  renderBrackets: [ '', '' ],
  renderSeps: [ '', '', '' ],

  jsonLogicImport: (v) => {
    const number = v['number_add'][0];
    const value = Math.abs(v['number_add'][1]);
    const operand = v['number_add'][1] >= 0 ? 'plus' : 'minus';
    const dimension = v['number_add'][2];
    return [ number, operand, value, dimension ];
  },

  jsonLogic: ({ number, operand, value, dimension }) => ({
    'number_add': [
      number,
      value * (operand === 'minus' ? -1 : +1),
      dimension
    ]
  }),

  formatFunc: ({ number, operand, value, dimension }) => {
    if (dimension === 'percentage') {
      return value
        ? `${number} * (1 ${operand === 'minus' ? '-' : '+'} ${value} / 100)`
        : number;
    }
    if (dimension === 'exact') {
      return value
        ? `${number} ${operand === 'minus' ? '-' : '+'} ${value}`
        : number;
    }
    return `Unsuported operand dimension ${dimension}`;
  },

  args: {
    number: {
      label: 'Number',
      type: 'number',
      valueSources: ['field'],
    },
    operand: {
      label: 'Operand',
      type: 'select',
      defaultValue: 'plus',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          plus: '+',
          minus: '-',
        },
      }
    },
    value: {
      label: 'Value',
      type: 'number',
      fieldSettings: {
        min: 0,
        validateValue: (value: number) => Number.isInteger(value)
      },
      defaultValue: 0,
      valueSources: ['value'],
    },
    dimension: {
      label: 'Dimension',
      type: 'select',
      defaultValue: 'exact',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          exact: 'Exact Value',
          percentage: 'Percentage',
        },
      }
    },
  }
};
