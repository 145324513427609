import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  uploadContainer: {
    border: `1px dashed ${theme.palette.secondary.secondary500}`,
    borderRadius: '0.5rem',
    padding: '1.125rem',
  },
}));