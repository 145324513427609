import React, { useMemo, useState } from 'react';

import {
  DeleteQuoteModal,
  DuplicateQuoteModal,
  MarkQuoteLostModal,
  MarkQuoteWonModal,
  ModalComponent,
  TableMenu,
} from 'components';
import { MARK_QUOTE_LOST_HEADER, MARK_QUOTE_WON_HEADER, QUOTE_SOURCE, QUOTE_STATUS } from 'enums';
import { QUOTE_PERMISSIONS } from 'permissions';
import { ArrowIcon, CheckIconRounded, CloneIcon, DeleteIcon, ErrorIconRounded, Pencil } from 'resources';
import { Quote } from 'types';

import { useStyles } from './QuoteTableMenu.css';

type QuoteTableMenuProps = {
  quote: Quote
};

export const QuoteTableMenu = ({ quote }: QuoteTableMenuProps) => {
  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isWonDialogOpen, setIsWonDialogOpen ] = useState<boolean>(false);
  const [ isLostDialogOpen, setIsLostDialogOpen ] = useState<boolean>(false);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState<boolean>(false);
  const [ isDuplicateDialogOpen, setIsDuplicateDialogOpen ] = useState<boolean>(false);

  const markWonHandler = () => {
    setIsWonDialogOpen(true);
    setAnchorEl(null);
  };

  const markLostHandler = () => {
    setIsLostDialogOpen(true);
    setAnchorEl(null);
  };

  const duplicateQuoteHandler = () => {
    setIsDuplicateDialogOpen(true);
    setAnchorEl(null);
  };

  const deleteQuoteHandler = () => {
    setIsDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [QUOTE_PERMISSIONS.RETRIEVE],
      text: 'More details',
      icon: ArrowIcon,
      urlTo: `/quotes/${quote.id}`,
    },
    {
      permissions: [QUOTE_PERMISSIONS.UPDATE],
      text: 'Edit',
      icon: Pencil,
      urlTo: `quotes/${quote.id}/edit`,
      disabled: quote.status !== QUOTE_STATUS.OPEN || quote.quote_source !== QUOTE_SOURCE.QQT_UI,
    },
    {
      permissions: [QUOTE_PERMISSIONS.DUPLICATE_QUOTE],
      text: 'Duplicate',
      icon: CloneIcon,
      onClick: duplicateQuoteHandler,
    },
    {
      permissions: [QUOTE_PERMISSIONS.DELETE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteQuoteHandler,
      divider: true,
      disabled: quote.status !== QUOTE_STATUS.OPEN,
    },
    {
      permissions: [QUOTE_PERMISSIONS.MARK_QUOTE],
      text: 'Mark as Won',
      icon: CheckIconRounded,
      onClick: markWonHandler,
      disabled: [ QUOTE_STATUS.WON, QUOTE_STATUS.REJECTED ].includes(quote.status) || !quote.quoted_price,
      className: classes.markWon
    },
    {
      permissions: [QUOTE_PERMISSIONS.MARK_QUOTE],
      text: 'Mark as Lost',
      icon: ErrorIconRounded,
      onClick: markLostHandler,
      disabled: ![ QUOTE_STATUS.OPEN, QUOTE_STATUS.RESPONDED, QUOTE_STATUS.EXPIRED ].includes(quote.status) || !quote.quoted_price,
      className: classes.markLost
    }], [quote]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>

      <ModalComponent
        message={MARK_QUOTE_WON_HEADER}
        isOpen={isWonDialogOpen}
        onCancel={() => setIsWonDialogOpen(false)}>
        <MarkQuoteWonModal quoteId={quote.id} onCancel={() => setIsWonDialogOpen(false)}/>
      </ModalComponent>
      <ModalComponent
        message={MARK_QUOTE_LOST_HEADER}
        isOpen={isLostDialogOpen}
        onCancel={() => () => setIsLostDialogOpen(false)}>
        <MarkQuoteLostModal quoteId={quote.id} onCancel={() => setIsLostDialogOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={`Are you sure you want to delete quote "${quote.system_id}"?`}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}>
        <DeleteQuoteModal quoteId={quote.id} onCancel={() => setIsDeleteDialogOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={`Are you sure you want to duplicate "${quote.system_id}"?`}
        isOpen={isDuplicateDialogOpen}
        onCancel={() => setIsDuplicateDialogOpen(false)}>
        <DuplicateQuoteModal quoteId={quote.id} onCancel={() => setIsDuplicateDialogOpen(false)}/>
      </ModalComponent>
    </>
  );
};