import React from 'react';
import ReactJson from 'react-json-view';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './TabContent.css';

type TabContentProps = {
  body: Record<string, unknown>,
  showRaw: boolean,
};

export const TabContent = ({ body, showRaw } : TabContentProps) => {
  const { innerWidth } = window;
  const classes = useStyles();

  return (
    <Grid className={classes.gridContainer}>
      { body ?
        <pre>
          {showRaw ?
            JSON.stringify(body, null, 4) :
            <ReactJson
              src={body}
              collapseStringsAfterLength={innerWidth}
              displayDataTypes={false}
              enableClipboard={false}
              displayObjectSize={false}/>}
        </pre> :
        <Typography>No body</Typography> }
    </Grid>
  );
};