import React, { FC, useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { SelectFilter } from 'components';
import LiteralEditor from 'components/RuleEngine/LiteralEditor';
import VariableEditor from 'components/RuleEngine/VariableEditor';
import OperationEditor from 'components/RuleEngine/OperationEditor';
import SimpleConfirmationModal from 'components/Modals/SimpleConfirmationModal';
import { initExpression, initLiteral } from 'services/ruleEngine';
import { Expression,
  ExpressionEditorBaseProps,
  ExpressionType,
  Operation,
  Option,
} from 'types';

import expressionEditorStyles from 'components/RuleEngine/ExpressionEditor.css';

interface ExpressionEditorProps extends ExpressionEditorBaseProps {
  expression: Expression | null;
}

const EXPRESSION_TYPES: Option[] = [
  {
    id: ExpressionType.LITERAL,
    label: 'Constant',
  },
  {
    id: ExpressionType.VARIABLE,
    label: 'Variable',
  },
  {
    id: ExpressionType.OPERATION,
    label: 'Operation',
  },
];

const ExpressionEditor: FC<ExpressionEditorProps> = ({ resultType, config, expression: ex, onUpdate, isReadOnly = false}) => {
  const styles = expressionEditorStyles();
  // If the expression is not defined, we define it to a literal based on result's type
  const [ expression, setExpression ] = useState<Expression>(ex || initLiteral(resultType));
  const [ isResetModalOpen, setIsResetModalOpen ] = useState(false);
  const expressionCache = useRef<Map<ExpressionType, Expression>>(new Map());

  useEffect(() => {
    expressionCache.current.set(expression.type, expression);
    onUpdate(expression);
  }, [expression]);

  const onExpressionTypeChange = (type: ExpressionType) => {
    setExpression(expressionCache.current.get(type) || initExpression(resultType, type, expression));
  };

  const onNodeReset = () => {
    setExpression(initExpression(resultType, expression.type, expression));
    setIsResetModalOpen(false);
  };

  const onArgUpdate = (arg: Expression) => {
    const operation = expression as Operation;
    const args = [...operation.args];
    const replacedArgIdx = args.findIndex(a => a.priority === arg.priority);
    args[replacedArgIdx] = arg;
    setExpression({ ...operation, args });
  };

  const resetAction = (
    <Tooltip title='Reset Node'>
      <IconButton onClick={() => setIsResetModalOpen(true)} disabled={isReadOnly}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  );

  const editor = (
    <>
      {expression.type === ExpressionType.LITERAL &&
        <LiteralEditor
          key={expression.id}
          resultType={resultType}
          expression={expression}
          onUpdate={setExpression}
          isReadOnly={isReadOnly} />
      }
      {expression.type === ExpressionType.VARIABLE &&
        <VariableEditor
          key={expression.id}
          resultType={resultType}
          config={config}
          expression={expression}
          onUpdate={setExpression}
          isReadOnly={isReadOnly} />
      }
      {expression.type === ExpressionType.OPERATION &&
        <OperationEditor
          key={expression.id}
          resultType={resultType}
          config={config}
          expression={expression}
          onUpdate={setExpression}
          isReadOnly={isReadOnly} />
      }
    </>
  );

  const subheader = (
    <div className={styles['expression-node-wrapper__subheader']}>
      <SelectFilter
        className={styles['expression-node-wrapper__header-control']}
        defaultValue={expression.type}
        onChange={onExpressionTypeChange}
        options={EXPRESSION_TYPES}
        placeholder='Choose Expression Type'
        isReadOnly={isReadOnly}/>
      {
        editor &&
        <div className={styles['expression-node-wrapper__subheader-content']}>
          {editor}
        </div>
      }
    </div>
  );

  return (
    <Card className={styles['expression-node-wrapper']}>
      <CardHeader
        className={styles['expression-node-wrapper__header']}
        action={resetAction}
        subheader={subheader} />
      {
        // If the expression is an operation with arguments, then the arguments need
        // to be recursively rendered as child components of the operation expression.
        expression.type === ExpressionType.OPERATION && !!expression.args.length &&
          <CardContent className={styles['expression-node-wrapper__children']}>
            {
              expression.args
                .sort((a, b) => a.priority - b.priority)
                .map((arg, i) => (
                  <ExpressionEditor
                    key={expression.name + i}
                    resultType={resultType}
                    config={config}
                    expression={arg}
                    onUpdate={onArgUpdate}
                    isReadOnly={isReadOnly}/>
                ))
            }
          </CardContent>
      }
      <SimpleConfirmationModal
        isOpen={isResetModalOpen}
        title='Are you sure you want to reset the expression?'
        confirmLabel='Yes'
        rejectLabel='No'
        onConfirm={onNodeReset}
        onReject={() => setIsResetModalOpen(false)} />
    </Card>
  );
};

export default ExpressionEditor;
