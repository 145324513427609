import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { Layout, NavigationButton } from 'components';
import { PAGE_TYPE } from 'enums';
import { Params } from 'types';
import { CreateUpdateQuoteForm } from './components/CreateUpdateQuoteForm';

import { useStyles } from './CreateUpdateQuotePage.css';

type CreateUpdateQuotePageProps = {
  type: string,
};

export const CreateUpdateQuotePage = ({ type }: CreateUpdateQuotePageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { id: quoteId } = useParams<Params>();

  let title = '';
  let urlTo = '';

  switch (type) {
    case PAGE_TYPE.CREATE:
      title = 'Add New Quote';
      urlTo = '/dashboard';
      break;
    case PAGE_TYPE.EDIT:
      title = `Edit Quote \u2022 ${quoteId || ''}`;
      urlTo = `/quotes/${quoteId}`;
      break;
  }

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo={urlTo} />}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {title}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CreateUpdateQuoteForm onCancel={() => history.push(urlTo)}/>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};