import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { EmailField, FormModalContent, RejectionReasonField } from 'components';
import {
  QUOTE_SOURCE,
  QUOTE_STATUS,
  REJECTION_STATUS_LABELS
} from 'enums';
import { useMarkQuoteMutation, useRejectMutation } from 'services';
import { QuoteDetails } from 'types';
import { getValidationSchema } from './validation';

type MarkQuoteRejectedModalProps = {
  quote: QuoteDetails,
  isDirect?: boolean,
  onCancel: () => void
};

export const MarkQuoteRejectedModal = ({ quote, isDirect, onCancel } : MarkQuoteRejectedModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: markAsRejected, isLoading: isMarkingQuote } = useMarkQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully marked as Rejected');
      }
    }
  );

  const { mutate: directReject, isLoading: isRejectingQuote } = useRejectMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully rejected');
      }
    }
  );

  const handleSave = ({ reason, email } : {reason: string, email?: string}) => {
    const rejectionReason = reason ? REJECTION_STATUS_LABELS[Number(reason)] : '';
    if (isDirect) {
      directReject({ id: quote.id, rejection_reason: rejectionReason, email: quote.quote_source ? '' : email });
    } else {
      markAsRejected({ id: quote.id, status: QUOTE_STATUS.REJECTED, reason: rejectionReason });
    }
  };

  return (
    <FormModalContent
      initialValues={ isDirect && quote.quote_source === QUOTE_SOURCE.QQT_UI ?
        { reason: '', email: quote.customer_email } : { reason: '' }
      }
      validationSchema={(isDirect && quote.quote_source === QUOTE_SOURCE.QQT_UI) && getValidationSchema}
      isActionInProgress={isRejectingQuote || isMarkingQuote}
      onCancel={onCancel}
      enableDivider={false}
      onSubmit={handleSave}>
      {isDirect && !quote.quote_source ?
        <>
          <RejectionReasonField />
          <EmailField />
        </> : < RejectionReasonField />}
    </FormModalContent>
  );
};