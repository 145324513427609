import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { Layout, NavigationButton } from 'components';
import { PAGE_TYPE } from 'enums';
import { Params } from 'types';

import { UploadAwardForm } from './UploadAwardForm/UploadAwardForm';
import { useStyles } from './UploadAwardPage.css';

type UploadAwardPageProps = {
  type: string,
};

export const UploadAwardPage = ({ type }: UploadAwardPageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<Params>();
  const awardId = Number(id);

  const getPageTitle = () => {
    let title = '';
    switch (type) {
      case PAGE_TYPE.CREATE:
        title = 'Upload Awards';
        break;
      case PAGE_TYPE.EDIT:
        title = `Edit Award \u2022 ${awardId || ''}`;
        break;
    }
    return title;
  };

  const handleCancel = () => {
    history.push('/awards');
  };

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo='/awards' />}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {getPageTitle()}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UploadAwardForm
              onCancel={handleCancel} />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
