import enGB from 'date-fns/locale/en-GB';
import { format } from 'date-fns-tz';
import { formatDistanceToNowStrict, formatDuration } from 'date-fns';

import { getDateWithTZOffset } from 'helpers';

const formatDistanceLocale = {
  xSeconds: '{{count}} sec',
  xMinutes: '{{count}} min',
  xHours: '{{count}} hr',
  xDays: '{{count}} day',
  xWeeks: '{{count}} wk',
  xMonths: '{{count}} mo',
  xYears: '{{count}} yr',
};

const formatDistance = (token, count) => {
  const result = formatDistanceLocale[token].replace('{{count}}', count);
  return result;
};

export const formatDateToDetailPageFormat = (date, isDate = false) => {
  const newDate = isDate ? getDateWithTZOffset(date) : new Date(date);
  return format(newDate, 'EEE, MMM dd');
};

export const formatDateToDashboardPageFormat = (date, isDate = false) => {
  const newDate = isDate ? getDateWithTZOffset(date) : new Date(date);
  return format(newDate, 'MM/dd/yyyy');
};

export const formatTime = (datetime) => {
  return format(new Date(datetime), 'HH:mm zzz', { locale: enGB });
};

export const formatDateTime = (datetime, isDate = false) => {
  const newDatetime = isDate ? getDateWithTZOffset(datetime) : new Date(datetime);
  return format(newDatetime, 'MM/dd/yyyy HH:mm:ss');
};

export const formatDateTimeWith12HourClock = (datetime, isDate = false) => {
  const newDatetime = isDate ? getDateWithTZOffset(datetime) : new Date(datetime);
  return format(newDatetime, 'MM/dd/yyyy hh:mm aaa');
};

export const formatSeparatelyDateTimeWith12HourClock = (datetime, isDateTime = false) => {
  const newDatetime = isDateTime ? getDateWithTZOffset(datetime) : new Date(datetime);
  return {
    date: format(newDatetime, 'MM/dd/yyyy'),
    time: format(newDatetime, 'hh:mm aaa')
  };
};

export const formatTimeToTimePassed = (datetime) => {
  const formatedTime = formatDistanceToNowStrict(new Date(datetime), { locale: {enGB, formatDistance}});
  return formatedTime;
};

export const formatCounterTime = ({ days, hours, minutes, seconds }) => {
  return formatDuration(
    {
      days,
      hours,
      minutes,
      seconds
    },
    {
      format: [
        'days',
        'hours',
        'minutes',
        'seconds'
      ],
      locale: {
        formatDistance
      }
    });
};

export const formatToUniqueOptionsByProperty = (items, propertyName) => {
  // Format an array of objects for select options to have sorted not empty unique labels with passed property name
  return _.uniqWith(items?.filter(item => item[propertyName]), (a, b) => a[propertyName] === b[propertyName])
    .map((item) => ({ id: item.id, label: item[propertyName] }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
