import * as Yup from 'yup';

import {
  VALIDATION_EMAIL_MSG,
  VALIDATION_REQUIRED_MSG
} from 'enums';

export const getValidationSchema = () => {
  const validationSchema = Yup.object({
    email: Yup.string().required(VALIDATION_REQUIRED_MSG).email(VALIDATION_EMAIL_MSG)
  });

  return validationSchema;
};
