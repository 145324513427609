import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  text: {
    ...theme.typography.bodyRegularXS,
  },
  tooltip: {
    border: `1px solid ${theme.palette.primary.primary700}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 14
  },
  stopsButton: {
    justifyContent: 'space-evenly',
    color: theme.palette.primary.primary700,
    background: theme.palette.neutral.neutral0,
    border: '1px solid',
    '&:hover': {
      color: theme.palette.neutral.neutral0,
      border: `1px solid ${theme.palette.primary.primary700}`,
      background: theme.palette.primary.primary700,
    },
    '& .MuiButton-label': {
      gap: '0.5rem',
    }
  }
}));