import { useEffect, useState } from 'react';

import { formatCounterTime } from './dataFormaters';

export const mergeDateTime = (date, time) => {
  const desiredDate = new Date();
  if (date && time) {
    desiredDate.setFullYear(date.getFullYear());
    desiredDate.setMonth(date.getMonth());
    desiredDate.setDate(date.getDate());
    desiredDate.setHours(time.getHours() || 0);
    desiredDate.setMinutes(time.getMinutes() || 0);
  }
  return desiredDate;
};

export const getDatetimeStamp = (daysOffset) => {
  const date = new Date();
  date.setDate(date.getDate() + daysOffset || 0);
  return date;
};

export const getDateFromDatetime = (date) => {
  const year = date.getFullYear();
  // Month index starts from 0
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const getDateWithTZOffset = (date) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  return dtDateOnly;
};

export const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [ countDown, setCountDown ] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const [ days, hours, minutes, seconds ] = getDateValues(countDown);
  const counter = formatCounterTime({ days, hours, minutes, seconds });
  const hasCounterExpired = days + hours + minutes + seconds <= 0;

  return [ counter, hasCounterExpired ];
};

const getDateValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [ days, hours, minutes, seconds ];
};
