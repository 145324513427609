import React from 'react';
import { Button, CircularProgress, Divider } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './ActionButton.css';

export type ButtonVariants = 'text' | 'outlined' | 'contained';

type ActionButtonProps = {
  handleClick?: () => void,
  type?: 'button' | 'submit' | 'reset' | undefined,
  disabled?: boolean,
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  variant?: 'primary' | 'secondary' | 'default' | 'textPrimary' | 'textSecondary',
  text?: React.ReactNode | string,
  colorVariant?: 'white' | 'green' | 'red',
  className?: string | undefined,
  children?: React.ReactNode,
  isLoading?: boolean
};

export const ActionButton = ({
  handleClick,
  type,
  text = '',
  variant = 'primary',
  disabled,
  startIcon,
  endIcon,
  className,
  colorVariant,
  children,
  isLoading,
}: ActionButtonProps) => {
  const classes = useStyles();

  let muiVariant: ButtonVariants = 'outlined';
  switch (variant) {
    case 'primary':
      muiVariant = 'contained';
      break;
    case 'secondary':
    case 'default':
      muiVariant = 'outlined';
      break;
    case 'textPrimary':
    case 'textSecondary':
      muiVariant = 'outlined';
      break;
  }

  return (
    <Button
      onClick={handleClick}
      variant={muiVariant}
      disableFocusRipple
      disableElevation
      className={clsx({
        [classes.button]: true,
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
        [classes.default]: variant === 'default',
        [classes.textPrimary]: variant === 'textPrimary',
        [classes.textSecondary]: variant === 'textSecondary',
        [classes.whiteButton]: colorVariant === 'white',
        [classes.greenButton]: colorVariant === 'green',
        [classes.redButton]: colorVariant === 'red',
        [className]: className,
      })}
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      disabled={disabled}
      type={type}>
      {
        isLoading &&
          <div className={classes.loadingSpinner}>
            <CircularProgress color='inherit' size={20} />
          </div>
      }
      <div style={{opacity: isLoading ? 0 : 1, display: 'flex'}}>
        {children ?? text}
      </div>
      {endIcon && <Divider orientation='vertical' variant='middle' flexItem />}
    </Button>
  );
};