import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, ModalComponent } from 'components';
import { useDeleteAwardLanesMutation } from 'services';

type DeleteAwardLanesModalContentProps = {
    awardId: number,
    selectedAwardLanes: { id: number }[],
    onDeleteSuccess: () => void,
    onCancel: () => void,
  };

const DeleteAwardLanesModalContent = ({
  awardId,
  selectedAwardLanes,
  onDeleteSuccess,
  onCancel
}: DeleteAwardLanesModalContentProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteAwardLanes, isLoading } = useDeleteAwardLanesMutation(queryClient, {
    onSuccess: () => {
      toast.success('Award lanes successfully deleted.');
      onDeleteSuccess();
      onCancel();
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const deleteAwardLanesHandler = () => {
    const awardLaneIds = selectedAwardLanes.map(awardLanesIds => awardLanesIds.id);
    const payload = {
      award_lanes: awardLaneIds
    };
    deleteAwardLanes({ awardId: awardId, payload });
  };

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={deleteAwardLanesHandler}
      onCancel={onCancel} />
  );
};

type DeleteAwardLanesModalProps = {
    awardId: number,
    selectedAwardLanes: { id: number }[],
    isModalOpen: boolean,
    onCancel: () => void,
    onDeleteSuccess: () => void
  };

export const DeleteAwardLanesModal = ({
  isModalOpen,
  onCancel,
  awardId,
  selectedAwardLanes,
  onDeleteSuccess
} : DeleteAwardLanesModalProps) => {
  return (
    <ModalComponent
      message={`Are you sure you want to delete ${selectedAwardLanes.length} lanes?`}
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <DeleteAwardLanesModalContent
        awardId={awardId}
        selectedAwardLanes={selectedAwardLanes}
        onDeleteSuccess={onDeleteSuccess}
        onCancel={onCancel} />
    </ModalComponent>
  );
};