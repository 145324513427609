import React, { useMemo, useState } from 'react';

import {
  ConfirmationDialog,
  CreateUpdateRuleModal,
  ModalComponent,
  TableMenu,
} from 'components';
import { ExpressionEditorConfig, Identified, Rule } from 'types';
import { CloneIcon, DeleteIcon, Pencil } from 'resources';

type RuleTableMenuProps = {
  rule: Rule,
  rules: Rule [],
  ruleEditorConfiguration: ExpressionEditorConfig,
  permissions: string[],
  handleDeleteRule: (rule: Identified) => void,
  handleDuplicateRule: (rule: Rule) => void,
  handleEditRule: (rule: Rule) => void
};

export const RuleTableMenu = ({ rule, rules, ruleEditorConfiguration, permissions, handleDeleteRule, handleDuplicateRule, handleEditRule}: RuleTableMenuProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState<boolean>(false);
  const [ isUpdateRuleModalOpen, setIsUpdateRuleModalOpen ] = useState<boolean>(false);

  const editRuleHandler = () => {
    setIsUpdateRuleModalOpen(true);
    setAnchorEl(null);
  };

  const deleteRuleHandler = () => {
    setIsDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const duplicateRuleHandler = () => {
    handleDuplicateRule(rule);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: permissions,
      text: 'Edit',
      icon: Pencil,
      onClick: editRuleHandler,
    },
    {
      permissions: permissions,
      text: 'Duplicate',
      icon: CloneIcon,
      onClick: duplicateRuleHandler,
    },
    {
      permissions: permissions,
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteRuleHandler,
    },
  ], [rule]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>

      <ModalComponent
        message={`Are you sure you want to delete rule "${rule.name}"?`}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}>
        <ConfirmationDialog
          isActionInProgress={false}
          onSubmit={() => {
            handleDeleteRule(rule);
            setIsDeleteDialogOpen(false);
          }}
          onCancel={() => setIsDeleteDialogOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={`Edit Rule \u2022 ${rule.name}`}
        isOpen={isUpdateRuleModalOpen}
        modalWidth='xl'
        onCancel={() => setIsUpdateRuleModalOpen(false)}
        disableEnforceFocus={true}>
        {<CreateUpdateRuleModal rules={rules} rule={rule} ruleEditorConfiguration={ruleEditorConfiguration} handleCreateEditRule={handleEditRule} onCancel={() => setIsUpdateRuleModalOpen(false)} />}
      </ModalComponent>
    </>
  );
};