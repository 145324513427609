import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
  datePicker: {
    '& .MuiPickersModal-dialog': {
      '& .MuiIconButton-root:hover': {
        backgroundColor: theme.palette.primary.primary100,
      },
      '& .MuiPickersDay-current': {
        color: theme.palette.primary.primary700,
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: theme.palette.primary.primary700,
        color: theme.palette.neutral.neutral0,
      },
    },
    '& .MuiDialogActions-root': {
      '& .MuiButton-textPrimary': {
        color: theme.palette.primary.primary700,
      },
    }
  },
  formControl: {
    width: '100%',
    '& > div > div.MuiInputBase-root.MuiInput-root': {
      ...theme.typography.bodyRegularS,
      backgroundColor: theme.palette.neutral.neutral0,
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
      color: theme.palette.neutral.neutral700,
      padding: '0.5rem 1rem',
      '&:focus-within': {
        border: `1px solid ${theme.palette.primary.primary700}`,
      },
      '&:hover:not(:focus-within)': {
        border: `1px solid ${theme.palette.primary.primary700}`,
      },
      '& > input': {
        padding: 0
      },
      '& .MuiButtonBase-root': {
        padding: 0,
      },
      '& input::placeholder': {
        color: theme.palette.neutral.neutral400,
        opacity: 1,
      },
    },
    '& > p.MuiFormHelperText-contained': {
      ...theme.typography.bodyRegularXS,
      color: theme.palette.error.main,
      margin: '5px 0 0 0',
    },
    '& > div > div.MuiInput-underline:after': {
      content: 'none'
    },
    '& > div > div.MuiInput-underline:before': {
      content: 'none'
    },
  },
  error: {
    '& > div > div.MuiInputBase-root.MuiInput-root': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}));
