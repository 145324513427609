import React from 'react';
import { Divider, Skeleton, Typography } from '@mui/material';
import { useStyles } from './WidgetStates.css';

export const WidgetLoadingState = () => {
  const classes = useStyles();
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <div key={index}>
          <Skeleton className={classes.skeleton}/>
          <Divider/>
        </div>
      ))}
    </>
  );
};

export const WidgetNoResultsState = ({ message }: { message: string }) => {
  const classes = useStyles();
  return <Typography className={classes.message}>{message}</Typography>;
};