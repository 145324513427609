import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  button: {
    ...theme.typography.h5,
    lineHeight: '1.25rem',
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 20px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    width: 'auto',
    whiteSpace: 'nowrap',
    '& hr': {
      backgroundColor: theme.palette.primary.primary500,
      margin: 'auto 0.5rem',
    },
    '&:disabled': {
      '& hr': {
        margin: 'auto 0.5rem',
      },
    },
    '& .MuiDivider-middle': {
      width: '1px',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },
  primary: {
    background: theme.palette.primary.primary700,
    color: theme.palette.neutral.neutral0,
    '& svg > path': {
      fill: theme.palette.neutral.neutral0,
    },
    '&:hover': {
      background: theme.palette.primary.primary500,
      color: theme.palette.neutral.neutral0,
    },
    '&:disabled': {
      background: theme.palette.primary.primary200,
      color: theme.palette.neutral.neutral0,
      '& hr': {
        backgroundColor: theme.palette.primary.primary100,
      },
    },
  },
  secondary: {
    fontWeight: 500,
    background: theme.palette.primary.primary100,
    color: theme.palette.primary.primary700,
    borderColor: theme.palette.primary.primary700,
    '& svg > path': {
      fill: theme.palette.primary.primary700,
    },
    '&:hover': {
      background: theme.palette.primary.primary200,
      color: theme.palette.primary.primary700,
    },
    '&:disabled': {
      background: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
      borderColor: theme.palette.primary.primary700,
      opacity: '50%',
      '& hr': {
        backgroundColor: theme.palette.primary.primary500,
      },
    },
  },
  default: {
    fontWeight: 500,
    background: theme.palette.primary.primary100,
    color: theme.palette.neutral.neutral700,
    borderColor: theme.palette.secondary.secondary500,
    '& svg > path': {
      fill: theme.palette.neutral.neutral700,
    },
    '&:hover': {
      background: theme.palette.neutral.neutral100,
      color: theme.palette.neutral.neutral700,
    },
    '&:disabled': {
      background: theme.palette.primary.primary200,
      color: theme.palette.neutral.neutral700,
      borderColor: theme.palette.secondary.secondary500,
      opacity: '50%'
    },
  },
  textPrimary: {
    fontWeight: 500,
    border: 0,
    boxShadow: 'none',
    background: theme.palette.neutral.neutral0,
    color: theme.palette.primary.primary700,
    '&:hover': {
      background: theme.palette.neutral.neutral0,
      color: theme.palette.secondary.secondary700,
    },
  },
  textSecondary: {
    fontWeight: 400,
    border: 0,
    boxShadow: 'none',
    background: theme.palette.neutral.neutral0,
    color: theme.palette.neutral.neutral700,
    '&:hover': {
      background: theme.palette.neutral.neutral0,
      color: theme.palette.secondary.secondary700,
    },
  },
  whiteButton: {
    background: theme.palette.neutral.neutral0,
    color: theme.palette.neutral.neutral700,
    '&:hover': {
      background: theme.palette.neutral.neutral100,
      color: theme.palette.neutral.neutral700,
    },
  },
  greenButton: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    '& svg > path': {
      fill: theme.palette.success.main,
    },
    '&:hover': {
      background: theme.palette.success.dark,
      color: theme.palette.success.main,
    },
    '&:disabled': {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
      opacity: 0.5,
    },
  },
  redButton: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    '& svg > path': {
      fill: theme.palette.error.main,
    },
    '&:hover': {
      background: theme.palette.error.dark,
      color: theme.palette.error.main,
    },
    '&:disabled': {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
      opacity: 0.5,
    },
  },
  loadingSpinner: {
    display: 'flex',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      fill: theme.palette.primary.primary700
    }
  }
}));