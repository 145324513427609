import React, { useMemo, useState } from 'react';

import { DeleteAwardsModal, ModalComponent, TableMenu } from 'components';
import { AWARD_PERMISSIONS } from 'permissions/AwardPermissions';
import { DeleteIcon, LaneIcon, Pencil } from 'resources';
import { Award } from 'types';

type AwardTableMenuProps = {
  award: Award,
  onActionSuccess: () => void,
};

export const AwardTableMenu = ({ award, onActionSuccess } : AwardTableMenuProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isDeleteAwardsModalOpen, setIsDeleteAwardsModalOpen ] = useState<boolean>(false);

  const deleteAwardHandler = () => {
    setIsDeleteAwardsModalOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [AWARD_PERMISSIONS.UPDATE],
      text: 'Edit',
      icon: Pencil,
      urlTo: `/awards/${award.id}/edit`,
    },
    {
      permissions: [AWARD_PERMISSIONS.LIST_AWARD_LANES],
      text: 'Lanes',
      icon: LaneIcon,
      urlTo: `/awards/${award.id}/lanes`,
    },
    {
      permissions: [AWARD_PERMISSIONS.DELETE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteAwardHandler,
    },
  ], [award]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>
      <ModalComponent
        message={null}
        isOpen={isDeleteAwardsModalOpen}
        onCancel={() => setIsDeleteAwardsModalOpen(false)}>
        <DeleteAwardsModal message={`Are you sure you want to delete award "${award.name}"?`} isModalOpen={true} onCancel={() => setIsDeleteAwardsModalOpen(false)} selectedAwards={[{id: award.id}]} onDeleteSuccess={onActionSuccess}/>
      </ModalComponent>
    </>
  );
};