import React from 'react';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import { Box, Divider } from '@material-ui/core';

import { QuoteDetails as QuoteDetailsType } from 'types';
import { QuoteDetailsHeader } from './QuoteDetailsHeader/QuoteDetailHeader';
import { LocationInfo } from './LocationInfo/LocationInfo';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';

import { useStyles } from './QuoteDetails.css';

type QuoteDetailsProps = {
  quote: QuoteDetailsType
  refetchQuote: (options?: RefetchOptions) => Promise<QueryObserverResult<AxiosResponse<QuoteDetailsType>, unknown>>
};

export const QuoteDetails = ({ quote, refetchQuote } : QuoteDetailsProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainInfoContainer}>
      <QuoteDetailsHeader quote={quote} refetchQuote={refetchQuote} />
      <Divider />
      <Box className={classes.mainInfoContent}>
        <LocationInfo quote={quote} />
        <Divider orientation='vertical' />
        <GeneralInfo quote={quote} />
      </Box>
    </Box>
  );
};