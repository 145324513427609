import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  listItemButton: {
    display: 'flex',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.5rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.125rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.primary.primary100,
  },
  listItemIcon: {
    justifyContent: 'center'
  },
  lightTooltip: {
    ...theme.typography.bodyRegularXS,
    display: 'inline-flex',
    padding: '0.5rem 1rem',
    alignItems: 'flex-start',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral.neutral700,
    boxShadow: `0rem 0.5rem 0.5rem 0rem ${theme.palette.primary.primary200}`
  }
}));
