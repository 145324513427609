import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  button: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.primary100,
    color: theme.palette.primary.primary700,
    display: 'flex',
    justifyContent: 'start',
    marginLeft: 0,
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
    '& .MuiButton-iconSizeSmall': {
      marginRight: '0.75rem',
    },
  },
}));