import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  filterButtonWrapper: {
    marginLeft: '.8rem',
    position: 'relative',
    boxSizing: 'border-box',
    textTransform: 'none',
    '& btn': {
      background: theme.palette.primary.primary200,
      color: theme.palette.secondary.secondary700,
    }
  },
  filterContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.info.light,
    borderRadius: '8px',
    display: 'flex',
    gap: '4rem',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    padding: '1rem',
    position: 'relative',
  },
  filterActionsContainer: {
    marginLeft: 'auto',
  },
  filterControl: {
    display: 'flex',
    gap: '1.25rem',
    height: '2.5rem',
    justifyContent: 'end',
  },
  filterCounter: {
    ...theme.typography.bodyXXS,
    alignItems: 'center',
    backgroundColor: theme.palette.info.main,
    borderRadius: '50%',
    color: theme.palette.neutral.neutral0,
    display: 'flex',
    height: '1rem',
    justifyContent: 'center',
    minWidth: '1.2rem',
    position: 'absolute',
    right: '-0.5rem',
    top: '-0.5rem',
  },
  filterIndicator: {
    backgroundColor: theme.palette.info.light,
    height: '1rem',
    left: '0',
    position: 'absolute',
    top: '-0.5rem',
    transform: 'rotate(45deg)',
    width: '1rem',
    zIndex: 0
  },
  filterMainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    position: 'relative',
  },
  gridItem: {
    padding: 0,
    '& .MuiInputBase-root': {
      height: '2.5rem',
    },
  },
  resetFilterIcon: {
    marginRight: '-0.8rem',
  },
  resetFilterButton: {
    height: '100%',
    padding: 0,
    minWidth: '2.5rem',
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  openedFiltersButton: {
    background: theme.palette.primary.primary200,
    color: theme.palette.secondary.secondary700,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.secondary200,
      color: theme.palette.secondary.secondary700,
    },
  },
  closedFiltersButton: {
    background: theme.palette.neutral.neutral0,
    color: theme.palette.secondary.secondary700,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.neutral.neutral100,
      color: theme.palette.secondary.secondary700,
    },
  },
  nSelect: {
    '& .MuiSelect-root': {
      paddingRight: '2rem !important',
    },
    '& .MuiSelect-icon': {
      margin: 'auto 0.5rem !important',
    },
  },
}));