import React from 'react';

import { StatusChip } from 'components';
import { formatDateToDashboardPageFormat } from 'helpers';
import { Column, Award } from 'types';

export const AwardTableColumns : Column[] = [
  {id: 'name', label: 'Award Name', sortable: true, orderingField: 'name'},
  {id: 'customer_name', label: 'Shipper Name', sortable: true, orderingField: 'customer_name'},
  {id: 'region', label: 'Region', sortable: true, orderingField: 'region'},
  {id: 'effective_date', label: 'Effective Date', orderingField: 'effective_date', customRender: (Award: Award) => formatDateToDashboardPageFormat(Award.effective_date, true), sortable: true},
  {id: 'expiration_date', label: 'Expiration Date', orderingField: 'expiration_date', customRender: (Award: Award) => formatDateToDashboardPageFormat(Award.expiration_date, true), sortable: true},
  {id: 'expiration_status', label: 'Status', sortable: false, customRender: (Award: Award) => (
    <StatusChip status={Award.expiration_status}/>
  )},
];