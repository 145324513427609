import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  content: {
    marginBottom: '1rem'
  },
  uploadInput: {
    backgroundColor: theme.palette.primary.primary100,
    border: `1px dashed ${theme.palette.secondary.secondary500}`,
    borderRadius: '8px',
    padding: '1.5rem',
    '& .MuiButtonBase-root': {
      margin: '1rem auto',
      width: 'fit-content'
    },
  },
  uploadButton: {
    background: 'transparent',
    border: `1px solid ${theme.palette.secondary.secondary500}`,
    color: theme.palette.neutral.neutral700,
    '&:hover': {
      '& svg': {
        '& path': {
          fill: theme.palette.primary.primary700,
        },
      },
    },
  },
  validationError: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
    marginBottom: '1rem',
  },
  importText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral700,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  formControl: {
    marginBottom: '1rem',
    width: '100%',
  },
  buttonText: {
    color: theme.palette.primary.primary700,
    '&:hover': {
      color: theme.palette.primary.primary700,
    }
  }
}));