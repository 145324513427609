export const generateStatusTitle = (code: number): string => {
  switch (code) {
    case 2:
      return '2XX';
    case 200:
      return '200 OK';
    case 201:
      return '201 Created';
    case 202:
      return '202 Accepted';
    case 204:
      return '204 No Content';
    case 4:
      return '4XX';
    case 400:
      return '400 Bad Request';
    case 401:
      return '401 Unauthorized';
    case 403:
      return '403 Forbidden';
    case 404:
      return '404 Not Found';
    case 409:
      return '409 Conflict';
    case 415:
      return '415 Unsupported Media Type';
    case 5:
      return '5XX';
    case 500:
      return '500 Internal Server Error';
    case 502:
      return '502 Bad Gateway';
    case 503:
      return '503 Service Unavailable';
    default:
      return `${code} Unknown status`;
  }
};