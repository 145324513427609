import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useAuth } from 'contexts';
import {
  AccessControl,
  ActionButton,
  DeleteFuelSheetsModal,
  FuelSheetDetailsModal,
  ImportFuelSheetModal,
  Layout,
  TableComponent
} from 'components';
import { checkPermissions } from 'helpers';
import { FILE_PERMISSIONS, FUEL_SHEET_PERMISSIONS } from 'permissions';
import { DeleteIcon, UploadIcon } from 'resources';
import { useGetCustomerById, useGetFuelSheetsByCustomerId } from 'services';
import { FuelSheet, Params } from 'types';

import { FuelSheetTableConfig } from './components/FuelSheetsTableConfig';
import { FuelSheetTableMenu } from './components/FuelSheetTableMenu';

import { useStyles } from './FuelSheetsPage.css';

const PAGE_SIZE = 10;

export const FuelSheetsPage = () => {
  const classes = useStyles();
  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ selectedFuelSheets, setSelectedFuelSheets ] = useState<{ id: number }[]>([]);
  const [ fuelSheetId, setFuelSheetId ] = useState<number>(0);
  const [ isFuelSheetModalOpen, setIsFuelSheetModalOpen ] = useState<boolean>(false);
  const [ isDeleteFuelSheetsModalOpen, setIsDeleteFuelSheetsModalOpen ] = useState<boolean>(false);
  const [ isImportFuelSheetModalOpen, setIsImportFuelSheetModalOpen ] = useState<boolean>(false);

  const { id } = useParams<Params>();
  const customerId = Number(id);

  const { isSuccess: isCustomerSuccess, data: customerData } = useGetCustomerById(customerId);
  const { data: customer } = customerData || {};
  const { isLoading: isFuelSheetsLoading, isSuccess, data: fuelSheetsData, refetch } = useGetFuelSheetsByCustomerId(customerId, rowsPerPage, page * rowsPerPage);
  const { data: fuelSheets } = fuelSheetsData || {};

  const rowClickHandler = (fuelSheetId: number) => {
    setIsFuelSheetModalOpen(true);
    setFuelSheetId(fuelSheetId);
  };

  const { currentUser } = useAuth();

  return (
    <Layout goBackTo='/shippers' title={isCustomerSuccess && `${customer.customer_name} - Fuel Sheets`}>
      <Grid container className={classes.headerWrapper}>
        <AccessControl permissions={[FILE_PERMISSIONS.IMPORT]}>
          <ActionButton
            text='Upload'
            handleClick={ () => setIsImportFuelSheetModalOpen(true)}
            startIcon={<UploadIcon/>}
            className={classes.headerButton} />
        </AccessControl>
      </Grid>
      <TableComponent
        columns={FuelSheetTableConfig}
        isSuccess={isSuccess}
        isLoading={isFuelSheetsLoading}
        tableData={fuelSheets?.results}
        areRowsSelectable={checkPermissions(currentUser.permissions, [FUEL_SHEET_PERMISSIONS.DELETE])}
        selectedRows={selectedFuelSheets}
        isTablePaginated={true}
        page={page}
        pageRows={rowsPerPage}
        rows={fuelSheets?.count}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        setSelectedRows={setSelectedFuelSheets}
        rowClickHandler={rowClickHandler}
        bulkMenuConfig={[
          {
            icon: DeleteIcon,
            permissions: [FUEL_SHEET_PERMISSIONS.DELETE],
            label: 'Delete Fuel Sheets',
            handleClick: () => setIsDeleteFuelSheetsModalOpen(true),
          },
        ]}
        renderMenu={(fuelSheet: FuelSheet) => {
          return (
            <FuelSheetTableMenu fuelSheet={fuelSheet} onActionSuccess={() => setSelectedFuelSheets([])}/>
          );
        }} />
      <FuelSheetDetailsModal fuelSheetId={fuelSheetId} isModalOpen={isFuelSheetModalOpen} onCancel={() => {
        setIsFuelSheetModalOpen(false);
        setSelectedFuelSheets([]);
      }} />
      <DeleteFuelSheetsModal isModalOpen={isDeleteFuelSheetsModalOpen} selectedFuelSheets={selectedFuelSheets} onDeleteSuccess={() => setSelectedFuelSheets([])} onCancel={() => setIsDeleteFuelSheetsModalOpen(false)}/>
      <ImportFuelSheetModal
        isModalOpen={isImportFuelSheetModalOpen}
        customer={customer} onCancel={() => setIsImportFuelSheetModalOpen(false)}
        onUploadSuccess={() => {
          refetch();
          setSelectedFuelSheets([]);
        }}/>
    </Layout>
  );
};