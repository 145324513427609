import React from 'react';
import { Checkbox, Stack, TableCell } from '@mui/material';

import { AccessControl, ConditionalWrapper, TooltipComponent } from 'components';

import { useStyles } from './BulkMenu.css';

export type BulkMenuOption = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>,
  permissions: string[],
  label: string,
  handleClick: () => void,
}

type BulkMenuProps = {
  config: BulkMenuOption[],
  checked: boolean,
  indeterminate: boolean,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  numberOfColumns: number
}

export const BulkMenu = ({config, checked, indeterminate, handleChange, numberOfColumns}: BulkMenuProps) => {
  const classes = useStyles();

  return (
    <>
      <TableCell className={classes.bulkMenuCell}>
        <Checkbox
          color='primary'
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleChange}/>
      </TableCell>
      <TableCell colSpan={numberOfColumns} className={classes.bulkMenuCell}>
        <Stack direction='row' spacing={5} >
          {config.map((item: BulkMenuOption, index: number) => (
            <ConditionalWrapper
              condition={!!item.permissions}
              wrapper={(children) => <AccessControl permissions={item.permissions}>{children}</AccessControl>}
              key={index}>
              <Stack key={index}>
                <TooltipComponent title={item.label} placement='top'>
                  <item.icon onClick={item.handleClick} className={classes.icon}/>
                </TooltipComponent>
              </Stack>
            </ConditionalWrapper>
          ))}
        </Stack>
      </TableCell>
    </>
  );
};
