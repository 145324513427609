import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { API_URL } from '../config';
import { toast } from 'react-toastify';

import { Opts, QuoteFilter, User } from 'types';

const USER_API_URL = '/v1/user';
const DEFAULT_USER_FILTERS_API = '/v1/update-default-filters/';
const CURRENT_USER_API_URL = '/v1/current-user/';
const GS_TOKEN_API_URL= '/v1/gs-token/';
const USER_QUERY_KEY = 'user';
const CURRENT_USER_QUERY_KEY = 'current-user';
const GS_TOKEN_QUERY_KEY = 'gs-token';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  users: {
    list: (filters?: string) => axios.get(`${API_URL}${USER_API_URL}/?${filters}`),
    currentUser: () => axios.get(`${API_URL}${CURRENT_USER_API_URL}`),
    defaultFilters: ({ payload } : {payload: QuoteFilter}) => axios.post(`${API_URL}${DEFAULT_USER_FILTERS_API}`, { default_filters: payload }),
    gsToken: () => axios.get(`${API_URL}${GS_TOKEN_API_URL}`)
  },
};

export const useGetUsersQuery = (filters?: string) => {
  return useQuery<AxiosResponse<User[]>>([ USER_QUERY_KEY, { filters }], () => api.users.list(filters), { enabled: filters ? !!filters : true});
};

export const useGetCurrentUserQuery = (enabled: boolean) => {
  return useQuery([CURRENT_USER_QUERY_KEY], () => api.users.currentUser(), { enabled: enabled });
};

export const useUpdateFiltersMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.users.defaultFilters, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
    },
    onError: (error: AxiosError<unknown>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });
export const useGetGsTokenQuery = (queryOptions = {}) => {
  return useQuery<AxiosResponse<string>>([GS_TOKEN_QUERY_KEY], () => api.users.gsToken(), {...queryOptions});
};