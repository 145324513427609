import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import {
  AccessControl,
  ActionButton,
  DeleteAwardsModal,
  FilterComponent,
  Layout,
  TableComponent
} from 'components';
import { FILTER_PAGE_SOURCE, FILTER_SET_SOURCE, AWARDS_FILTER_CONFIG } from 'enums';
import { addSelectAllOption, calculateNumberOfFilterChanges, getAppliedFilters, getPageAppliedFilters, setPageAppliedFilters, sortOptions } from 'helpers';
import { FILE_PERMISSIONS, AWARD_PERMISSIONS } from 'permissions';
import { DeleteIcon, UploadIcon } from 'resources';
import { useGetCustomersQuery, useAwardsQuery } from 'services';
import { Customer, FilterParamsType, QueryResult, Award, AwardFilter } from 'types';
import { AwardTableColumns } from './components/AwardTableConfig';
import { AwardTableMenu } from './components/AwardTableMenu';

import { useStyles } from './AwardsPage.css';

const PAGE_SIZE = 10;

export const AwardsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.AWARD);
  const mapSelectedFiltersToQuery = (filterParams: FilterParamsType, search: string) => {
    const customer = filterParams?.shipper as Array<string | number> || ['all'];

    return {
      customer: customer.includes('all') ? '' : customer?.join(),
      search,
    };
  };

  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ ordering, setOrdering ] = useState<string>('');
  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search);
  const [ filterParams, setFilterParams ] = useState<FilterParamsType>(getAppliedFilters(appliedFiltersAndSearch));
  const [ searchFilters, setSearchFilters ] = useState<Record<string, string>>({shipper: '',});
  const [ filter, setFilter ] = useState<AwardFilter>(mapSelectedFiltersToQuery(filterParams, search) || {});
  const [ filterChangesNumber, setFilterChangesNumber ] = useState(0);
  const [ selectedAwards, setSelectedAwards ] = useState<{ id: number }[]>([]);
  const [ isDeleteAwardsModalOpen, setIsDeleteAwardsModalOpen ] = useState<boolean>(false);

  const { isSuccess, isError, isFetching, data } = useAwardsQuery(stringify(filter), rowsPerPage, page * rowsPerPage, ordering);
  const { data: awards } = data || {};

  const { data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery(stringify({
    search: searchFilters.shipper,
    name_only: true
  }));
  const customers = customerData?.data?.map((c: Customer) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const filterOptions = {
    shipper: addSelectAllOption(sortOptions(customers), 'All Shippers'),
  };

  const applyFilters = useCallback(() => {
    const query = mapSelectedFiltersToQuery(filterParams, search);
    setFilter(query);
    setPageAppliedFilters(FILTER_PAGE_SOURCE.AWARD, query.search, filterParams);
    setFilterChangesNumber(calculateNumberOfFilterChanges(AWARDS_FILTER_CONFIG, filterParams));
  }, [ filterParams, search ]);

  const applySearch = () => {
    const query = {
      ...filter,
      search,
    };
    debounceFilters(query, appliedFiltersAndSearch);
  };

  useEffect(applyFilters, []);

  useEffect(applySearch, [search]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce((query, appliedFiltersAndSearch) => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.AWARD, query.search, getAppliedFilters(appliedFiltersAndSearch));
    }, 300),
    []
  );

  const rowClickHandler = (awardId: number) => {
    history.push('/awards/' + awardId + '/lanes');
  };

  return (
    <Layout title='Awards'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.AWARD}
        filterSetSource={FILTER_SET_SOURCE.AWARD}
        filterConfig={AWARDS_FILTER_CONFIG}
        filterOptions={filterOptions}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        applyFilters={applyFilters}
        searchText={search}
        setSearchText={setSearch}
        searchParams={searchFilters}
        setSearchParams={setSearchFilters}
        filterChangesNumber={filterChangesNumber}
        setFilterChangesNumber={setFilterChangesNumber}>
        <AccessControl permissions={[FILE_PERMISSIONS.IMPORT]}>
          <ActionButton
            text='Upload'
            variant='primary'
            handleClick={() => history.push('awards/upload')}
            startIcon={<UploadIcon />}
            className={classes.headerButton} />
        </AccessControl>
      </FilterComponent>
      <TableComponent
        columns={AwardTableColumns}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={awards?.results}
        areRowsSelectable
        selectedRows={selectedAwards}
        rowClickHandler={rowClickHandler}
        page={page}
        rows={awards?.count}
        pageRows={rowsPerPage}
        setOrdering={ordering => setOrdering(ordering)}
        defaultOrder={'desc'}
        defaultOrderBy={'created_at'}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        setSelectedRows={setSelectedAwards}
        bulkMenuConfig={[
          {
            icon: DeleteIcon,
            permissions: [AWARD_PERMISSIONS.DELETE],
            label: 'Delete Awards',
            handleClick: () => setIsDeleteAwardsModalOpen(true),
          },
        ]}
        renderMenu={(award: Award) => {
          return (
            <AwardTableMenu award={award} onActionSuccess={() => setSelectedAwards([])}/>
          );
        }}/>
      <DeleteAwardsModal
        isModalOpen={isDeleteAwardsModalOpen}
        selectedAwards={selectedAwards}
        message={`Are you sure you want to delete ${selectedAwards.length} awards?`}
        onDeleteSuccess={() => setSelectedAwards([])}
        onCancel={() => setIsDeleteAwardsModalOpen(false)}/>
    </Layout>
  );
};