import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  menu: {
    borderRadius: '8px',
    boxShadow: `0px 2px 8px ${theme.palette.secondary.secondary200}`,
    marginLeft: '0.5rem',
    marginTop: '2rem',
    width: '12.5rem',
    '& ul': {
      padding: 0,
      '& .MuiDivider-root': {
        margin: 0
      }
    }
  },
  menuItem: {
    '&.MuiButtonBase-root': {
      padding: '0.625rem 1.25rem',
      width: '100%',
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          ...theme.typography.bodyRegularS,
        }
      }
    },
    '&.MuiButtonBase-root:hover': {
      background: theme.palette.primary.primary100,
    },
    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.primary700,
        '& path': {
          fill: theme.palette.primary.primary700
        }
      },
    },
    '& svg': {
      fill: theme.palette.neutral.neutral700
    },
  },
  defaultColor: {
    '&.MuiButtonBase-root:hover': {
      color: theme.palette.info.main,
      '& .MuiListItemIcon-root': {
        color: theme.palette.info.main
      }
    },
  },
  divider: {
    background: theme.palette.primary.primary200
  },
}));