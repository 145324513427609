import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';

import { calendarInputIcon } from 'resources';

import { useStyles } from './DatePickerField.css';

type DatePickerFieldProps = {
  id: string,
  label: string,
  disablePast?: boolean,
  maxDate?: string | Date,
  minDate?: string | Date
};

export const DatePickerField = ({ id, label, disablePast = true, maxDate, minDate }: DatePickerFieldProps) => {
  const classes = useStyles();

  return (
    <div>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Field name={id}>
        {({ form } : FieldProps) => (
          <FormControl variant='outlined' error={!!form.errors[id]} className={clsx({
            [classes.formControl]: true,
            [classes.error]: !!form.errors[id]})
          }>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disableToolbar
                format='MM/dd/yyyy'
                invalidDateMessage=''
                name={id}
                placeholder='MM/DD/YYYY'
                value={form.values[id] ? form.values[id] : null}
                error={!!form.errors[id]}
                disablePast={disablePast}
                autoOk
                maxDate={maxDate}
                maxDateMessage=''
                minDate={minDate}
                minDateMessage=''
                onChange={d => form.setFieldValue(id, d)}
                keyboardIcon={<img src={calendarInputIcon}/>} />
            </MuiPickersUtilsProvider>
            <FormHelperText>{(form.errors as Record<string, string>)[id] || ' '}</FormHelperText>
          </FormControl>
        )}
      </Field>
    </div>
  );
};