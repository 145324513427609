import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  addPadding: {
    padding: '2px',
  },
  costTypeWidth: {
    width: '60%'
  },
  customerInitial: {
    ...theme.typography.bodyRegularS,
    textAlign: 'center',
    width: '65%',
  },
  historyArrowIcon: {
    height: '1rem',
    width: '1rem',
  },
  historyPrice: {
    boxSizing: 'border-box',
    paddingLeft: '0.4rem',
    textAlign: 'left',
    width: '50%',
    '& h4': {
      fontSize: '0.875rem',
    }
  },
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  flexItem: {
    width: '50%'
  },
  itemContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    '& p': {
      ...theme.typography.bodyRegularS
    }
  },
  linkItem: {
    alignItems: 'center',
    padding: '2px',
  },
  locationArrow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  noWidth: {
    maxWidth: 0
  },
  timePassed: {
    width: '35%',
    paddingLeft: '1.25rem'
  },
}));

