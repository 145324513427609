import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  searchWrapper: {
    display: 'flex',
    flex: 1,
    gap: '1.125rem',
    justifyContent: 'start',
  },
  headerButton: {
    height: '2.5rem',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon > *:first-child': {
        fontSize: '0.875rem',
      },
    },
  },
}));