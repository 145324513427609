import { Award } from 'types';

export const getUploadAwardMetaDataInitialValues = (award?: Award) => {
  return {
    name: award ? award.name : '',
    customer: award ? null : '',
    region: award ? award.region : '',
    effective_date: award ? award.effective_date : null,
    expiration_date: award ? award.expiration_date : null,
  };
};
