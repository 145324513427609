import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ActionButton } from 'components';
import { NO_NOTES_TEXT } from 'enums';
import { CheckIcon, DocumentIcon, EditNotesIcon, XMark } from 'resources';

import { useStyles } from './Notes.css';

type NotesProps = {
  headerTitle: string,
  defaultNotes: string,
  onSave?: (notes: string) => void,
  onSaveInProgress?: boolean,
  areNotesEditable?: boolean,
  variant: 'purple' | 'yellow',
  isEditable?: boolean,
  setIsEditable? : (isEditable: boolean) => void,
};

export const Notes = ({ headerTitle, defaultNotes, onSave, onSaveInProgress, areNotesEditable = true, variant, isEditable = false, setIsEditable } : NotesProps) => {
  const classes = useStyles();
  const textareaRef = useRef(null);

  const [ notes, setNotes ] = useState<string>(defaultNotes);

  useEffect(() => {
    if (isEditable) {
      textareaRef.current.readOnly = false;
      textareaRef.current.style.display = 'block';
      textareaRef.current.focus();
      if (notes === NO_NOTES_TEXT) {
        setNotes('');
      }
      return;
    }
    if (!notes) {
      textareaRef.current.style.display = 'none';
    }
    textareaRef.current.readOnly = true;
  }, [isEditable]);

  useEffect(() => {
    setNotes(defaultNotes);
  }, [defaultNotes]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const handleCancel = () => {
    setIsEditable(false);
    setNotes(defaultNotes);
  };

  return (
    <Box className={clsx(classes.container, {
      [classes.purpleGradientBackground]: variant === 'purple',
      [classes.yellowGradientBackground]: variant === 'yellow'
    })}>
      <Box className={clsx(classes.header)}>
        <Box className={clsx(classes.iconContainer, {
          [classes.purpleBackground]: variant === 'purple',
          [classes.yellowBackground]: variant === 'yellow',
        })}>
          <DocumentIcon />
        </Box>
        <Typography variant='h4'>{headerTitle}</Typography>
        {
          !isEditable && areNotesEditable &&
            <EditNotesIcon className={classes.editIcon} onClick={() => setIsEditable(true)} />
        }
      </Box>
      <TextField
        fullWidth
        variant='outlined'
        className={clsx({[classes.inputContent]: true, [classes.textareaCursor]: !isEditable, [classes.notesHeight]: isEditable})}
        multiline
        onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
        inputProps={{
          ref: textareaRef
        }}
        value={notes}
        onChange={(e) => handleChange(e)}/>
      {
        isEditable &&
          <>
            <Divider className={classes.divider}/>
            <Box className={classes.buttonContainer}>
              <ActionButton
                variant='secondary'
                startIcon={<XMark />}
                disabled={onSaveInProgress}
                handleClick={handleCancel} />
              <ActionButton
                isLoading={onSaveInProgress}
                startIcon={<CheckIcon />}
                disabled={onSaveInProgress || notes === defaultNotes}
                handleClick={() => onSave(notes)}/>
            </Box>
          </>
      }
    </Box>
  );
};