import { AxiosResponse } from 'axios';
import mime from 'mime';

import { FILE_CATEGORY } from 'enums';
import { FileType } from 'types';

export const downloadFile = (response: AxiosResponse, file: FileType) => {
  const link = document.createElement('a');
  link.type = file.type;
  link.download = file.name;
  //because users can enter freely file name that may or may not contains file extension
  //we need to check if the file name has an extension or if it is a correct one
  //if not we need to set file extension derived from the file type
  const suffixFileExtension = `.${mime.getExtension(file.type)}`;
  if (!file.name.endsWith(suffixFileExtension)) {
    link.download += suffixFileExtension;
  }
  //some browsers might not choose to use the specified desired file name set in the download attribute on an anchor (<a>) tag
  //where one of the reasons will be when the Content-Disposition Header is set by a server like in responseURL.data
  //this will solve the problem
  // Create a URL for the blob data
  const blobUrl = window.URL.createObjectURL(
    new Blob([response.data])
  );
  link.href = blobUrl;
  link.click();
  window.URL.revokeObjectURL(blobUrl);
};

export const isDifferentFileCategory = (specificCategory: FILE_CATEGORY, category: Array<string>) => {
  return category?.length && category[0] !== specificCategory.toString();
};
