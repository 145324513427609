import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import formatISO from 'date-fns/formatISO';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import { AccessControl, ActionButton, FilterComponent, Layout, TableComponent } from 'components';
import { FILTER_PAGE_SOURCE, FILTER_SET_SOURCE, QUOTES_FILTER_CONFIG, QUOTE_STATUS_LABELS } from 'enums';
import { addSelectAllOption, calculateNumberOfFilterChanges, getAppliedFilters, getPageAppliedFilters, setPageAppliedFilters, sortOptions } from 'helpers';
import { QUOTE_PERMISSIONS } from 'permissions';
import { useGetCustomersQuery, useGetEquipmentTypesQuery, useGetUsersQuery, useQuotesQuery } from 'services';
import { Customer, DateRangeType, FilterParamsType, Location, QueryResult, Quote, QuoteFilter, User } from 'types';

import { QuoteTableConfig } from './components/QuoteTableConfig';
import { QuoteTableMenu } from './components/QuoteTableMenu';

import { useStyles } from './DashboardPage.css';

const PAGE_SIZE = 10;

export const DashboardPage = () => {
  const history = useHistory();
  const classes = useStyles();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.QUOTE);

  const mapSelectedFiltersToQuery = (quoteFilters: FilterParamsType, search: string) => {
    const {fromDate = null, toDate = null} = quoteFilters.createdAt as DateRangeType;
    const formatFromDate = fromDate ? formatISO(new Date(fromDate), { representation: 'date' }) : null;
    const formatToDate = toDate ? formatISO(new Date(toDate), { representation: 'date' }) : null;

    const createdBy = quoteFilters.createdBy as Array<string | number>;
    const customer = quoteFilters.shipper as Array<string | number>;
    const status = quoteFilters.status as Array<string | number>;
    const equipment = quoteFilters.equipment as Array<string | number>;
    const respondedBy = quoteFilters.respondedBy as Array<string | number>;
    const origin = quoteFilters.origin as Location;
    const destination = quoteFilters.destination as Location;

    return {
      created_by: createdBy.includes('all') ? '' : createdBy.join(),
      created_at_after: formatFromDate,
      created_at_before: formatToDate,
      customer: customer.includes('all') ? '' : customer.join(),
      search,
      status: status.includes('all') ? '' : status.join(),
      equipment: equipment.includes('all') ? '' : equipment.join(),
      responded_by: respondedBy.includes('all') ? '' : respondedBy.join(),
      origin: stringify(origin),
      destination: stringify(destination),
    };
  };

  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ ordering, setOrdering ] = useState<string>('');
  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ quoteFilters, setQuoteFilters ] = useState<FilterParamsType>(getAppliedFilters(appliedFiltersAndSearch));
  const [ searchFilters, setSearchFilters ] = useState<Record<string, string>>({
    shipper: '',
    createdBy: '',
    respondedBy: '',
  });
  const [ filter, setFilter ] = useState<QuoteFilter>(mapSelectedFiltersToQuery(quoteFilters, search) || {});
  const [ filterChangesNumber, setFilterChangesNumber ] = useState(0);

  const { isSuccess, isError, isFetching, data } = useQuotesQuery(stringify(filter), rowsPerPage, page * rowsPerPage, ordering);
  const { data: quotes } = data || {};

  const userSearch = searchFilters.createdBy.length === 0 ? searchFilters.respondedBy : searchFilters.createdBy;
  const { data: userData } = useGetUsersQuery(stringify({search: userSearch}));
  const users = userData?.data?.map((c: User) => {
    return { id: c.id, label: c.display_name };
  }) || [];

  const { data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery(stringify({search: searchFilters.shipper, name_only: true}));
  const customers = customerData?.data?.map((c) => ({ id: c.id, label: c.customer_name })) || [];

  const { data: equipmentData } = useGetEquipmentTypesQuery();
  const equipments = equipmentData?.data?.map((eq) => {
    return { id: eq.id, label: eq.type };
  }) || [];

  const filterOptions = useMemo(() => {
    if ( userData?.data === null || customerData?.data === null || equipmentData?.data === null) {
      return {};
    }
    return {
      shipper: addSelectAllOption(sortOptions(customers), 'All Shippers'),
      status: addSelectAllOption(
        Object.keys(QUOTE_STATUS_LABELS).map((key: string) => ({id: key, label: QUOTE_STATUS_LABELS[key]})),
        'All Statuses'),
      equipment: addSelectAllOption(sortOptions(equipments), 'All Equipment'),
      createdBy: addSelectAllOption(sortOptions(users), 'All Creators'),
      respondedBy: addSelectAllOption(sortOptions(users), 'All Responders'),
    };
  }, [ userData?.data, customerData?.data, equipmentData?.data ]);

  const applyFilters = useCallback(() => {
    const query = mapSelectedFiltersToQuery(quoteFilters, search);
    setFilter(query);
    setPageAppliedFilters(FILTER_PAGE_SOURCE.QUOTE, query.search, quoteFilters);
    setFilterChangesNumber(calculateNumberOfFilterChanges(QUOTES_FILTER_CONFIG, quoteFilters));
  }, [ quoteFilters, search ]);

  const applySearch = () => {
    const query = {
      ...filter,
      search,
    };
    debounceFilters(query, appliedFiltersAndSearch);
  };

  useEffect(applyFilters, []);

  useEffect(applySearch, [search]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce((query, appliedFiltersAndSearch) => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.QUOTE, query.search, getAppliedFilters(appliedFiltersAndSearch));
    }, 300),
    []
  );

  return (
    <Layout title='Quotes Dashboard'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.QUOTE}
        filterSetSource={FILTER_SET_SOURCE.QUOTE}
        filterConfig={QUOTES_FILTER_CONFIG}
        filterOptions={filterOptions}
        filterParams={quoteFilters}
        setFilterParams={setQuoteFilters}
        applyFilters={applyFilters}
        searchText={search}
        setSearchText={setSearch}
        searchParams={searchFilters}
        setSearchParams={setSearchFilters}
        filterChangesNumber={filterChangesNumber}
        setFilterChangesNumber={setFilterChangesNumber}>
        <Grid className={classes.actionsWrapper}>
          <AccessControl permissions={[QUOTE_PERMISSIONS.CREATE]}>
            <ActionButton
              className={classes.button}
              text='New Quote'
              variant='primary'
              handleClick={() => history.push('/quotes/create')}
              startIcon={<FontAwesomeIcon icon={faPlus}/>}/>
          </AccessControl>
        </Grid>
      </FilterComponent>
      <TableComponent
        columns={QuoteTableConfig()}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={quotes?.results}
        page={page}
        pageRows={rowsPerPage}
        rows={quotes?.count}
        setOrdering={ordering => setOrdering(ordering)}
        defaultOrder={'desc'}
        defaultOrderBy={'id'}
        areRowsSelectable={false}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        renderMenu={(quote: Quote) => {
          return (
            <QuoteTableMenu quote={quote} />
          );
        }}/>
    </Layout>
  );
};