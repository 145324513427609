import React from 'react';
import { useStyles } from './LogInfoLine.css';
import { Grid } from '@mui/material';

type LogInfoLineProps = {
    text: string,
    value?: string,
};

export const LogInfoLine = ({ text, value} : LogInfoLineProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item className={classes.titleText}>
        {text}:
      </Grid>
      <Grid item className={classes.valueText}>
        {value || 'N/A'}
      </Grid>
    </Grid>
  );
};