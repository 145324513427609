import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, ModalComponent } from 'components';
import { useDeleteCustomersMutation } from 'services';
import { Customer } from 'types';

type DeleteCustomerModalProps = {
  isOpen: boolean,
  selectedCustomers: Customer[],
  onCancel: () => void,
  onDeleteSuccess: (customers: React.SetStateAction<Customer[]>) => void,
};

export const DeleteCustomersModal = ({ isOpen, selectedCustomers, onCancel, onDeleteSuccess } : DeleteCustomerModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteCustomers, isLoading } = useDeleteCustomersMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      onDeleteSuccess([]);
      toast.success('Shippers successfully deleted.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
      onCancel();
    }
  });

  return (
    <ModalComponent
      message={`Are you sure you want to delete ${selectedCustomers.length} shippers?`}
      isOpen={isOpen}
      onCancel={onCancel}>
      <ConfirmationDialog
        isActionInProgress={isLoading}
        onSubmit={() => deleteCustomers({ids: selectedCustomers.map(customer => customer.id)})}
        onCancel={onCancel}/>
    </ModalComponent>
  );
};
