import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  icon: {
    width: '1.125rem',
    height: '1.125rem',
    '& path': {
      fill: theme.palette.secondary.secondary700,
    },
    '&:hover': {
      cursor: 'pointer',
      '& path': {
        fill: theme.palette.primary.primary700,
      },
    },
  },
  bulkMenuCell: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    background: theme.palette.primary.primary200,
    whiteSpace: 'nowrap',
    borderBottomColor: theme.palette.primary.primary200,
    position: 'sticky',
    top: 0,
    zIndex: 10,
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.primary.primary200,
      borderRadius: 'inherit',
      borderColor: theme.palette.neutral.neutral700,
      color: theme.palette.primary.primary700,
      marginLeft: '0.5rem',
      padding: 0,
      width: '1rem',
      height: '1rem',
    }
  },
}));
