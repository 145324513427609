import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog } from 'components';
import { useOverwriteFilterSetMutation } from 'services';
import { FilterParamsType } from 'types';

type DeleteFilterSetModalProps = {
  selectedFilterSetId: number,
  sourceFilterValues: FilterParamsType,
  onCancel: () => void,
};

export const OverwriteFilterSetModal = ({ selectedFilterSetId, sourceFilterValues, onCancel } : DeleteFilterSetModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: overwriteFilterSet, isLoading: isDeletingFilterSet } = useOverwriteFilterSetMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Filters successfully overwritten.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isDeletingFilterSet}
      onSubmit={() => overwriteFilterSet({ target_filter_set: selectedFilterSetId, source_filter_values: sourceFilterValues })}
      onCancel={onCancel}/>
  );
};
