import React, { useState } from 'react';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';

import { AccessControl, ActionButton, DuplicateQuoteModal, InfoBox, ModalComponent, StatusChip } from 'components';
import { EQUIPMENT_LABEL, QUOTE_SOURCE, QUOTE_STATUS, QUOTE_STATUS_LABELS } from 'enums';
import { formatDateToDashboardPageFormat } from 'helpers';
import { QuoteDetails as QuoteDetailsType, StoppingPoint } from 'types';
import { QUOTE_PERMISSIONS } from 'permissions';
import { duplicateIcon } from 'resources';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { QuoteEquipment } from '../QuoteEquipment/QuoteEquipment';

import { useStyles } from './QuoteDetailHeader.css';

type QuoteDetailsHeaderProps = {
  quote: QuoteDetailsType,
  refetchQuote: (options?: RefetchOptions) => Promise<QueryObserverResult<AxiosResponse<QuoteDetailsType>, unknown>>
};

export const QuoteDetailsHeader = ({ quote, refetchQuote } : QuoteDetailsHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [ isDuplicateDialogOpen, setIsDuplicateDialogOpen ] = useState(false);

  return (
    <>
      <Box className={classes.infoBox}>
        {quote?.equipment_type === EQUIPMENT_LABEL.INTERMODAL && (
          <InfoBox type='info' message='This is a basic door-to-door intermodal SPOT quote. Additional service or longer term pricing available upon request. Email impricing@nfiindustries.com.'></InfoBox>
        )}
      </Box>
      <Box className={classes.infoBox}>
        {quote?.custom_fields?.nearest_delivery && (
          <InfoBox type='info' message={`To provide accurate rates, Greenscreens requires unique Pickup and Delivery locations. Greenscreens rates are calculated based on the nearest geographical location to the provided Delivery. The location used for calculation is ${(quote.custom_fields.nearest_delivery as StoppingPoint['location']).name}`}></InfoBox>
        )}
      </Box>
      <Box className={classes.infoBox}>
        {quote?.equipment_type === EQUIPMENT_LABEL.POWER_ONLY && (
          <InfoBox type='info' message='This power only quote is considered a round trip. The price prediction is based on the total sum of rates acquired for both the original and reverse lanes.' />
        )}
      </Box>
      <Box className={classes.mainInfoHeader}>
        <Box className={classes.quoteInfoHeader}>
          <Box className={classes.idSection}>
            <Typography variant='h1'>{quote.system_id}</Typography>
            <Box>
              <StatusChip status={QUOTE_STATUS_LABELS[quote.status]} />
            </Box>
            <QuoteEquipment quote={quote} refetchQuote={refetchQuote} />
          </Box>
          <Typography className={classes.quoteDate}>{formatDateToDashboardPageFormat(quote.created_at)}</Typography>
        </Box>
        <Box className={classes.actionSection}>
          <AccessControl permissions={[QUOTE_PERMISSIONS.DUPLICATE_QUOTE]}>
            <ActionButton
              colorVariant='white'
              startIcon={<img src={duplicateIcon} />}
              variant='default'
              handleClick={() => setIsDuplicateDialogOpen(true)}
              text={'Duplicate'} />
          </AccessControl>
          <AccessControl permissions={[QUOTE_PERMISSIONS.UPDATE]}>
            {
              quote.status === QUOTE_STATUS.OPEN &&
                <ActionButton
                  variant='secondary'
                  disabled={quote.quote_source !== QUOTE_SOURCE.QQT_UI}
                  startIcon={<FontAwesomeIcon className={classes.buttonIcon} icon={faPen} />}
                  handleClick={() => history.push(`/quotes/${quote.id}/edit`)}
                  text={'Edit Quote'} />
            }
          </AccessControl>
        </Box>
      </Box>
      <ModalComponent
        message={`Are you sure you want to duplicate "${quote.system_id}"?`}
        isOpen={isDuplicateDialogOpen}
        onCancel={() => setIsDuplicateDialogOpen(false)}>
        <DuplicateQuoteModal quoteId={quote.id} onCancel={() => setIsDuplicateDialogOpen(false)}/>
      </ModalComponent>
    </>
  );
};
