import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

type Props = {
  disabledDelete: boolean;
};

export const useStyles = makeStyles<ThemeDefault, Props>((theme: ThemeDefault) => ({
  dropTrailerLabel: {
    marginLeft: '1.5rem',
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      borderRadius: 'inherit',
      color: theme.palette.primary.primary200,
      marginRight: '0.5rem',
      padding: 0,
      width: '1rem',
      height: '1rem',
    },
    '& .MuiCheckbox-colorSecondary.Mui-unchecked': {
      color: theme.palette.primary.primary500,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    '&.MuiFormControlLabel-root > span:nth-of-type(2)': {
      ...theme.typography.bodyRegularS,
    },
  },
  fullWidth: {
    width: '100%',
    margin: '0.5rem 0',
  },
  dragIndicatorIcon: {
    color: theme.palette.primary.primary700,
  },
  icon: {
    alignItems: 'end',
    display: 'grid',
    marginBottom: '0.3rem',
  },
  deleteIconContainer: {
    justifyContent: 'end',
    marginLeft: '1rem',
    cursor: ({ disabledDelete }: Props) => disabledDelete ? 'default' : 'pointer',
    '& svg': {
      marginBottom: '0.25rem',
      '& path': {
        fill: ({ disabledDelete }: Props) => disabledDelete ? theme.palette.secondary.secondary500 : theme.palette.neutral.neutral700,
      }
    },
  },
}));