import React from 'react';

import { ModalComponent, UploadFileModal } from 'components';
import { FILE_CATEGORY } from 'enums';

type ImportCustomerModalProps = {
  isModalOpen: boolean,
  onCancel: () => void
  onUploadSuccess: () => void
};

export const ImportCustomerModal = ({ isModalOpen, onCancel, onUploadSuccess } : ImportCustomerModalProps) => {
  return (
    <ModalComponent
      message='Upload Shippers'
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <UploadFileModal
        category={FILE_CATEGORY.SHIPPERS}
        onCancel={onCancel}
        onUploadSuccess={onUploadSuccess}/>
    </ModalComponent>
  );
};
