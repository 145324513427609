import React from 'react';
import clsx from 'clsx';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { STOP_ACTIVITY_TYPES } from 'enums';
import { Collapse } from '../Collapse/Collapse';

import { useStyles } from './StopsTimeline.css';

type StopsTimelineProps = {
  stopData: {
    order: number,
    name: string,
    activityType: STOP_ACTIVITY_TYPES
  }[]
}
export const StopsTimeline = ({ stopData }: StopsTimelineProps) => {
  const classes = useStyles();
  return (

    <Collapse hideText='Hide Stops' showText='Show Stops'>
      <Timeline className={classes.timelineContainer}>
        {stopData.map((stop) => (
          <TimelineItem key={stop.order}>
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineDot />
              <TimelineConnector className={clsx(stop.order === stopData.length && classes.hideConnector)} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              {stop.order}. {stop.name}
              <span className={classes.activitiTypeLabel}>{stop.activityType === STOP_ACTIVITY_TYPES.PICKUP ? '(Pickup)' : '(Delivery)'}</span>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Collapse>
  );
};
