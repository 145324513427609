import { Location, Option } from 'types';

export type FilterParamsValueType = DateRangeType | Array<string | number> | Location;

export type FilterConfigType = {
  type: FilterType;
  paramsProperty: string;
  placeholder: string;
  initialValue: FilterParamsValueType;
  enableSearch?: boolean
}

export type DateRangeType = {
  fromDate: Date,
  toDate: Date,
}

export type FilterParamsType = {
  [key: string]: FilterParamsValueType
}

export type FilterSearchParamsType = {
  [key: string]: FilterParamsValueType | string
}

export enum FilterType {
  Select = 1,
  MultiSelect = 2,
  DateRange = 3,
  LocationSearch = 4,
}

export type ShipperFilterParamsType = {
  selectedShipper: Option[],
  selectedUsers: Option[],
  respondedBy: Option[],
  selectedStatus: Option[],
  selectedRange: DateRangeType,
}

export type FilterFormType = {
  name: string,
}

export type FilterSetUpsertPayload = {
  name: string,
  filter_source: number,
  filter_values: FilterParamsType,
};
