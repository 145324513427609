import * as Yup from 'yup';

import { MAX_FUEL_PRICE_DELAY, VALIDATION_EMAIL_MSG, VALIDATION_REQUIRED_MSG } from 'enums';

const maxCharWarning = (num: number) => `Must be below ${num} characters`;

export const getCustomerUpsertValidationSchema = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required(VALIDATION_REQUIRED_MSG).max(100, maxCharWarning(100)),
    code: Yup.string().required(VALIDATION_REQUIRED_MSG).max(255, maxCharWarning(255)),
    email: Yup.string().email(VALIDATION_EMAIL_MSG),
    contactName: Yup.string().max(32, maxCharWarning(32)),
    phone: Yup.string().max(32, maxCharWarning(32)),
    profitCenter: Yup.string().max(12, maxCharWarning(12)),
    defaultFuelPriceDelay: Yup.number().min(0, 'Fuel price delay must be greater than or equal to 0').max(MAX_FUEL_PRICE_DELAY, `Fuel price delay must be less than or equal to ${MAX_FUEL_PRICE_DELAY}`),
    notes: Yup.string().max(200, maxCharWarning(200)),
    channelId: Yup.string().max(32, maxCharWarning(32)),
    quoteExpirationPeriod: Yup.number().nullable().integer().min(1, 'Quote expiration period must be greater than or equal to 1'),
    vertical: Yup.string().nullable().max(30, maxCharWarning(30)),
  });

  return validationSchema;
};
