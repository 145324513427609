import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from 'components';
import { useDuplicateQuoteMutation } from 'services';

type DuplicateQuoteModalProps = {
  quoteId: number,
  onCancel: () => void
};

export const DuplicateQuoteModal = ({ quoteId, onCancel }: DuplicateQuoteModalProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate: duplicateQuote, isLoading: isDuplicatingQuote } = useDuplicateQuoteMutation(queryClient, {
    onSuccess: (response) => {
      onCancel();
      history.push(`/quotes/${response.data.id}`);
      toast.success('Quote successfully duplicated');
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isDuplicatingQuote}
      onSubmit={() => duplicateQuote(quoteId)}
      onCancel={onCancel}/>
  );
};
