import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { QuoteDetails as QuoteDetailsType } from 'types';
import { ShipperDetailItem } from '../ShipperDetailItem/ShipperDetailItem';
import { QuoteInfo } from '../QuoteInfo/QuoteInfo';

import { useStyles } from './GeneralInfo.css';

type GeneralInfoProps = {
  quote: QuoteDetailsType
};

export const GeneralInfo = ({ quote } : GeneralInfoProps) => {
  const classes = useStyles();

  const formatShipperReferences = () => {
    const shipper_quote_id = quote.shipper_quote_id;
    const shipper_ref_number = quote.shipper_ref_number;

    if ( shipper_quote_id === shipper_ref_number) {
      return shipper_quote_id;
    }

    return `${shipper_quote_id || ' - '} / ${shipper_ref_number || ' - '}`;
  };

  return (
    <Box className={classes.generalInfo}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Shipper'
            value={quote.customer_name}
            labelSize='md'
            valueSize='lg'
            reserveMultipleLinesSpace />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Shipper References'
            value={formatShipperReferences()}
            labelSize='md'
            valueSize='lg' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem label='Source'
            value={quote.custom_fields?.auction_link ? 'Auction Link' : null}
            url={quote.custom_fields?.auction_link as string}
            labelSize='md'
            valueSize='sm' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Outbound Market Strength'
            value={quote.outbound_capacity?.toString()}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Inbound Market Strength'
            value={quote.inbound_capacity?.toString()}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Combined Market Strength'
            value={
              quote.inbound_capacity && quote.outbound_capacity ? ((quote.inbound_capacity + quote.outbound_capacity) / 2).toString() : null
            }
            valueSize='sm' />
        </Grid>
      </Grid>
      <QuoteInfo quote={quote} />
    </Box>
  );
};
