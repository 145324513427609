import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';

import { ConfirmationDialog, ModalComponent, TableMenu } from 'components';
import { FILE_CATEGORY, FILE_STATUS } from 'enums';
import { FILE_PERMISSIONS } from 'permissions';
import { DeleteIcon, DownloadFileIcon, ErrorIndicatorIcon } from 'resources';
import { useDeleteFileMutation, useDownloadFileMutation, useGetQuoteBulkUploadResults } from 'services';
import { FileType } from 'types';

import { FileErrorLogModal } from '../FileErrorLogModal/FileErrorLogModal';
import { useStyles } from './FilesTableMenu.css';

type FilesTableMenuProps = {
  file: FileType
};

export const FilesTableMenu = ({ file }: FilesTableMenuProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);
  const [ isErrorLogModalOpen, setIsErrorLogModalOpen ] = useState(false);

  const showErrorLogModalHandler = () => {
    setIsErrorLogModalOpen(true);
    setAnchorEl(null);
  };

  const deleteFileHandler = () => {
    setIsDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const { mutate: downloadUrl } = useDownloadFileMutation(file);

  const { mutate: quoteBulkUploadResults } = useGetQuoteBulkUploadResults(file);

  const { mutate: deleteFile, isLoading: isDeletingFile } = useDeleteFileMutation(
    queryClient,
    {
      onSuccess: () => {
        setIsDeleteDialogOpen(false);
        toast.success('File successfully deleted');
      },
    }
  );

  const handleDeleteFile = () => {
    deleteFile(file.id);
    setIsDeleteDialogOpen(false);
  };

  const menuItems = useMemo(() => [
    {
      permissions: [FILE_PERMISSIONS.RETRIEVE],
      text: 'Show error log',
      icon: ErrorIndicatorIcon,
      onClick: showErrorLogModalHandler,
      className: classes.menuItemError,
      hidden: file.status !== FILE_STATUS.ERROR,
    },
    {
      permissions: [FILE_PERMISSIONS.DOWNLOAD],
      text: 'Download results',
      icon: DownloadFileIcon,
      onClick: () => quoteBulkUploadResults(file.id),
      hidden: file.status !== FILE_STATUS.SUCCESS || file.category !== FILE_CATEGORY.QUOTE,
    },
    {
      permissions: [FILE_PERMISSIONS.DOWNLOAD],
      text: 'Download',
      icon: DownloadFileIcon,
      onClick: () => downloadUrl(file.id),
    },
    {
      permissions: [FILE_PERMISSIONS.DESTROY],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteFileHandler,
    }
  ], [file]);

  return (
    <>
      <Box className={classes.menuIconContainer}>
        {
          file.status === FILE_STATUS.ERROR &&
          <ErrorIndicatorIcon />
        }
        <TableMenu
          anchorEl={anchorEl}
          items={menuItems}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
          onClick={(e) => setAnchorEl(e.currentTarget)} />
      </Box>
      <ModalComponent
        className={classes.errorModal}
        message={`${file.name} - Errors`}
        isOpen={isErrorLogModalOpen}
        onCancel={() => setIsErrorLogModalOpen(false)}
        modalWidth='md'>
        <FileErrorLogModal fileId={file.id} onCancel={() => setIsErrorLogModalOpen(false)}/>
      </ModalComponent>

      <ModalComponent
        message={`Are you sure you want to delete file "${file.name}"?`}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}>
        <ConfirmationDialog
          isActionInProgress={isDeletingFile}
          onSubmit={handleDeleteFile}
          onCancel={() => setIsDeleteDialogOpen(false)}/>
      </ModalComponent>
    </>
  );
};