import * as Yup from 'yup';

export const getShipperPriceRulesValidationSchema = () => {
  const validationSchema = Yup.object({
    shippers: Yup.array()
      .test('shippersLength', null, (shippers) => {
        if (shippers.length <= 1 && shippers[0] === 'all') {
          return new Yup.ValidationError('At least one shipper should be selected', null, 'shippers');
        }
        return true;
      }),
  });

  return validationSchema;
};