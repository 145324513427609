import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';

import { FormModalContent, InputField, LostReasonField } from 'components';
import { LOST_STATUS_LABELS, QUOTE_STATUS } from 'enums';
import { useMarkQuoteMutation } from 'services';
import { getValidationSchema } from './validation';

type MarkQuoteLostModalProps = {
  quoteId: number,
  onCancel: () => void
};

export const MarkQuoteLostModal = ({ quoteId, onCancel }: MarkQuoteLostModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: markAsLost, isLoading: isMarkingQuote } = useMarkQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully marked as Lost');
      }
    }
  );

  const handleSave = ({ reason, winningBid }: {reason: string, winningBid: string }) => {
    const lostReason = LOST_STATUS_LABELS[+reason] || '';
    markAsLost({ id: quoteId, status: QUOTE_STATUS.LOST, reason: lostReason, winning_bid: Number(winningBid) || null});
  };

  return (
    <FormModalContent
      initialValues={{reason: '', winningBid: ''}}
      validationSchema={getValidationSchema}
      validateOnChange={true}
      isActionInProgress={isMarkingQuote}
      enableDivider={false}
      onSubmit={handleSave}
      onCancel={onCancel}>
      <Grid container style={{gap: '1.5rem'}}>
        <Grid xs={12}>
          <LostReasonField />
        </Grid>
        <Grid xs={12}>
          <InputField id='winningBid' label='Winning Bid' type='number' />
        </Grid>
      </Grid>
    </FormModalContent>
  );
};