import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  titleText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    width: '6.25rem'
  },
  valueText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.common.black,
    alignItems: 'left',
  },
}));
