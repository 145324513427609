import * as Yup from 'yup';

import { VALIDATION_REQUIRED_MSG } from 'enums';
import { getUploadAwardValidationSchema } from 'validations';

export const getUploadFileValidationSchema = () => Yup.object({
  file: Yup.mixed().required(VALIDATION_REQUIRED_MSG),
  category: Yup.array().of(Yup.string()).min(1, VALIDATION_REQUIRED_MSG),
});

export const uploadFileWithMetaDataValidation = getUploadFileValidationSchema().concat(getUploadAwardValidationSchema());
