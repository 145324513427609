import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog } from 'components';
import { useDeleteQuoteMutation } from 'services';

type DeleteQuoteModalProps = {
  quoteId: number,
  onCancel: () => void
};

export const DeleteQuoteModal = ({ quoteId, onCancel }: DeleteQuoteModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteQuote, isLoading: isDeletingQuote } = useDeleteQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully deleted');
      },
    }
  );

  return (
    <ConfirmationDialog
      isActionInProgress={isDeletingQuote}
      onSubmit={() => deleteQuote(quoteId)}
      onCancel={onCancel}/>
  );
};