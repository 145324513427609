import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: '3rem',
    color: theme.palette.neutral.neutral700,
  }
}));