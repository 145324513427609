import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    ...theme.typography.bodyRegularS,
    padding: '0.3rem 0rem',
    alignItems: 'center'
  },
  secondaryText: {
    color: theme.palette.neutral.neutral400,
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
  link: {
    color: theme.palette.primary.primary700,
    '&:hover': {
      color: theme.palette.primary.primary500,
      cursor: 'pointer',
    }
  },
  icon: {
    alignItems: 'end',
    verticalAlign: 'middle',
    display: 'grid',
    fontSize: '18px',
  },
  triggered: {
    color: theme.palette.success.main,
  },
  notTriggered: {
    color: theme.palette.neutral.neutral400,
  },
  scrollableContent: {
    maxHeight: '15rem',
    overflowX: 'hidden',
    padding: '0.25rem',
    ...scrollStyle as CSSProperties,
  },
}));