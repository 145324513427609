import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles({
  content: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  importText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 15,
    textAlign: 'center'
  },
  reportTitle: {
    fontWeight: 700,
    marginBottom: 16
  },
  importResultWrapper: {
    marginBottom: 16
  },
  importResultItem: {
    display: 'flex'
  },
  unsuccessfulTextColor: {
    color: 'red'
  }
});
