import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { infoIcon} from 'resources';

import { useStyles } from './InfoBox.css';

interface InfoBoxProps {
    type?: 'info';
    message: string;
}

export const InfoBox = ({ type, message }: InfoBoxProps) => {
  const classes = useStyles();

  return (
    <Typography variant='h5' className={clsx({
      [classes.defaultBox]: true,
      [classes.infoBox]: type === 'info',
    })}>
      <img src={infoIcon}/>
      {message}
    </Typography>
  );
};