import { Func } from '@react-awesome-query-builder/core';

export const RELATIVE_DATE: Func = {
  label: 'Relative',
  returnType: 'date',
  renderBrackets: [ '', '' ],
  renderSeps: [ '', '', '' ],
  jsonLogicImport: (v) => {
    const date = v['date_add'][0];
    const val = Math.abs(v['date_add'][1]);
    const op = v['date_add'][1] >= 0 ? 'plus' : 'minus';
    const dim = v['date_add'][2];
    return [ date, op, val, dim ];
  },
  jsonLogic: ({date, op, val, dim}) => ({
    'date_add': [
      date,
      val * (op === 'minus' ? -1 : +1),
      dim
    ]
  }),
  formatFunc: ({date, op, val, dim}) => (val ? `${date} ${op === 'minus' ? '-' : '+'} ${val} ${dim}` : date),
  args: {
    date: {
      label: 'Date',
      type: 'date',
      valueSources: ['field'],
    },
    op: {
      label: 'Op',
      type: 'select',
      defaultValue: 'plus',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          plus: '+',
          minus: '-',
        },
      }
    },
    val: {
      label: 'Value',
      type: 'number',
      fieldSettings: {
        min: 0,
        validateValue: (value: number) => Number.isInteger(value)
      },
      defaultValue: 0,
      valueSources: ['value'],
    },
    dim: {
      label: 'Dimension',
      type: 'select',
      defaultValue: 'days',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          days: 'days',
          weeks: 'weeks',
          months: 'months',
          years: 'years',
        },
      }
    },
  }
};

export const RELATIVE_DATETIME: Func = {
  label: 'Relative',
  returnType: 'datetime',
  renderBrackets: [ '', '' ],
  renderSeps: [ '', '', '' ],
  jsonLogicImport: (v) => {
    const date = v['date_add'][0];
    const val = Math.abs(v['date_add'][1]);
    const op = v['date_add'][1] >= 0 ? 'plus' : 'minus';
    const dim = v['date_add'][2];
    return [ date, op, val, dim ];
  },
  jsonLogic: ({date, op, val, dim}) => ({
    'date_add': [
      date,
      val * (op === 'minus' ? -1 : +1),
      dim
    ]
  }),
  formatFunc: ({date, op, val, dim}) => (val ? `${date} ${op === 'minus' ? '-' : '+'} ${val} ${dim}` : date),
  args: {
    date: {
      label: 'Date time',
      type: 'datetime',
      valueSources: ['field'],
    },
    op: {
      label: 'Op',
      type: 'select',
      defaultValue: 'plus',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          plus: '+',
          minus: '-',
        },
      }
    },
    val: {
      label: 'Value',
      type: 'number',
      fieldSettings: {
        min: 0,
        validateValue: (value: number) => Number.isInteger(value)
      },
      defaultValue: 0,
      valueSources: ['value'],
    },
    dim: {
      label: 'Dimension',
      type: 'select',
      defaultValue: 'hours',
      valueSources: ['value'],
      mainWidgetProps: {
        customProps: {
          showSearch: false
        }
      },
      fieldSettings: {
        listValues: {
          minutes: 'minutes',
          hours: 'hours',
          days: 'days',
          weeks: 'weeks',
          months: 'months',
          years: 'years',
        },
      }
    },
  }
};