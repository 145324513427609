import React from 'react';
import { Divider } from '@material-ui/core';

import { CURRENCY_TYPE } from 'enums';
import { GSPredictionRate } from 'types';

import { InfoLine } from './components/InfoLine';

import { useStyles } from './QuoteDetailsPage.css';

type PredictionRateDetailsProps = {
  predictionRate: GSPredictionRate,
  numberOfStops: number,
  distance: number,
  currency: string
};

export const PredictionRateDetails = ({ predictionRate, numberOfStops, distance, currency } : PredictionRateDetailsProps) => {
  const classes = useStyles();

  const {
    prediction_confidence_level_str,
    prediction_confidence_level,
    prediction_fuel_rate,
    prediction_high_buy_rate,
    prediction_low_buy_rate,
    prediction_target_buy_rate,
  } = predictionRate || {};

  const formatPrice = (price: number) => {
    return price ? `${ currency===CURRENCY_TYPE.CAD ? 'C$' : '$'}${price.toFixed(2)}` : 'N/A';
  };

  const calculateMultiStopPredictions = (value: number) => {
    const intermediateStopCost = numberOfStops > 2 ? (numberOfStops - 2) * 150 : 0;
    return value ? formatPrice((value * distance) + intermediateStopCost) : null;
  };

  return (
    <>
      <InfoLine text='Target Buy Predicted' value={calculateMultiStopPredictions(prediction_target_buy_rate)} />
      <Divider className={classes.cardContentDivider}/>
      <InfoLine text='Confidence Level' value={prediction_confidence_level_str && prediction_confidence_level ? `${prediction_confidence_level_str} ( ${ prediction_confidence_level } % )` : 'N/A'}/>
      <Divider className={classes.cardContentDivider}/>
      <InfoLine text='Low Buy Predicted' value={calculateMultiStopPredictions(prediction_low_buy_rate)} />
      <Divider className={classes.cardContentDivider}/>
      <InfoLine text='High Buy Predicted' value={calculateMultiStopPredictions(prediction_high_buy_rate)} />
      <Divider className={classes.cardContentDivider}/>
      <InfoLine text='Fuel Cost' value={formatPrice(prediction_fuel_rate * distance)} />
    </>
  );
};