import axios, { AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { API_URL } from '../config';
import { downloadFile } from 'helpers';
import { FileType, FileDetail, PaginatedQueryResult, Opts } from 'types';

const FILE_QUERY_KEY = 'files';
const FILE_API_URL = '/v1/file';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';
const FILE_DOWNLOAD_FAILED_MESSAGE = 'File download failed. Please try again.';

export const api = {
  files: {
    list: (size: number, page: number, filters: string) => axios.get(`${API_URL}${FILE_API_URL}/?limit=${size}&offset=${page}&${filters}`),
    get: (id: number) => axios.get(`${API_URL}${FILE_API_URL}/${id}/`),
    delete: (id: number) => axios.delete(`${API_URL}${FILE_API_URL}/${id}/`),
    import: (formData: FormData) => axios.post(
      `${API_URL}${FILE_API_URL}/import_file/`,
      formData,
      {
        headers:
          {
            'Content-Type': 'multipart/form-data'
          }
      }
    ),
    getDownloadUrl: (id: number) => axios.get(`${API_URL}${FILE_API_URL}/${id}/download_url/`),
    getQuoteBulkUploadResults: (id: number) => axios.get(`${API_URL}${FILE_API_URL}/${id}/bulk-upload-results/`),
  }
};

export const useGetFilesQuery = (size: number, page: number, filters: string) => {
  return useQuery<PaginatedQueryResult<FileType>>([ FILE_QUERY_KEY, { size, page, filters }],
                                                  () => api.files.list(size, page, filters),
                                                  { enabled: !!filters, keepPreviousData: true }
  );
};

export const useGetFileQuery = (fileId: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<FileDetail>>([ FILE_QUERY_KEY, fileId ], () => api.files.get(fileId), { ...queryOptions, enabled: !!fileId, });
};

export const useImportFileMutation = (opts: Opts, queryClient: QueryClient = null) => (useMutation(api.files.import, {
  onSuccess: () => {
    opts.onSuccess();
    queryClient && queryClient.invalidateQueries([FILE_QUERY_KEY]);
  },
  onError: () => {
    if (opts.onError) {
      opts.onError();
    } else {
      toast.error(UNKNOWN_ERROR_MESSAGE);
    }
  }
}));

export const useDeleteFileMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.files.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(FILE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    },
  });

export const useDownloadFileMutation = (file: FileType) =>
  useMutation(api.files.getDownloadUrl, {
    onSuccess: (responseURL) => {
      axios.create().get(responseURL.data, {
        responseType: 'blob',
      })
        .then(response => {
          try {
            downloadFile(response, file);
          } catch (error) {
            toast.error(FILE_DOWNLOAD_FAILED_MESSAGE);
          }
        })
        .catch(() => {
          toast.error(UNKNOWN_ERROR_MESSAGE);
        });
    },
    onError: () => {
      toast.error(UNKNOWN_ERROR_MESSAGE);
    }
  });

export const useGetQuoteBulkUploadResults = (file: FileType) =>
  useMutation(api.files.getQuoteBulkUploadResults, {
    onSuccess: (response) => {
      try {
        downloadFile(response, file);
      } catch (error) {
        toast.error(FILE_DOWNLOAD_FAILED_MESSAGE);
      }
    },
    onError: () => {
      toast.error(UNKNOWN_ERROR_MESSAGE);
    }
  });