import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { Layout, NavigationButton } from 'components';
import { PAGE_TYPE } from 'enums';
import { Params } from 'types';
import { AwardLaneUpsertForm } from './components/AwardLaneUpsertForm';

import { useStyles } from './CreateUpdateAwardLanePage.css';

type CreateUpdateAwardLanePageProps = {
  type: string,
};

export const CreateUpdateAwardLanePage = ({ type }: CreateUpdateAwardLanePageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { awardId } = useParams<Params>();

  const backUrl = `/awards/${awardId}/lanes`;

  const getPageTitle = () => {
    let title = '';
    switch (type) {
      case PAGE_TYPE.CREATE:
        title = 'Add New Award Lane';
        break;
      case PAGE_TYPE.EDIT:
        title = 'Edit Award Lane';
        break;
    }
    return title;
  };

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo={backUrl}/>}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {getPageTitle()}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AwardLaneUpsertForm onCancel={() => history.push(backUrl)}/>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};