import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import SearchBar from 'material-ui-search-bar';

import { SearchIcon, XMark } from 'resources';

import { useStyles } from './NSearch.css';

type SearchComponentProps = {
  value: string,
  handleChange: (newText: string) => void,
  placeholder?: string
};

export const NSearch = ({ value, handleChange, placeholder = 'Search'}: SearchComponentProps) => {
  const classes = useStyles();

  const debouncedChange = useCallback(
    debounce((val) => handleChange(val), 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, []);

  return (
    <SearchBar
      classes={{
        searchIconButton: classes.searchIconButton,
        iconButtonHidden: classes.iconButtonHidden,
        searchContainer: classes.searchContainer,
      }}
      value={value}
      onChange={debouncedChange}
      onCancelSearch={() => handleChange('')}
      placeholder={placeholder}
      closeIcon={<XMark className={classes.closeIconButton} />}
      searchIcon={<SearchIcon />}
      className={classes.searchBar} />
  );
};

