import { BinaryOperator, DateTimeFieldSettings, FieldOrGroup, Fields, Funcs, Types, Widgets } from '@react-awesome-query-builder/core';
import { toast } from 'react-toastify';
import { MuiConfig, Operators, ListItem } from '@react-awesome-query-builder/mui';
import {
  BooleanFieldSettings,
  ImmutableTree,
  JsonGroup,
  JsonItem,
  JsonRule,
  MultiSelectFieldSettings,
  NumberFieldSettings,
  RuleProperties,
  TextFieldSettings,
  Utils as QbUtils,
} from '@react-awesome-query-builder/ui';

import { parseISO, isValid} from 'date-fns';
import { utcToZonedTime} from 'date-fns-tz';
import { merge, cloneDeepWith } from 'lodash';
import {v4 as uuidv4, validate as validateUuidv4 } from 'uuid';

import {TIMEZONES, TIMEZONE_NAMES} from 'enums';
import { rule_engine_items_api } from 'services';
import { Action, ActionType, AsyncFetchConfig, ExpressionEditorConfig, OperandConfig, Option, PaginatedQueryResult, Rule, Ruleset } from 'types';
import { RELATIVE_DATE, RELATIVE_DATETIME, RELATIVE_NUMBER } from 'rule-engine/custom-functions';

const getLocalToZonedTime = (customSettings: Record<string, unknown> = null): Date => {
  const currentDate = new Date();
  if (!customSettings || !('timezone' in customSettings) || customSettings['timezone'] === TIMEZONES.UTC) {
    return currentDate;
  }
  const timezone = TIMEZONE_NAMES[customSettings['timezone'] as string];
  // convert the current datetime to show current data for specific time zone (offseted value)
  // NOTE: value still has local TimezoneOffset set
  return utcToZonedTime(currentDate, timezone);
};

const fetchPaginatedData = async (operand: OperandConfig, input: string, offset: number): Promise<{ values: ListItem[], hasMore: boolean }> => {
  try {
    const config = operand.config?.customSettings?.asyncFetch as AsyncFetchConfig;

    const response = await rule_engine_items_api.items.list(
      config.url,
      `search=${input}`,
      config.limit,
      offset,
      config.ordering
    ) as PaginatedQueryResult<unknown>;

    const data = response.data; // Assuming the results are nested under "results"
    const results = data.results;

    /**
     * Retrieves a value from an item either by a specified field or by executing a function.
     *
     * This function determines how to extract the value (e.g., 'value' or 'title') from an object.
     * It checks if a field name or a function string is provided in the configuration and returns
     * the corresponding function to retrieve the value. If neither the field nor function is provided,
     * it throws an error indicating that both are undefined.
     *
     * @param {string | undefined} field - The field name to look for in the item object.
     *                                     If defined, the function will return the item's value at that field.
     * @param {string | undefined} func - A function string that can be dynamically executed to compute the value.
     *                                    If defined and field is not provided, this function is executed.
     * @param {string} type - Describes whether this is for 'value' or 'title' (used in error messages to provide clarity).
     *
     * @returns {Function} A function that takes an item as input and returns the value derived from the field or function.
     *                     If both field and function are missing, it throws an error.
     *
     * @throws {Error} Throws an error if neither the field nor function is defined in the configuration.
     */
    const getFieldOrFunction = (type: string, field?: string, func?: string) => {
      if (field) {
        return (item: Record<string, unknown>) => item[field];
      } else if (func) {
        return new Function('item', `return ${func}`);
      } else {
        throw new Error(`Both config.${type}_field and config.${type}Func are undefined. Cannot determine how to get the ${type}.`);
      }
    };

    const valueFunc = getFieldOrFunction('value', config.value_field, config.valueFunc);
    const titleFunc = getFieldOrFunction('title', config.title_field, config.titleFunc);

    const values = results.map((item: unknown) => ({
      value: valueFunc(item),
      title: titleFunc(item),
    }));

    const hasMore = !!data.next; // Check if there are more records to load

    return { values, hasMore };
  } catch (error) {
    toast.error(`Multiselect field error for operand ${operand.name}`);
    toast.error(error.message);
    return { values: [], hasMore: false };
  }
};

const getSelectField = (operand: OperandConfig, fieldBase: Partial<FieldOrGroup>): FieldOrGroup => {

  const config = operand.config?.customSettings?.asyncFetch as AsyncFetchConfig;

  // Handle synchronous choices
  const choices: ListItem[] = operand.choices ?
    Object.entries(operand.choices).map(acc => {
      return { value: acc[1], title: acc[0] };
    }, {}).sort((a, b) => typeof a.value === 'string' ? a.value.localeCompare(b.title) : Number(a) - Number(b))
    : [];

  const fieldSettings = operand.config?.fieldSettings as MultiSelectFieldSettings;

  const selectField = {
    ...fieldBase,
    label: operand.display_name,
    type: 'select',
    operators: [
      'select_equals', // select-specific operator: "equals"
      'select_not_equals', // select-specific operator: "not equals"
      'is_null',
      'is_not_null',
      'is_empty', // text-specific operator: "is empty"
      'is_not_empty', // text-specific operator: "is not empty",
      'select_any_in', // select-specific operator: "any in"
      'select_not_any_in', // select-specific operator: "not any in"
      'custom_starts_with', // text-specific custom operator: " starts with",
      'custom_not_starts_with', // text-specific custom operator: " starts with",
    ],
    listValues: choices,
    fieldSettings: {
      showSearch: fieldSettings?.showSearch ?? true,
    } as MultiSelectFieldSettings
  };

  // Handle asynchronous fetching if customSettings defines it
  if (config) {
    selectField.fieldSettings = {
      ...selectField.fieldSettings,
      useAsyncSearch: fieldSettings?.useAsyncSearch ?? true,
      useLoadMore: fieldSettings?.useLoadMore ?? true,
      forceAsyncSearch: fieldSettings?.forceAsyncSearch ?? false,
      allowCustomValues: fieldSettings?.forceAsyncSearch ?? true,
      asyncFetch: async (input: string, offset: number) => {
        if (!input || (config.trigger_search_after && input.length < config.trigger_search_after)) {
          return { values: [], hasMore: false };
        }
        return await fetchPaginatedData(operand, input, offset);
      },
    };
  }

  return selectField;
};

const getField = (operand: OperandConfig): FieldOrGroup => {

  const customSettings = operand.config?.customSettings;
  let fieldSettings;

  const fieldBase: Partial<FieldOrGroup> = {
    valueSources: [ 'value', 'field', 'func' ],
  };

  if (operand.choices || customSettings?.asyncFetch) {
    return getSelectField(operand, fieldBase);
  }

  switch (operand.type) {
    case 'number':
      fieldSettings = operand.config?.fieldSettings as NumberFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'number',
        fieldSettings: {
          min: fieldSettings?.min ?? 0,
          max: fieldSettings?.max ?? null,
        },
        preferWidgets: ['number'],
        hideForSelect: customSettings?.hideForSelect as boolean ?? false,
        hideForCompare: customSettings?.hideForCompare as boolean ?? false,
      };

    case 'string':
      fieldSettings = operand.config?.fieldSettings as TextFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'text',
        operators: [
          'equal',
          'not_equal',
          'like',
          'not_like',
          'is_empty',
          'is_not_empty',
          'is_null',
          'is_not_null',
          'custom_starts_with',
          'custom_not_starts_with',
        ],
      };
    case 'boolean':
      fieldSettings = operand.config?.fieldSettings as BooleanFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'boolean',
        defaultValue: customSettings?.defaultValue ?? true,
        mainWidgetProps: {
          labelYes: fieldSettings?.labelYes ?? 'Yes',
          labelNo: fieldSettings?.labelNo ?? 'No'
        }
      };
    case 'date':
      fieldSettings = operand.config?.fieldSettings as DateTimeFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'date',
        defaultValue: new Date(),
      };
    case 'time':
      fieldSettings = operand.config?.fieldSettings as DateTimeFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'time',
        defaultValue: getLocalToZonedTime(customSettings),
      };
    case 'datetime':
      fieldSettings = operand.config?.fieldSettings as DateTimeFieldSettings;
      return {
        ...fieldBase,
        label: operand.display_name,
        type: 'datetime',
        defaultValue: getLocalToZonedTime(customSettings),
      };
    default:
      throw new TypeError(`Operand type ${operand.type} not supported`);
  }
};

export const generatePreconditionConfigOperators = () : Operators => {
  return {
    ...MuiConfig.operators,
    // overriding
    'custom_starts_with': customStartsWith,
    'custom_not_starts_with': customNotStartsWith,
  };
};

//for future reference, an example how we can set the initial value of new query
export const generateInitialQuery = (): ImmutableTree => {

  const initValue = {
    'type': 'group',
    'id': QbUtils.uuid(),
    'children1':
    {
      [QbUtils.uuid()]: {
        'type': 'rule',
        'properties': {
          'field': 'base_predicted_linehaul_price',
          'operator': 'equal',
          'value': [0],
          'valueSrc': ['value']
        } as RuleProperties
      } as JsonRule
    } as {[id: string]: JsonItem} | JsonItem[]
  } as JsonGroup;

  return QbUtils.loadTree(initValue);
};

export const generatePreconditionConfigTypes = () : Types => {
  const configTypes = {
    ...MuiConfig.types,
    // overriding
    text: {
      ...MuiConfig.types.text,
      excludeOperators: ['proximity'],
      widgets: {
        ...MuiConfig.types.text.widgets,
        'text': {
          ...MuiConfig.types.text.widgets.text,
          operators: [ 'equal', 'not_equal', 'like', 'not_like', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null', 'custom_starts_with', 'custom_not_starts_with' ],
        },
      }
    },
    select: {
      ...MuiConfig.types.select,
      widgets: {
        ...MuiConfig.types.select.widgets,
        multiselect: {
          operators: [ 'select_any_in', 'select_not_any_in', 'is_null', 'is_not_null', 'is_empty', 'is_not_empty' ], // Custom operators for multiselect widgets
        },
        select: {
          operators: [ 'select_equals', 'select_not_equals', 'is_null', 'is_not_null', 'is_empty', 'is_not_empty', 'custom_starts_with', 'custom_not_starts_with' ], // Custom operators for select widgets
        },
      },
    },
    boolean: merge({}, MuiConfig.types.boolean, {
      widgets: {
        boolean: {
          widgetProps: {
            hideOperator: true,
            operatorInlineLabel: 'is'
          },
          opProps: {
            equal: {
              label: 'is'
            },
            not_equal: {
              label: 'is not'
            }
          }
        },
      },
    }),
  };
  return configTypes;
};

export const generatePreconditionConfigWidgets = () : Widgets => {
  return {
    ...MuiConfig.widgets,
    // overriding
    text: {
      ...MuiConfig.widgets.text
    },
    date: {
      ...MuiConfig.widgets.date,
      dateFormat: 'YYYY-MM-DD',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      //workaround for the converting local date to date UTC
      //conversion will not be affected by the local time zone difference
      jsonLogic: (val): Date => {
        const dt = new Date(val);
        dt.setHours(12);
        return dt;
      },
    },
    time: {
      ...MuiConfig.widgets.time,
      timeFormat: 'HH:mm',
      valueFormat: 'HH:mm:ss',
    },
    datetime: {
      ...MuiConfig.widgets.datetime,
      timeFormat: 'HH:mm',
      dateFormat: 'YYYY-MM-DD',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      validateValue: (val: Date | number | string) => {
        let parsedDate: Date;
        if (typeof val === 'number') {
          // If val is a number, treat it as a Unix timestamp
          parsedDate = new Date(val * 1000); // Convert Unix timestamp to milliseconds
        } else if (typeof val === 'string') {
          // If val is a string, parse it as a date string
          parsedDate = parseISO(val);
        } else {
          // If val is a Date object, use it directly
          parsedDate = val;
        }
        return isValid(parsedDate);
      },
    },
  };
};

export const generatePreconditionConfigFields = (rule_editor_config: ExpressionEditorConfig): Fields => {
  const operands: OperandConfig[] = rule_editor_config.operands.sort((a, b) => a.display_name.localeCompare(b.display_name));
  const fields : Fields = {};
  operands.forEach(element => {
    if (element.is_precondition_operand) {
      fields[element.name] = getField(element);
    }
  });
  return fields;
};

export const generateActionOptions = (rule_editor_config: ExpressionEditorConfig) : Option[] => {
  const actionOptions = rule_editor_config.actions.map(action => {
    return { id: action.name, label: action.display_name };
  }).sort((a, b) => a.label.localeCompare(b.label));
  return actionOptions;
};

export const checkActionType = (config: ExpressionEditorConfig, action: Action, type: ActionType): boolean => {
  const registeredAction = config.actions.find(a => a.name === action.name && a.action_type === type);
  return !!registeredAction;
};

// Custom operators definition. Follow this example in case we need additional custom operators.
// Make sure to implement custom JSON logic in `backend/rule_engine/json_logic.py` file.
const customStartsWith: BinaryOperator = {
  isNotOp: false,
  label: 'Starts with',
  reversedOp: 'custom_not_starts_with',
  labelForFormat: 'STARTS WITH',
  cardinality: 1,
  formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
  jsonLogic: 'custom_starts_with',
  valueSources: [ 'value', 'field', 'func' ],
};

const customNotStartsWith: BinaryOperator = {
  isNotOp: true,
  label: 'Not starts with',
  reversedOp: 'custom_starts_with',
  labelForFormat: 'NOT STARTS WITH',
  cardinality: 1,
  formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
  jsonLogic: 'custom_not_starts_with',
  valueSources: [ 'value', 'field', 'func' ],
};

export const generateCustomFunctions = () : Funcs => {
  return {
    relative_date: RELATIVE_DATE,
    relative_datetime: RELATIVE_DATETIME,
    relative_number: RELATIVE_NUMBER
  };
};

const deepCopyWithUUID = <T>(obj: T): T => {
  const copiedObj = cloneDeepWith(obj, (value, key) => {
    // NOTE: This could be potentially dangerous in the future!
    if (key === 'id' && validateUuidv4(value)) {
      return uuidv4();
    }
  });
  return copiedObj;
};

export const getDuplicatedRule = (rule: Rule, ruleset_ctx: Ruleset): Rule => {
  // First we deep copy the Rule object and generate new IDs:
  const duplicate = deepCopyWithUUID(rule);

  // Next we figure out the priority - the duplicated rule should be moved to the last position:
  const priority = Math.max(...ruleset_ctx.map(value => value.priority)) + 1;

  // Finally, Rule name must be unique, otherwise the update will fail:
  let name = duplicate.name + ' - copy';
  const existingRuleNames = ruleset_ctx.map(r => r.name);
  if (existingRuleNames.includes(name)) {
    const regex = new RegExp(`^${name} \\((\\d*)\\)$`);
    const existingCopyNumbers: number[] = existingRuleNames.reduce((acc, value) => {
      // e.g.: "some_rule - copy (5)" will match to ["some_rule - copy (5)", "5"]
      const match = value.match(regex);
      if (match) {
        acc.push(Number(match[1]));
      }
      return acc;
    }, [0]);
    const copyNumber = Math.max(...existingCopyNumbers) + 1;
    name = `${name} (${copyNumber})`;
  }

  return { ...duplicate, priority, name };
};
