import React from 'react';
import { Chip, FormControl, FormHelperText, Grid, Typography } from '@material-ui/core';
import { clsx } from 'clsx';
import { FormikErrors } from 'formik';

import { ActionButton } from 'components';
import { isObject } from 'helpers';
import { UploadIcon, XMark } from 'resources';

import { useStyle } from './UploadFile.css';

type UploadFileProps = {
  id: string,
  accept: string,
  className: string,
  errors: FormikErrors<unknown>,
  inputRef: React.MutableRefObject<HTMLInputElement>,
  title: string,
  values: unknown,
  setFieldValue: (field: string, value: File, shouldValidate?: boolean) => void;
}

export const UploadFile = ({ id, accept, className, errors, inputRef, title, values, setFieldValue }: UploadFileProps) => {
  const classes = useStyle();

  return (
    <>
      <FormControl
        className={clsx(classes.muiFormControl, className)}>
        <Typography className={classes.uploadTitle}>{title}</Typography>
        <input
          ref={inputRef}
          type='file'
          accept={accept}
          id={id}
          onChange={(e) => {
            setFieldValue(id, e.target.files[0]);
            e.target.value = null;
          }}
          style={{ display: 'none' }} />
        <ActionButton
          text='Upload'
          variant='default'
          startIcon={<UploadIcon />}
          handleClick={() => inputRef.current.click()} />
        {isObject(values) && values[id] &&
          <Grid className={classes.uploadedFileChipContainer}>
            <Typography className={classes.uploadedFileName}>{(values.file as File).name}</Typography>
            <Chip deleteIcon={<XMark />} onDelete={() => setFieldValue(id, null)} />
          </Grid>}
      </FormControl>
      <FormHelperText className={classes.errorMsg}>{(errors as Record<string, string>)[id] || ' '}</FormHelperText>
    </>
  );
};