import { isAfter, isBefore } from 'date-fns';
import * as Yup from 'yup';

import { FILE_CATEGORY, VALIDATION_REQUIRED_MSG } from 'enums';
import { isDifferentFileCategory } from 'helpers';

// If selected category in Upload File modal is FILE_CATEGORY.AWARD the fields should be mandatory
export const getUploadAwardValidationSchema = (awardId?: number) => Yup.object({
  file: Yup.mixed()
    .when('category', {
      is: (category: Array<string>) => awardId || isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema.required(VALIDATION_REQUIRED_MSG),
    }),
  customer: Yup.mixed()
    .when('category', {
      is: (category: Array<string>) => awardId || isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema.required(VALIDATION_REQUIRED_MSG),
    }),
  name: Yup.string()
    .when('category', {
      is: (category: Array<string>) => isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema.required(VALIDATION_REQUIRED_MSG),
    }),
  region: Yup.string()
    .when('category', {
      is: (category: Array<string>) => isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema.required(VALIDATION_REQUIRED_MSG),
    }),
  effective_date: Yup.date()
    .when('category', {
      is: (category: Array<string>) => isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema
        .typeError('Please enter a valid date')
        .max(Yup.ref('expiration_date'), 'Effective Date cannot be after Expiration date').required(VALIDATION_REQUIRED_MSG)
        .test(
          'same_dates_test',
          'Effective date must be before expiration date',
          function (value) {
            const { expiration_date } = this.parent;
            return isBefore(value, expiration_date);
          }
        ),
    }),
  expiration_date: Yup.date()
    .when('category', {
      is: (category: Array<string>) => isDifferentFileCategory(FILE_CATEGORY.AWARD, category),
      then: schema => schema.nullable(),
      otherwise: schema => schema
        .typeError('Please enter a valid date')
        .min(Yup.ref('effective_date'), 'Expiration Date cannot be before Effective Date').required(VALIDATION_REQUIRED_MSG)
        .test(
          'same_dates_test',
          'Expiration date must be after effective date',
          function (value) {
            const { effective_date } = this.parent;
            return isAfter(value, effective_date);
          }
        ),
    })
});
