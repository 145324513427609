import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import { getIntermodalRateTitle } from 'helpers';
import { SoftmodalResponse } from 'types';

import { SoftmodalRateContent } from './SoftmodalRateContent';

import { useStyles } from '../../QuoteDetailsPage.css';

type IntermodalRateDetailsProps = {
  data: SoftmodalResponse,
  currency: string,
};

export const IntermodalRateDetails = ({ data, currency } : IntermodalRateDetailsProps) => {
  const classes = useStyles();

  const {
    error,
    rates,
    quote_id,
  } = data || {};

  return (
    <Card className={classes.cardBorder}>
      <CardContent style={{paddingBottom: '0.35rem'}}>
        <Typography variant='h4'>
          {getIntermodalRateTitle(quote_id)}
        </Typography>
      </CardContent>
      <SoftmodalRateContent rates={rates} error={error} currency={currency} />
    </Card>
  );
};