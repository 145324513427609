import { FormControl, Grid } from '@material-ui/core';
import React, { SetStateAction } from 'react';

import { AutocompleteFilter, DatePickerField, InputField, SelectField } from 'components';
import { REGIONS } from 'enums';
import { Option, UploadAward } from 'types';

import { FormikErrors } from 'formik';
import { useStyles } from './UploadAwardMetaData.css';

type UploadAwardMetaDataProps = {
  awardId?: number,
  customers: Option[],
  direction: 'column' | 'row',
  errors: FormikErrors<UploadAward>,
  isLoadingCustomer: boolean,
  values: UploadAward,
  setFieldValue: (field: string, value: SetStateAction<string | number | (string | number)[]>) => void,
}

export const UploadAwardMetaData = ({ awardId, customers, direction, errors, isLoadingCustomer, values, setFieldValue }: UploadAwardMetaDataProps) => {
  const classes = useStyles();

  const getSheetNameInput = (hasError: boolean) => (
    <FormControl error={hasError}>
      <InputField
        id='name'
        label='Sheet name'
        type='text'
        placeholder='Enter sheet name'
        reserveErrorMsgSpace/>
    </FormControl>
  );

  return (
    <>
      {!awardId &&
        <Grid item direction={direction} className={direction === 'row' ? classes.rowContaier : classes.columnContaier}>
          <Grid item direction='column' xs={12} sm={12} md={12} lg={12}>
            {getSheetNameInput(!!errors['name'])}
          </Grid>
        </Grid>}
      <Grid item direction={direction} className={direction === 'row' ? classes.rowContaier : classes.columnContaier}>
        <Grid direction='column' xs={12} sm={12} md={direction === 'row' ? 6 : 12} lg={direction === 'row' ? 6 : 12}>
          {awardId ?
            <Grid>
              {getSheetNameInput(!!errors['name'])}
            </Grid> :
            <FormControl error={!!errors['customer']}>
              <AutocompleteFilter
                label='Shipper'
                placeholder='Choose or enter shipper partner'
                defaultValue={values.customer}
                error={errors?.customer as string}
                options={customers}
                loading={isLoadingCustomer}
                onChange={value => setFieldValue('customer', value)}
                reserveErrorMsgSpace />
            </FormControl>}
        </Grid>
        <Grid direction='column' xs={12} sm={12} md={direction === 'row' ? 6 : 12} lg={direction === 'row' ? 6 : 12}>
          <FormControl error={!!errors['region']}>
            <SelectField
              id='region'
              label='Fuel Sheet Region'
              options={REGIONS}
              defaultValue={values.region}
              placeholder='Choose fuel sheet region' />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item direction={direction} className={direction === 'row' ? classes.rowContaier : classes.columnContaier}>
        <Grid direction='column' xs={12} sm={12} md={direction === 'row' ? 6 : 12} lg={direction === 'row' ? 6 : 12}>
          <FormControl error={!!errors['effective_date']}>
            <DatePickerField id='effective_date' label='Effective Date' disablePast={false} maxDate={values?.expiration_date || undefined}/>
          </FormControl>
        </Grid>
        <Grid direction='column' xs={12} sm={12} md={direction === 'row' ? 6 : 12} lg={direction === 'row' ? 6 : 12}>
          <FormControl error={!!errors['expiration_date']}>
            <DatePickerField id='expiration_date' label='Expiration Date' disablePast={false} minDate={values?.effective_date || undefined}/>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
