import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { ActionButton, TableComponent } from 'components';
import { useGetFileQuery } from 'services';

import { FilesErrorsTableColumns } from '../../FilesTableConfig';
import { useStyles } from './FileErrorLogModal.css';

type FileErrorLogModalProps = {
    fileId: number,
    onCancel: () => void
  };

export const FileErrorLogModal = ({ fileId, onCancel } : FileErrorLogModalProps) => {
  const classes = useStyles();

  const { isLoading, data, isSuccess, isError } = useGetFileQuery(fileId);
  const { data: fileDetail } = data || {};

  return (
    <>
      { isLoading ?
        <Box className={classes.loadingSpinnerBox}>
          <CircularProgress size={75} />
        </Box> :
        <TableComponent
          columns={FilesErrorsTableColumns}
          isLoading={isLoading}
          isSuccess={isSuccess}
          areRowsSelectable={false}
          isError={isError}
          isTablePaginated={false}
          tableData={fileDetail?.errors}/>}
      <Box className={classes.buttonContainer}>
        <ActionButton
          variant='secondary'
          text='Cancel'
          handleClick={onCancel}/>
      </Box>
    </>
  );
};
