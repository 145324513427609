import axios, { AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { API_URL } from '../config';

import { GlobalRule, Opts, Ruleset } from 'types';

const GLOBAL_RULE_API_URL = '/v1/global-rule';
const GLOBAL_RULE_QUERY_KEY = 'global-rule';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  globalRules: {
    getGlobalRuleset: (id: number) => axios.get(`${API_URL}${GLOBAL_RULE_API_URL}/${id}`),
    updateRuleset: ({ id, ruleset}: {id: number, ruleset: Ruleset}) =>
      axios.patch(`${API_URL}${GLOBAL_RULE_API_URL}/${id}/`, { ruleset }),
  }
};

export const useGetGlobalRulesetQuery = (id: number) => {
  return useQuery<AxiosResponse<GlobalRule>>([GLOBAL_RULE_QUERY_KEY], () => api.globalRules.getGlobalRuleset(id));
};

export const useUpdateRuleset = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.globalRules.updateRuleset, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(GLOBAL_RULE_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });