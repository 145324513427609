import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../QuoteDetailsPage.css';

type InfoLineProps = {
  text: string,
  value?: string,
  asHTML?: boolean,
  className?: string,
};

export const InfoLine = ({ text, value, asHTML = false, className } : InfoLineProps) => {
  const classes = useStyles();

  return (
    <CardContent
      className={clsx({
        [classes.infoLine]: true,
        [className]: className,
      })}>
      <Grid container>
        <Grid item xs={4}>
          <Typography className={classes.secondaryText}>{text}</Typography>
        </Grid>
        <Grid item xs={4}>
          {
            asHTML && (<Typography className={classes.predictionInfoValue} dangerouslySetInnerHTML={{__html: value}}></Typography>)
          }
          {
            !asHTML && (<Typography className={classes.predictionInfoValue}>{value || 'N/A'}</Typography>)
          }
        </Grid>
      </Grid>
    </CardContent>
  );
};
