export enum ACTIVITY_TYPES {
  CREATE_QUOTE,
  UPDATE_PRICE,
  MARK_QUOTE_WON,
  MARK_QUOTE_LOST,
  MARK_QUOTE_RESPONDED,
  MARK_QUOTE_REJECTED,
  RESPOND_BY_EMAIL,
  RESPOND_BY_API,
  REJECT_BY_EMAIL,
  REJECT_BY_API,
  UPDATE_QUOTE,
  DUPLICATE_QUOTE
}
