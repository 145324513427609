import React from 'react';
import { Stack, Typography } from '@mui/material';

import { SHIPPER_COST_TYPE_LABELS } from 'enums';
import { Column, Customer } from 'types';

import { useStyles } from './CustomerTableConfig.css';

export const CustomerTableConfig = () => {

  const classes = useStyles();

  const customerTableColumns: Column[] = [
    { id: 'customer_name', label: 'Name', sortable: true, orderingField: 'customer_name'},
    { id: 'customer_slug', label: 'Code', sortable: true, orderingField: 'customer_slug'},
    { id: 'contact_name', label: 'Contact Name', sortable: true, orderingField: 'contact_name'},
    { id: 'contact_email', label: 'Email', sortable: true, orderingField: 'contact_email'},
    { id: 'contact_phone', label: 'Phone', sortable: true, orderingField: 'contact_phone'},
    { id: 'profit_center', label: 'Profit Center', sortable: true, orderingField: 'profit_center'},
    { id: 'nfi_pricing_contact_name', label: 'NFI Pricing Contact', sortable: true, orderingField: 'nfi_pricing_contact_name'},
    { id: 'tms', label: 'TMS', sortable: true, orderingField: 'tms'},
    { id: 'cost_type', label: 'Cost Type', sortable: false, customRender: (customer: Customer) => SHIPPER_COST_TYPE_LABELS[customer.cost_type] },
    { id: 'auto_response', label: 'Auto Response', sortable: true, orderingField: 'auto_response', customRender: (customer: Customer) => (
      <Stack className={classes.auto_response}>{customer.auto_response ? <Typography className={classes.enabled_auto_response}>Enabled</Typography> : <Typography>Disabled</Typography>}</Stack>)}
  ];

  return customerTableColumns;
};
