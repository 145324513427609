import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import firebase from 'firebase/app';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { App } from 'components';
import { AuthProvider } from 'contexts';
import { themeDefault } from './themeDefault';

import './index.scss';

//axios interceptor
axios.interceptors.request.use(async (config) => {
  const accessToken = await firebase.auth().currentUser?.getIdToken();
  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken;
  }

  return config;
}, error => Promise.reject(error));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const app = (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ThemeProvider theme={createMuiTheme(themeDefault)}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </AuthProvider>
  </QueryClientProvider>
);

ReactDOM.render(app, document.getElementById('root'));
