import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@mui/material';

import { AccessControl } from 'components/Auth/AccessControl';
import { useAuth } from 'contexts/index';
import { USER_PERMISSIONS } from 'permissions';
import { avatarIcon, logoutIcon, myProfileIcon } from 'resources';

import { useStyles } from './ProfileMenu.css';

type ProfileMenuProps = {
  quote?: number
};

export const ProfileMenu = ({ }: ProfileMenuProps) => {
  const classes = useStyles();

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;
  const { currentUser, logout } = useAuth();

  return (
    <>
      <img
        src={avatarIcon}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={classes.icon} />
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.menu}>
        <Grid className={classes.menuHeader}>
          <MenuItem>
            <Tooltip title={currentUser?.display_name}>
              <p>{currentUser.display_name}</p>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip title={currentUser?.email}>
              <p>{currentUser.email}</p>
            </Tooltip>
          </MenuItem>
        </Grid>
        <Grid className={classes.menuOptions}>
          <AccessControl permissions={[USER_PERMISSIONS.RETRIEVE]}>
            <MenuItem>
              <img src={myProfileIcon} className={classes.icon} /> My Profile
            </MenuItem>
          </AccessControl>
          <MenuItem
            onClick={logout}>
            <img src={logoutIcon} className={classes.icon} /> Logout
          </MenuItem>
        </Grid>
      </Menu>
    </>
  );
};