import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog } from 'components';
import { useDeleteFilterSetMutation } from 'services';

type DeleteFilterSetModalProps = {
  filterSetId: number,
  onActionSuccess: () => void,
  onCancel: () => void,
};

export const DeleteFilterSetModal = ({ filterSetId, onActionSuccess, onCancel } : DeleteFilterSetModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteFilterSet, isLoading: isDeletingFilterSet } = useDeleteFilterSetMutation(queryClient, {
    onSuccess: () => {
      onActionSuccess();
      onCancel();
      toast.success('Filters successfully deleted.');
    },
    onError: () => {
      toast.error('Filter set unsuccessfully deleted!');
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isDeletingFilterSet}
      onSubmit={() => deleteFilterSet(filterSetId)}
      onCancel={onCancel}/>
  );
};
