import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  errorModal: {
    '& .MuiDialog-paper': {
      maxWidth: '600px'
    },
    '& .MuiDialogTitle-root': {
      paddingBottom: 0,
    }
  },
  menuIconContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '.5rem',
    justifyContent: 'flex-end'
  },
  menuItemError: {
    '&:hover': {
      '& svg': {
        '& path': {
          fill: theme.palette.common.white,
        }
      }
    }
  }
}));