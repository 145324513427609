import React from 'react';
import clsx from 'clsx';
import { Link, Typography } from '@material-ui/core';

import { ConditionalWrapper } from 'components';

import { useStyles } from './ShipperDetailItem.css';

type ShipperDetailItemProps = {
  label: string,
  value?: string | null,
  labelSize?: 'sm' | 'md',
  valueSize?: 'sm' | 'md' | 'lg',
  url?: string,
  reserveMultipleLinesSpace?: boolean,
}

export const ShipperDetailItem = ({ label, value = null, url, labelSize = 'sm', valueSize = 'md', reserveMultipleLinesSpace = false } : ShipperDetailItemProps) => {
  const classes = useStyles({ reserveSpace: reserveMultipleLinesSpace });

  return (
    <>
      <Typography
        className={clsx(
          classes.labelRoot, {
            [classes.labelSmall]: labelSize === 'sm',
            [classes.labelMedium]: labelSize === 'md',
          })}>
        {label}
      </Typography>
      <ConditionalWrapper
        condition={!!url}
        wrapper={(children) =>
          <Link target='_blank' underline='none' className={classes.link} href={url}>
            {children}
          </Link>
        }>
        <Typography
          className={clsx(classes.valueRoot, {
            [classes.valueSmall]: valueSize === 'sm',
            [classes.valueMedium]: valueSize === 'md',
            [classes.valueLarge]: valueSize === 'lg',
          })}>
          {value || '/'}
        </Typography>
      </ConditionalWrapper>
    </>
  );
};
