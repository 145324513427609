import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  stops: {
    paddingBottom: '6px',
  },
  dropTrailerLabel: {
    margin: '1rem 0',
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      borderRadius: 'inherit',
      color: theme.palette.primary.primary200,
      marginRight: '0.5rem',
      padding: 0,
      width: '1rem',
      height: '1rem',
    },
    '& .MuiCheckbox-colorSecondary.Mui-unchecked': {
      color: theme.palette.primary.primary500,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    '&.MuiFormControlLabel-root > span:nth-of-type(2)': {
      ...theme.typography.bodyRegularS,
    },
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
  },
}));