import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Checkbox, CircularProgress, ListItemText, MenuItem, Select, Box } from '@material-ui/core';
import { Chip, FormControl, InputLabel, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { NSearch } from 'components/Filter';
import { Option } from 'types';

import { useStyles } from './NSelect.css';

type SelectFilterProps = {
  options: Option[],
  setSelectedValues: (newValues: Array<number | string>) => void,
  selectedValues: Array<number | string>,
  isMultiple?: boolean,
  placeholder?: string,
  label?: string,
  defaultValue?: string | string[],
  showChips?: boolean,
  disablePortal?: boolean,
  variant?: 'primary' | 'secondary',
  className?: string,
  isError?: boolean,
  disabled?: boolean,
  enableSearch?: boolean,
  search?: string,
  setSearch?: (newText: string) => void
};

export const NSelect = ({
  options,
  setSelectedValues,
  selectedValues,
  isError,
  disabled,
  search,
  setSearch,
  enableSearch = false,
  isMultiple = false,
  placeholder = '',
  label = '',
  disablePortal = true,
  showChips = true,
  variant = 'primary',
  className,
} : SelectFilterProps) => {
  const classes = useStyles(isError);
  const searchRef = useRef(null);

  const [ isSelectedAll, setIsSelectedAll ] = useState(false);

  useEffect(() => {
    setIsSelectedAll(selectedValues.includes('all'));
  }, [selectedValues]);

  const handleChange = (newId: number | string) => {
    if (newId === 'all') {
      setSelectedValues(['all']);
      return;
    }
    if (selectedValues.includes(newId)) {
      const filteredSelectedValues = selectedValues.filter(selectedValue => selectedValue !== newId);
      const defaultSelectedValues = options.map(option => option.id).includes('all') ? ['all'] : [];
      const newSelectedValues = filteredSelectedValues.length > 0 ? filteredSelectedValues : defaultSelectedValues;
      setSelectedValues(isMultiple ? newSelectedValues : []);
      return;
    }
    setSelectedValues(isMultiple ? [ ...selectedValues.filter(selectedValue => selectedValue !== 'all'), newId ] : [newId]);
  };

  return (
    <FormControl className={clsx({[classes[variant as keyof typeof classes]]: true, [classes.selectError]: isError})} sx={{ width: '100%' }} size='medium'>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Select
        onTransitionEnd={() => {
          enableSearch && searchRef?.current?.querySelector('input').focus();
        }}
        onKeyDown={(e) => e.stopPropagation()}
        onClose={() => enableSearch && setSearch('')}
        disabled={disabled}
        displayEmpty
        multiple={isMultiple}
        value={selectedValues}
        disableUnderline
        placeholder={placeholder}
        className={clsx(classes.select, className)}
        renderValue={() => {
          const selectedOptions = options.filter(option => selectedValues.includes(option.id));
          return (
            selectedValues.length === 0 ?
              <InputLabel className={classes.placeholderText}>
                {placeholder}
              </InputLabel> :
              <Box className={classes.chipContainer}>
                {
                  selectedOptions.map((value) => (
                    showChips ?
                      <Chip
                        onMouseDown={(e) => e.stopPropagation()}
                        onDelete={() => handleChange(value.id)}
                        key={value.id}
                        className={classes.selectedChip}
                        label={value.label}
                        variant='outlined'/> :
                      <Typography key={value.id} className={classes.labelText}>{value.label}</Typography>
                  ))
                }
              </Box>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          autoFocus: true,
          getContentAnchorEl: null,
          elevation: 0,
          disablePortal: disablePortal
        }}
        IconComponent={KeyboardArrowDownIcon}>
        {enableSearch &&
        <div onKeyDown={e => e.stopPropagation()} className={classes.search} ref={searchRef}>
          <NSearch value={search} handleChange={(value) => setSearch(value)} placeholder='Search...'/>
        </div>
        }
        <div className={classes.optionsContainer}>
          {options.length > 0 ? options.map(item =>
            <MenuItem key={item.id} value={item.id} className={classes.selectMenuItem} onClick={() => handleChange(item.id)}>
              {isMultiple ?
                <>
                  <Checkbox checked={selectedValues.includes(item.id) || isSelectedAll} />
                  <ListItemText primary={item.label} />
                </> :
                item.label
              }
            </MenuItem>
          )
            :
            <MenuItem className={classes.circuralProgressStyle}><CircularProgress /></MenuItem>}
        </div>
      </Select>
    </FormControl>
  );
};
