import * as Yup from 'yup';

import { VALIDATION_REQUIRED_MSG } from 'enums';

const maxCharWarning = num => `Must be below ${num} characters`;

export const getValidationSchema = (rules, rule) => Yup.object({
  name: Yup.string().required(VALIDATION_REQUIRED_MSG).max(50, maxCharWarning(50))
    .test('uniqueName', 'Rule name must be unique', value => !(((rule && rule.name !== value)||(!rule)) && rules.map( el => el.name).includes(value))),
  priority: Yup.number().required(VALIDATION_REQUIRED_MSG).positive('Priority must be a positive number'),
  order: Yup.string().required(VALIDATION_REQUIRED_MSG),
}).test({
  name: 'uniquePriority',
  test: function({priority, order}) {
    if ( !rule || rule.priority === priority && rule.order === order || !rules.some(el => el.priority === priority && el.order === order)) {
      return true;
    }

    return this.createError({
      path: 'priorityOrder',
      message: 'Rule with specified priority and order already exists',
    });
  }
});
