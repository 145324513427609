import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { SelectFilter } from 'components';
import SimpleConfirmationModal from 'components/Modals/SimpleConfirmationModal';
import { checkActionType } from 'helpers';
import { initAction } from 'services/ruleEngine';
import { Action, ActionType, ExpressionEditorConfig, Option, Setter } from 'types';
import { SetterEditor } from './SetterEditor';

import '@react-awesome-query-builder/ui/css/compact_styles.css';
import expressionEditorStyles from 'components/RuleEngine/ActionEditor.css';

type ActionEditorProps = {
  action: Action;
  config: ExpressionEditorConfig;
  actionsOptions: Option[],
  canDelete: boolean,
  onUpdate: (action: Action) => void;
  onDelete: (action: Action) => void;
  isReadOnly?: boolean
};

export const ActionEditor = ({
  action,
  config,
  actionsOptions,
  canDelete,
  onUpdate,
  onDelete,
  isReadOnly = false,
}: ActionEditorProps) => {

  const styles = expressionEditorStyles();

  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

  const [ actionState, setActionState ] = useState<Action>(action);

  useEffect(() => {
    onUpdate(actionState);
  }, [actionState]);

  const onActionOptionsChange = (field_name: string) => {
    const previousAction = config.actions.find(el => el.name === actionState.name);
    const nextAction = config.actions.find(el => el.name === field_name);
    if (previousAction.action_type === ActionType.SETTER && nextAction.action_type === ActionType.SETTER) {
      if (previousAction.config.result_type === nextAction.config.result_type) {
        return;
      }
    }
    setActionState(initAction(nextAction, actionState.priority));
  };

  return (
    <Card className={styles['setter-node-wrapper']}>

      <CardContent className={styles['setter-node-wrapper__subheader']}>
        <SelectFilter
          className={styles['setter-node-wrapper__header-control']}
          defaultValue={actionState.name}
          onChange={onActionOptionsChange}
          options={actionsOptions}
          placeholder='Choose Action'
          isReadOnly={isReadOnly}/>
        { checkActionType(config, actionState, ActionType.SETTER) &&
          <SetterEditor
            setterState={actionState as Setter}
            setSetterState={(setter) => setActionState(setter)}
            config={config}
            isReadOnly={isReadOnly}/>
        }
        {
          canDelete &&
          <IconButton onClick={() => setIsDeleteModalOpen(true)} disabled={isReadOnly}>
            <ClearIcon />
          </IconButton>
        }
        <SimpleConfirmationModal
          isOpen={isDeleteModalOpen}
          title='Are you sure you want to delete the action?'
          confirmLabel='Yes'
          rejectLabel='No'
          onConfirm={() => {
            onDelete(action);
          }}
          onReject={() => setIsDeleteModalOpen(false)} />
      </CardContent>
    </Card>
  );
};

