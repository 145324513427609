import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { Layout, NavigationButton } from 'components';
import { PAGE_TYPE } from 'enums';
import { CustomerUpsertForm } from './components/CustomerUpsertForm';

import { useStyles } from './CreateUpdateShipperPage.css';

type CreateUpdateShippersPageProps = {
  type: string,
};

export const CreateUpdateShipperPage = ({ type }: CreateUpdateShippersPageProps) => {
  const classes = useStyles();
  const history = useHistory();

  const getPageTitle = () => {
    let title = '';
    switch (type) {
      case PAGE_TYPE.CREATE:
        title = 'Add New Shipper';
        break;
      case PAGE_TYPE.EDIT:
        title = 'Edit Shipper';
        break;
    }
    return title;
  };

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo='/shippers'/>}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {getPageTitle()}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomerUpsertForm onCancel={() => history.push('/shippers')} />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};