import { MAX_PRICE_VALUE } from 'enums/constants';
import { Quote } from 'types/QuoteTypes';
import * as Yup from 'yup';

export const getValidationSchema = () => {
  const validationSchema = Yup.object({
    price: Yup.number()
      .typeError('Invalid value')
      .moreThan(0, 'Must be greater than 0')
      .lessThan(MAX_PRICE_VALUE + 1, `Must be less than ${MAX_PRICE_VALUE+1}`)
      .integer('Must be an integer')
      .required('Required')
  });

  return validationSchema;
};

export const getMarginValidationSchema = (quote: Quote) => Yup.object({
  margin: Yup.number()
    .typeError('Invalid value')
    .moreThan(-quote?.quoted_price, `Must be greater than ${-quote?.quoted_price}`)
    .lessThan(MAX_PRICE_VALUE + 1, `Must be less than ${MAX_PRICE_VALUE+1}`)
    .integer('Must be an integer')
    .required('Required')
});
