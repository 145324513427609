import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  headerButton: {
    height: '2.5rem',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon > *:first-child': {
        fontSize: '0.875rem'
      },
    },
  },
  uploadModalComponent: {
    '& .MuiPaper-root': {
      maxHeight: '70%',
    },
    '& .MuiDialogTitle-root': {
      padding: '1.875rem 1.875rem 0 1.875rem',
    },
  },
}));