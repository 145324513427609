import React from 'react';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';

import { ProfileMenu, Sidebar } from 'components';
import { config } from '../../config';

import { useStyles } from './Layout.css';

export const Layout = ({ children, title, goBackTo = '' }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.app}>
      <Sidebar/>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <Box className={classes.titleContainer} >
              { goBackTo && <FontAwesomeIcon onClick={() => history.push(goBackTo)} icon={faChevronLeft}/>}
              { title ?
                <Typography variant='h2'>
                  {title}
                </Typography> : <Skeleton width={100} height={18}/>}
            </Box>
            {config.isTraining && <Typography variant='h3' className={classes.training}>training</Typography>}
            <div className={classes.user}>
              <ProfileMenu />
            </div>
          </div>
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
