import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Grid, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { SadStates } from 'components';
import { generateStatusTitle} from 'helpers';
import { useIntegrationLogQuery } from 'services';
import { INTEGRATION_TYPES_LABEL } from 'enums/index';
import { TabContent } from './components/TabContent';
import { LogInfoLine } from './components/LogInfoLine';

import { copyIcon, downloadIcon } from 'resources';
import { useStyles } from './IntegrationLogModal.css';

type IntegrationLogModalContentProps = {
  integrationLogId: number,
}

export const IntegrationLogModal = ({ integrationLogId } : IntegrationLogModalContentProps) => {
  const [ value, setValue ] = useState<string>('0');
  const [ showRaw, setShowRaw ] = useState(false);
  const classes = useStyles();

  const { isLoading, data }= useIntegrationLogQuery(integrationLogId);
  const { data: integrationLog } = data || {};

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const exportToJSON = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(value === '0' ? integrationLog.request_body : integrationLog.response_body)
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${INTEGRATION_TYPES_LABEL[integrationLog.integration]} ${integrationLog.created_at}.json`.replace(/\s+/g, '_');
    link.click();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(value === '0' ? integrationLog.request_body : integrationLog.response_body));
    toast.success('Copied!', { position: toast.POSITION.TOP_CENTER });
  };

  const strToJSON = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {
        'error': 'Invalid JSON'
      };
    }
  };

  return (
    <Box style={{position: 'relative'}}>
      <SadStates states={[
        {
          when: isLoading,
          render: () => (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item><CircularProgress thickness={1} size={80} color='primary'/></Grid>
            </Grid>
          )
        }
      ]}>
        <Box className={classes.logInfoContainer}>
          <LogInfoLine text='Integration' value={INTEGRATION_TYPES_LABEL[integrationLog?.integration]}/>
          <LogInfoLine text='Status Code' value={generateStatusTitle(integrationLog?.status_code)}/>
          <LogInfoLine text='Targeted API' value={integrationLog?.targeted_api}/>
        </Box>
        <Box>
          <TabContext value={value}>
            <Tabs value={value} onChange={handleChange} className={classes.tabHeader}>
              <Tab label='Request Body' value='0'/>
              <Tab label='Response Body' value='1'/>
            </Tabs>
            <br/>
            <TabPanel className={classes.tabPanel} value='0'>
              <TabContent
                body={
                  typeof integrationLog?.request_body === 'string' ? strToJSON(integrationLog?.request_body) : integrationLog?.request_body
                }
                showRaw={showRaw}/>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value='1'>
              <TabContent
                body={integrationLog?.response_body}
                showRaw={showRaw} />
            </TabPanel>
            <Box className={classes.buttonContainer}>
              <Box className={classes.switchBox}>
                <Typography>Show raw</Typography><Switch className={classes.switchCustom} onClick={() => setShowRaw(!showRaw)} />
              </Box>
              <Box className={classes.actionButton}>
                <Tooltip title='Export to JSON'>
                  <Box onClick={exportToJSON}>
                    <img src={downloadIcon} />
                  </Box>
                </Tooltip>
              </Box>
              <Box className={classes.actionButton}>
                <Tooltip title='Copy to clipboard'>
                  <Box onClick={copyToClipboard}>
                    <img src={copyIcon} />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </TabContext>
        </Box>
      </SadStates>
    </Box>
  );
};
