import React from 'react';
import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { useStyles } from './CollapsibleButton.css';

type CollapsibleButtonProps = {
  children: React.ReactNode,
  className?: string | undefined,
  isCollapsed: boolean,
  showTopDivider?: boolean,
  text: string,
  handleOnClick: () => void,
};

export const CollapsibleButton = ({
  children,
  className,
  isCollapsed,
  showTopDivider = false,
  text,
  handleOnClick,
}: CollapsibleButtonProps) => {
  const classes = useStyles();

  return (
    <>
      {showTopDivider && <Divider className={classes.divider} />}
      <Grid container alignItems='center' justify='flex-start' className={clsx({
        [classes.moreOptions]: true,
        [className]: className,
        [classes.collapsed]: isCollapsed,
        [classes.notCollapsed]: !isCollapsed,
      })}>
        <Typography variant='h3' className={classes.title}>{text}</Typography>
        <IconButton onClick={handleOnClick}>
          {isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      {isCollapsed && children}
      <Divider className={classes.divider} />
    </>
  );
};