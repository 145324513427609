import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dialogStyle: {
    '& .MuiDialogTitle-root': {
      padding: '1.875rem 1.875rem 1rem 1.875rem'
    },
    '& .MuiDialogContent-root': {
      padding: '0 1.875rem 1.875rem 1.875rem',
      overflowY: 'hidden'
    }
  }
}));