import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@mui/material';

import { SearchIcon } from 'resources';

import { useStyles } from './TableStates.css';

type TableStateProps = {
  colSpan: number,
  classes?: {
    [key:string]: string
  },
};

export const TableLoadingState = ({ colSpan }: TableStateProps) => {
  const classes = useStyles();

  return (
    <>
      {[...Array(10)].map((_, i) => (
        <TableRow key={i}>
          <TableCell colSpan={colSpan}>
            <Skeleton className={classes.skeleton}/>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export const TableNoResultsState = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={classes.center}>
      <Grid item><SearchIcon/></Grid>
      <Grid item className={classes.text}>Sorry, no results were found.</Grid>
    </Grid>
  );
};

export const TableErrorState = ({ classes, colSpan }: TableStateProps) => (
  <TableRow>
    <TableCell colSpan={colSpan} className={classes.cell}>
      <div className={classes.tableMessage}>
        There was an error processing your request. Please try again later.
      </div>
    </TableCell>
  </TableRow>
);