import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  muiFormControl: {
    '&.MuiFormControl-root': {
      border: 0,
      display: 'inline-flex',
      flexDirection: 'row',
      gap: '2rem',
      marginBottom: '0.5rem',
      minWidth: 0,
      position: 'relative',
      verticalAlign: 'top',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1rem',
      },
    },
    '& .MuiGrid-root': {
      width: '100%',
    },
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
    marginTop: '2rem',
  },
  showTypes: {
    '&.MuiGrid-container ': {
      gap: '1rem',
      padding: '2rem 0',
    },
    '& button': {
      width: '9rem',
    }
  },
  moreOptionsContainer: {
    marginTop: '3rem',
  },
  moreOptions: {
    '& .MuiTypography-root': {
      width: 'auto',
    },
  },
  moreOptionsRowContent: {
    alignItems: 'center',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    paddingBottom: '1.5rem',
    width: '100%',
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  morOptionsContainer: {
    margin: '3rem 0',
  },
  flexBottom: {
    alignItems: 'end',
  },
  flexStart: {
    alignItems: 'center',
    justifyContent: 'start',
    height: '3.5rem',
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.primary200,
      padding: '0.375rem',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  loadingMsg: {
    padding: 24,
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      borderRadius: 'inherit',
      color: theme.palette.primary.primary200,
      marginRight: '0.5rem',
      padding: 0,
      width: '1rem',
      height: '1rem',
    },
    '& .MuiTypography-root': {
      ...theme.typography.bodyRegularS,
    }
  },
  fullWidth: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: '1.5rem',
  },
}));