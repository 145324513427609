import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles(((theme: ThemeDefault) => ({
  additionalItem: {
    alignItems: 'center',
    display: 'grid',
    height: '100%',
    width: '100%',
    '& .Mui-checked': {
      color: theme.palette.primary.primary700
    },
    '& .MuiTypography-root': {
      ...theme.typography.bodyRegularS,
    },
    '& .MuiInputBase-root': {
      padding: '0.75rem 1rem',
    },
    '& .MuiFormControlLabel-root': {
      margin: '0 0 1.875rem 0',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.neutral.neutral0,
      borderRadius: 'inherit',
      height: '1rem',
      color: theme.palette.primary.primary200,
      marginRight: '0.5rem',
      padding: 0,
      width: '1rem',
    },
  },
  gridContainer: {
    columnGap: '1rem',
    display: 'flex',
    width: '100%',
  },
  expandableContentContainer: {
    display: 'flex',
    marginTop: '1.125rem',
    rowGap: '1rem',
  },
  rowContainer: {
    display: 'flex',
    flex: 1,
    gap: '1.5rem',
    width: '100%',
  },
})));
