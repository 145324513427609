import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { AccessControl } from 'components';
import { MenuItem as MenuItemType} from 'types';

import { useStyles } from './TableMenu.css';

type TableMenuProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  anchorEl: HTMLElement,
  open: boolean,
  onClose: () => void,
  items: MenuItemType[]
}

export const TableMenu = ({ onClick, anchorEl, open, onClose, items } : TableMenuProps) => {
  const classes = useStyles();
  const permissions: string[] = items.flatMap(item => item.hidden ? [] : item.permissions);

  return (
    <AccessControl permissions={permissions}>
      <IconButton onClick={onClick} size='small'>
        <FontAwesomeIcon icon={faEllipsisV}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          className: classes.menu
        }}
        variant='menu'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {items.map( (item: MenuItemType, index: number) => (
          !item.hidden &&
          <ConditionalWrapper
            condition={!!item.permissions}
            wrapper={(children) => <AccessControl permissions={item.permissions}>{children}</AccessControl>}
            key={index}>
            <>
              <MenuItem
                component={item.urlTo ? Link : MenuItem}
                to={item.urlTo}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => item.onClick?.(e)}
                disabled={item.disabled}
                className={clsx({
                  [classes.menuItem]: true,
                  [classes.defaultColor]: !item.className,
                  [item.className]: item.className
                })}>
                <ListItemIcon>
                  <item.icon/>
                </ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </MenuItem>
              {item.divider && <Divider variant='middle' className={classes.divider}/>}
            </>
          </ConditionalWrapper>
        ))}
      </Menu>
    </AccessControl>
  );
};