import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  mainInfoContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    boxSizing: 'border-box',
    marginBottom: '1rem',
    padding: '1.8rem',
    width: '100%',
  },
  mainInfoContent: {
    display: 'flex',
    paddingTop: '0.6rem',
    width: '100%',
    '& hr': {
      height: 'auto'
    }
  },
}));