import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  skeleton: {
    height: '1.75rem',
    margin: '0.5rem 0rem',
    borderRadius: '8px',
    background: theme.palette.neutral.neutral100
  },
  message: {
    ...theme.typography.bodyM,
    marginBottom: '1.25rem',
  }
}));