import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';

import { Layout } from 'components';

export const Unauthorized = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 4000);
  }, []);

  return (
    <Layout title='Redirecting...'>
      <Grid style={{ display: 'flex'}}>
        <h1 style={{ marginRight: 20}}>Unauthorized! Redirecting to dashboard page... </h1>
        <CircularProgress color='inherit' />
      </Grid>
    </Layout>
  );
};