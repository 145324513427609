import React, { useMemo, useState } from 'react';

import { DeleteAwardLanesModal, ModalComponent, TableMenu } from 'components';
import { AWARD_PERMISSIONS } from 'permissions';
import { DeleteIcon, Pencil } from 'resources';
import { AwardLane } from 'types';

type AwardLanesTableMenuProps = {
  lane: AwardLane,
  awardId: number,
  onActionSucces: () => void
};

export const AwardLanesTableMenu = ({ lane, awardId, onActionSucces } : AwardLanesTableMenuProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isDeleteAwardLanesModalOpen, setIsDeleteAwardLanesModalOpen ] = useState<boolean>(false);

  const deleteAwardLaneHandler = () => {
    setIsDeleteAwardLanesModalOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [AWARD_PERMISSIONS.UPDATE_AWARD_LANE],
      text: 'Edit',
      icon: Pencil,
      urlTo: `/awards/${awardId}/lanes/${lane.id}/edit`,
    },
    {
      permissions: [AWARD_PERMISSIONS.DELETE_AWARD_LANE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteAwardLaneHandler,
    },
  ], [ lane, awardId ]);

  const onCancelDelete = () => {
    setIsDeleteAwardLanesModalOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>

      <ModalComponent
        message='Are you sure you want to delete the lane?'
        isOpen={isDeleteAwardLanesModalOpen}
        onCancel={() => setIsDeleteAwardLanesModalOpen(false)}>
        <DeleteAwardLanesModal awardId={awardId} selectedAwardLanes={[{id: lane.id}]} onCancel={onCancelDelete} onDeleteSuccess={onActionSucces} isModalOpen={true}/>
      </ModalComponent>
    </>
  );
};