import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  searchBar: {
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.neutral0,
    borderRadius: '.5rem',
    boxShadow: 'none',
    color: theme.palette.neutral.neutral400,
    display: 'flex',
    height: '2.5rem',
    flexDirection: 'row-reverse',
    padding: '0 .88rem',
    width: '25rem',
    '& input': {
      ...theme.typography.bodyRegularS,
    },
    '& input::placeholder': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral400,
      opacity: 1,
    },
    '& .MuiIconButton-root': {
      marginRight: '0.5rem',
      padding: 0,
      '& :hover': {
        backgroundColor: theme.palette.neutral.neutral0,
      },
      '& :focus': {
        backgroundColor: theme.palette.neutral.neutral0,
      },
    },
  },
  searchIconButton: {
    cursor: 'pointer',
    height: '1.25rem',
    marginRight: '0.5rem',
    width: '1.25rem',
    '& svg': {
      fill: theme.palette.neutral.neutral400,
      height: '1rem',
      overflow: 'visible',
      '& path': {
        stroke: theme.palette.neutral.neutral400,
        strokeWidth: '0.3rem',
      },
    },
  },
  iconButtonHidden: {
    display: 'none',
  },
  searchContainer: {
    flex: 1,
    margin: 0,
  },
  closeIconButton: {
    height: '0.8rem',
    marginRight: '0.5rem',
    overflow: 'visible',
    stroke: theme.palette.neutral.neutral400,
    strokeWidth: '0.06rem',
    width: '0.8rem',
  },
}));