import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  actionsWrapper: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'start',
    width: '13.5rem',
    '&.MuiGrid-item': {
      marginBottom: '1.5rem',
    },
    '& ul': {
      padding: 0,
    },
    '& .MuiCard-root': {
      overflow: 'visible',
    },
  },
  actionsWrapperExpanded: {
    '&.MuiGrid-item': {
      marginBottom: '1.5rem',
    },
  },
  collapsibleCard: {
    borderRadius: '0.5rem',
    boxShadow: 'none',
    width: 'inherit',
    '& button': {
      padding: 0,
      zIndex: 1,
    },
    '& .MuiCardActions-root': {
      padding: '8px 20px',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '& .MuiTypography-h6': {
      padding: '0.625rem 1.25rem',
    },
    '& hr': {
      backgroundColor: theme.palette.primary.primary500,
      height: '1.125rem',
      margin: 'auto',
      marginRight: 0,
    },
  },
  primary: {
    background: '#0071ED',
    color: theme.palette.neutral.neutral0,
    '& .MuiTypography-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.primary500,
      },
    },
  },
  secondary: {
    background: theme.palette.primary.primary100,
    color: theme.palette.primary.primary700,
    '& .MuiTypography-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.primary200,
      },
    },
  },
  collapsibleCardContent: {
    background: theme.palette.neutral.neutral0,
    height: '2.5rem',
    position: 'relative',
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '0.875rem',
      padding: '10px 20px 10px 10px',
    },
    '& button': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '-webkit-fill-available',
      '& .MuiButton-label > *:first-child': {
        padding: '10px 0 10px 20px',
      },
    },
  },
  collapsibleOptionWrapper: {
    position: 'absolute',
    width: '-webkit-fill-available',
  },
  collapsibleOption: {
    borderTop: 'none',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    paddingTop: '2.5rem',
    top: 0,
    '& .MuiTypography-root': {
      borderRadius: '0.5rem',
      padding: '0.625rem 1.25rem',
    },
  },
  collapsibleOptionCollapsed: {
    display: 'none',
  },
  primaryCardContent: {
    backgroundColor: '#0071ED',
    border: `1px solid ${theme.palette.primary.primary700}`,
  },
  secondaryCardContent: {
    backgroundColor: theme.palette.primary.primary100,
    border: `1px solid ${theme.palette.primary.primary700}`,
  },
}));