import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';

import { ActionButton, ModalComponent } from 'components';
import { useMergeCustomersMutation } from 'services';
import { Customer } from 'types';

type MergeCustomersModalContentProps = {
  onCancel : () => void,
  customers: Customer[],
  onMergeSuccess: (customers: React.SetStateAction<Customer[]>) => void
}

const MergeCustomersModalContent = ({ onCancel, customers, onMergeSuccess } : MergeCustomersModalContentProps) => {
  const [ targetCustomer, setTargetCustomer ] = useState<number>(null);
  const queryClient = useQueryClient();

  const {mutate: mergeCustomers, isLoading} = useMergeCustomersMutation(queryClient, {
    onSuccess: () => {
      toast.success('Shippers successfully merged!');
      onMergeSuccess([]);
      onCancel();
    },
    onError: (error) => {
      toast.error(error.response.data.message);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetCustomer(Number(event.target.value));
  };

  const handleMergeCustomers = () => {
    const source_customers = customers.filter(c => c.id !== targetCustomer).map(c => c.id);
    const payload = {target_customer: targetCustomer, source_customers};
    mergeCustomers(payload);
  };

  return (
    <Card>
      <CardContent>
        <Grid container direction='column' spacing={2} style={{'padding': 12}}>
          <Grid item>
            <FormControl>
              <FormLabel style={{'paddingBottom': 12}}>Choose target shipper</FormLabel>
              <RadioGroup
                value={targetCustomer}
                onChange={handleChange}>
                { customers.map(customer => (
                  <FormControlLabel key={customer.id} value={customer.id} control={<Radio color='primary'/>} label={customer.customer_slug} />
                ))
                }
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <ActionButton
              text='Merge Shippers'
              disabled={!targetCustomer || isLoading}
              isLoading={isLoading}
              handleClick={handleMergeCustomers} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

type MergeCustomersModalProps = {
  isOpen: boolean,
  onCancel: () => void,
  selectedCustomers: Customer[],
  onMergeSuccess: (customers: React.SetStateAction<Customer[]>) => void
}

export const MergeCustomersModal = ({ isOpen, onCancel, selectedCustomers, onMergeSuccess } : MergeCustomersModalProps) => {
  return (
    <ModalComponent
      message='Merge shippers'
      isOpen={isOpen}
      onCancel={onCancel}>
      <MergeCustomersModalContent onCancel={onCancel} customers={selectedCustomers} onMergeSuccess={onMergeSuccess}/>
    </ModalComponent>
  );
};