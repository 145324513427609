import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '1rem',
    width: '100%'
  },
  loadingSpinnerBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    '& .MuiCircularProgress-svg': {
      color: theme.palette.primary.primary700,
    }
  },
}));
