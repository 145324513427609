import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBaseComponentProps,
  OutlinedInput,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, FieldProps, FormikProps } from 'formik';

import { isReactNode } from 'helpers';

import { useStyles } from './InputField.css';

type ErrorType = Record<string, unknown> | ReactNode;

const getErrors = (id: string, errors: ErrorType) => {
  if (!errors) {
    return;
  }

  const idparts = id.split('.');
  if (idparts.length === 1) {
    return errors[id as keyof ErrorType];
  }

  const rootError = errors[idparts[0] as keyof ErrorType];
  if (rootError && typeof rootError === 'object') {
    const nestedError = (rootError as Record<string, unknown>)[idparts[1]];
    return isReactNode(nestedError) ? nestedError : null;
  }

  return null;
};

type InputFieldProps = {
  id: string,
  label?: string,
  type?: string,
  placeholder?: string,
  onChange?: (v?: string) => void,
  inputProps?: InputBaseComponentProps,
  startIcon?: string,
  endIcon?: string | JSX.Element,
  error?: string,
  reserveErrorMsgSpace?: boolean,
  readOnly?: boolean,
  autoFocus?: boolean,
  className?: string,
  multiline?: boolean
};

export const InputField = ({
  id,
  label,
  type = 'text',
  placeholder = '',
  onChange = null,
  inputProps = {},
  startIcon = '',
  endIcon = '',
  error = '',
  reserveErrorMsgSpace=false,
  readOnly=false,
  autoFocus=false,
  className,
  multiline = false
}: InputFieldProps) => {
  const classes = useStyles();

  const getDefaultValue = (form: FormikProps<unknown>, value: string) => {
    const splitValues = value.split('.');

    // if id is obj gets it's param value
    if (splitValues.length > 1 && form.values[splitValues[0] as keyof typeof form.values]) {
      return form.values[splitValues[0] as keyof typeof form.values][splitValues[1] as keyof typeof form.values];
    }

    return form.values[id as keyof typeof form.values];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, form: FormikProps<unknown>, id: string) => {
    form.setFieldValue(id, event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <div>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Field name={id} className={classes.field}>
        {({ form } : FieldProps<unknown>) => (
          <FormControl error={!!getErrors(id, form.errors)} className={classes.formControl}>
            <OutlinedInput
              multiline={multiline}
              id={id}
              className={clsx({
                [classes.input]: true,
                [className]: className,
              })}
              type={type}
              readOnly={readOnly}
              autoFocus={autoFocus}
              value={getDefaultValue(form, id)}
              placeholder={placeholder}
              inputProps={inputProps}
              error={!!getErrors(id, form.errors) || !!error}
              onChange={(e) => handleChange(e, form, id)}
              onFocus={(e) => e.target.select()}
              startAdornment={startIcon && <InputAdornment position='start' className={classes.startAdornment}>{startIcon}</InputAdornment>}
              endAdornment={endIcon && <InputAdornment position='end' className={classes.endAdornment}>{endIcon}</InputAdornment>}/>
            { reserveErrorMsgSpace ? <FormHelperText className={classes.errorMsg}>{ getErrors(id, form?.errors) ? getErrors(id, form.errors) : ' '}</FormHelperText> : getErrors(id, form?.errors) && <FormHelperText> {getErrors(id, form.errors)} </FormHelperText>}
            {error && <FormHelperText className={classes.errorMsg}> {error} </FormHelperText>}
          </FormControl >
        )}
      </Field>
    </div>
  );
};