import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  rowContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  uploadContainer: {
    border: `1px dashed ${theme.palette.secondary.secondary500}`,
    borderRadius: '0.5rem',
    padding: '1.125rem',
  },
}));