import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  chip: {
    ...theme.typography.bodyRegularS,
    borderRadius: '1.25rem',
    minWidth: '5.375rem',
    height: '1.5rem',
    '& .MuiChip-label': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '5rem',
    },
  },
  purple: {
    color: theme.palette.open.main,
    background: theme.palette.open.light
  },
  blue: {
    color: theme.palette.primary.primary700,
    background: theme.palette.primary.primary200
  },
  yellow: {
    color: theme.palette.warning.main,
    background: theme.palette.warning.light
  },
  green: {
    color: theme.palette.success.main,
    background: theme.palette.success.light
  },
  red: {
    color: theme.palette.error.main,
    background: theme.palette.error.light
  },
  grey: {
    color: theme.palette.inactive.main,
    background: theme.palette.inactive.light
  },
}));