import React from 'react';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { SadStates, WidgetNoResultsState } from 'components';
import { useGetFuelSheetDetails } from 'services';
import { FuelSheetRateRange } from 'types';
import { ModalComponent } from '../ModalComponent/ModalComponent';

import { useStyles } from './FuelSheetDetailsModal.css';

type FuelSheetDetailsModalProps = {
  fuelSheetId: number,
  isModalOpen: boolean,
  onCancel: () => void
};

export const FuelSheetDetailsModal = ({fuelSheetId, isModalOpen, onCancel } : FuelSheetDetailsModalProps) => {
  const classes = useStyles();

  const { isLoading, isSuccess, data } = useGetFuelSheetDetails(fuelSheetId);
  const { data: fuelSheetDetail } = data || {};

  return (
    <ModalComponent
      modalWidth='sm'
      message={ isLoading ? '' : `${fuelSheetDetail?.region} Fuel Sheet Details`}
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <SadStates states={[
        {
          when: isLoading,
          render: () => (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item><CircularProgress thickness={1} size={80} color='primary'/></Grid>
            </Grid>
          )
        },
        {
          when: isSuccess && fuelSheetDetail?.fuel_sheet_rate_ranges?.length === 0,
          render: () => <WidgetNoResultsState message='No fuel sheet details.'/>
        },
      ]}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>Fuel sheet price range</TableCell>
                <TableCell>Rate type</TableCell>
                <TableCell>Rate value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fuelSheetDetail?.fuel_sheet_rate_ranges.map((item: FuelSheetRateRange, index: number) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>{item.min_price} - {item.max_price}</TableCell>
                    <TableCell>{item.rate_type}</TableCell>
                    <TableCell>{item.rate_value}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </SadStates>
    </ModalComponent>
  );
};