import * as Yup from 'yup';

import { MAX_PRICE_VALUE, VALIDATION_REQUIRED_MSG } from 'enums';

export const getValidationSchema = () => {
  const validationSchema = Yup.object({
    winningBid: Yup.number()
      .typeError('Input must be a number.')
      .min(0, 'Winning bid can\'t be a negative value.')
      .lessThan(MAX_PRICE_VALUE + 1, `Must be less than ${MAX_PRICE_VALUE+1}`),
    reason: Yup.string().required(VALIDATION_REQUIRED_MSG)
  });

  return validationSchema;
};