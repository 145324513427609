import React, { FC } from 'react';
import { ConfirmationDialog, ModalComponent } from 'components';

interface SimpleConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message?: string;
  onConfirm: (...args: unknown[]) => void;
  onReject: (...args: unknown[]) => void;
  confirmLabel?: string;
  rejectLabel?: string;
}

const SimpleConfirmationModal: FC<SimpleConfirmationModalProps> = ({ isOpen, title, message, onConfirm, onReject, confirmLabel, rejectLabel }) => {
  return (
    <ModalComponent
      message={title}
      isOpen={isOpen}
      onCancel={onReject}>
      <ConfirmationDialog
        message={message}
        primaryButtonLabel={confirmLabel}
        cancelButtonLabel={rejectLabel}
        onSubmit={onConfirm}
        onCancel={onReject}/>
    </ModalComponent>
  );
};

export default SimpleConfirmationModal;
