import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  text: {
    ...theme.typography.h1,
    color: theme.palette.inactive.main,
    padding: '1rem 0rem'
  },
  container: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    justifyContent: 'center'
  },
}));