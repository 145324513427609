import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  icon: {
    height: '1rem',
    width: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    '& p': {
      ...theme.typography.bodyRegularXS,
      marginLeft: '.4rem'
    }
  },
  locationInfo: {
    boxSizing: 'border-box',
    flex: 5,
    height: '100%',
    paddingTop: '1rem',
    width: '100%',
  },
  locationName: {
    boxOrient: 'vertical',
    display: '-webkit-box',
    lineClamp: 1,
    margin: '0.6rem 0 0.3rem 0',
    maxWidth: '95%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  },
  locationValue: {
    ...theme.typography.bodyRegularXS,
  },
  pickupDelivery: {
    display: 'flex',
  },
  pickupDeliveryItem: {
    marginBottom: '1rem',
    width: '50%',
    '& div': {
      color: theme.palette.inactive.main,
      display: 'flex',
      '& h6': {
        color: theme.palette.neutral.neutral400,
        marginLeft: '0.4rem',
        width: 'fit-content',
      }
    },
  },
}));