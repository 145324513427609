import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.bodyMediumS,
    marginBottom: 4,
  },
  formControl: {
    width: '100%',
    '& > div > div.MuiInputBase-root.MuiInput-root': {
      padding: 9,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:focus-within': {
        borderColor: '#0d6ba4',
      },
      '&:hover:not(:focus-within)': {
        borderColor: 'rgba(0, 0, 0, 0.87)'
      },
      borderRadius: 5,
      '& > input': {
        padding: 0
      }
    },
    '& > p.MuiFormHelperText-contained': {
      margin: '5px 0 0 0',
    },
    '& > div > div.MuiInput-underline:after': {
      content: 'none'
    },
    '& > div > div.MuiInput-underline:before': {
      content: 'none'
    },
  }
}));