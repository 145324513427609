import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    background: theme.palette.neutral.neutral0,
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    boxShadow: '0 0.25rem 1rem 0 rgba(0, 0, 0, 0.04)',
    padding: '1rem 1.5rem',
    height: '2rem',
  },
  pagination: {
    color: theme.palette.neutral.neutral400,
    '& .MuiPaginationItem-root': {
      ...theme.typography.bodyRegularXS,
      background: theme.palette.neutral.neutral100,
      borderRadius: '0.25rem',
      margin: '0.1rem',
      '&:hover': {
        background: theme.palette.primary.primary500,
        color: theme.palette.neutral.neutral0,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent !important',
        color: `${theme.palette.neutral.neutral400} !important`
      }
    },
    '& .Mui-selected': {
      background: theme.palette.primary.primary700,
      color: theme.palette.neutral.neutral0,
    }
  },
  text: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    alignItems: 'center',
    display: 'flex'
  },
  select: {
    ...theme.typography.bodyMediumS,
    color: theme.palette.neutral.neutral700,
    height: '-webkit-fill-available',
    '& svg': {
      fill: theme.palette.neutral.neutral700,
      width: '1rem',
    },
    '& > .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        borderWidth: 1,
      },
    },
  },
  paper: {
    border: `1px solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    '& ul': {
      padding: 0,
      '& li': {
        ...theme.typography.bodyMediumS,
      },
    },
    '& .MuiMenuItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
  },
  input: {
    ...theme.typography.bodyRegularS,
    width: '4rem',
    color: theme.palette.neutral.neutral400,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
  },
  inputContainer: {
    '& div > .MuiOutlinedInput-input': {
      alignItems: 'center',
      display: 'flex',
      padding: '0.625rem 1.25rem',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        border: `1px solid ${theme.palette.primary.primary700}`,
        borderRadius: '0.5rem',
      },
    },
  },
}));