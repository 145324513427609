export enum FILE_STATUS {
    PENDING = 1,
    PROCESSING = 2,
    SUCCESS = 3,
    ERROR = 4
}

export const FILE_STATUS_LABELS: Record<string, string> = {
  [FILE_STATUS.PENDING]: 'Pending',
  [FILE_STATUS.PROCESSING]: 'Processing',
  [FILE_STATUS.SUCCESS]: 'Success',
  [FILE_STATUS.ERROR]: 'Error',
};

export enum FILE_CATEGORY {
    FUEL_SHEET = 1,
    MARKET_STRENGTH = 2,
    AWARD = 3,
    SHIPPERS = 4,
    WORKING_COPY = 5,
    CARRIER_PREFERENCE = 6,
    QUOTE = 7,
    BULK_AWARD = 8,
}

export const FILE_CATEGORY_LABELS: Record<string, string> = {
  [FILE_CATEGORY.FUEL_SHEET]: 'Fuel sheet',
  [FILE_CATEGORY.MARKET_STRENGTH]: 'Market strength',
  [FILE_CATEGORY.AWARD]: 'Award',
  [FILE_CATEGORY.SHIPPERS]: 'Shippers',
  [FILE_CATEGORY.WORKING_COPY]: 'Working copy',
  [FILE_CATEGORY.CARRIER_PREFERENCE]: 'Carrier preference',
  [FILE_CATEGORY.QUOTE]: 'Quote',
  [FILE_CATEGORY.BULK_AWARD]: 'Bulk Award',
};

export const FILE_CATEGORIES = [
  { id: FILE_CATEGORY.MARKET_STRENGTH, label: 'Market strength'},
  { id: FILE_CATEGORY.SHIPPERS, label: 'Shipper'},
  { id: FILE_CATEGORY.CARRIER_PREFERENCE, label: 'Carrier preference'},
  { id: FILE_CATEGORY.QUOTE, label: 'Quote'},
  { id: FILE_CATEGORY.AWARD, label: 'Award'},
  { id: FILE_CATEGORY.BULK_AWARD, label: 'Bulk Award'},
];