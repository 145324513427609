import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { InputAdornment, MenuItem, Select, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ActionButton, InputField } from 'components';
import {
  CURRENCY_TYPE,
  MAX_PRICE_VALUE,
  QUOTE_COST_TYPE,
  QUOTE_COST_TYPE_LABELS,
  QUOTE_STATUS
} from 'enums';
import { CheckIcon, XMark } from 'resources';
import { useQuoteUpdateMarginMutation, useQuoteUpdatePriceMutation } from 'services';
import { PriceCurrency, QuoteDetails as QuoteDetailsType, QuoteMarginForm } from 'types';
import { getMarginValidationSchema, getValidationSchema } from '../validation';

import { useStyles } from '../QuoteDetailsPage.css';

type QuotePriceMarginResolverProps = {
    quote: QuoteDetailsType,
    priceCurrency: PriceCurrency,
    formRef: React.MutableRefObject<FormikProps<QuoteMarginForm>>,
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>,
    setPriceCurrency: React.Dispatch<React.SetStateAction<PriceCurrency>>
};

export const QuotePriceMarginResolver = ({ quote, priceCurrency, formRef, setIsEditable, setPriceCurrency }: QuotePriceMarginResolverProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [ isValid, setIsValid ] = useState<boolean>(true);
  const roundedPrice = Math.round(quote.quoted_price);
  const roundedMargin = Math.round(quote.margin);

  const formatQuotePriceLabel = (cost_type: QUOTE_COST_TYPE) => {
    return `Quoted Price  - ${QUOTE_COST_TYPE_LABELS[cost_type]}`;
  };

  const formatQuoteMarginLabel = (cost_type: QUOTE_COST_TYPE) => {
    return `Quoted Margin  - ${QUOTE_COST_TYPE_LABELS[cost_type]}`;
  };

  const getInitialValues = () => {
    return quote.status === QUOTE_STATUS.WON ?
      {'margin': (roundedMargin || 0)} :
      {
        'price': (roundedPrice || 0),
        'currency': quote.currency ?? CURRENCY_TYPE.USD
      };
  };

  const handleValidationMargin = (value: string) => {
    const price = Number(value);
    const valid = (price <= MAX_PRICE_VALUE && price > -roundedPrice && Number.isInteger(price));
    setIsValid(valid);
    return valid;
  };

  const handleValidationPriceCurrency = (priceToCheck: number, currencyToCheck:string) => {
    const valid = (priceToCheck <= MAX_PRICE_VALUE && priceToCheck > 0 && Number.isInteger(priceToCheck) && Object.values(CURRENCY_TYPE).includes(currencyToCheck));
    setIsValid(valid);
    if (valid) {
      setPriceCurrency({
        ...priceCurrency,
        price: priceToCheck, currency: currencyToCheck
      });
    }
    return valid;
  };

  const { mutate: savePrice, isLoading: isQuotePriceUpdating } = useQuoteUpdatePriceMutation(queryClient, {
    onSuccess: () => {
      toast.success('Quote price updated');
      setIsEditable(false);
    },
  });

  const { mutate: saveMargin, isLoading: isQuoteMarginUpdating } = useQuoteUpdateMarginMutation(queryClient, {
    onSuccess: () => {
      toast.success('Quote margin updated');
      setIsEditable(false);
    },
  });

  const inputFieldId: string = quote.status === QUOTE_STATUS.WON ? 'margin' : 'price';
  const validationSchema = quote.status === QUOTE_STATUS.WON ? getMarginValidationSchema(quote) : getValidationSchema;
  const label = quote.status === QUOTE_STATUS.WON ? formatQuoteMarginLabel(quote.cost_type) : formatQuotePriceLabel(quote.cost_type);
  const handleClickEventResolver = quote.status === QUOTE_STATUS.WON ? (() => saveMargin({ id: quote.id, margin: formRef.current.values.margin })) : (() => savePrice({ id: quote.id, price: priceCurrency.price, currency: priceCurrency.currency, cost_type: quote.cost_type }));

  return (
    <Stack direction='row' alignItems='end' spacing={2} className={classes.priceContainerWidth}>
      <Formik
        onSubmit={() => null}
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        validateOnChange
        innerRef={formRef}>
        {({ dirty, setFieldValue }) => (
          <Stack direction='row' spacing={2} className={classes.flexEnd}>
            <Stack direction='column'>
              <Typography variant='h4' className={classes.title}>{label}</Typography>
              <Stack className={classes.currencyContainer} direction='row'>
                <InputField
                  id={inputFieldId}
                  type='number'
                  autoFocus
                  startIcon={priceCurrency.currency === CURRENCY_TYPE.CAD ? 'C$' : '$'}
                  reserveErrorMsgSpace
                  onChange={(v) => {
                    if (v ==='') {
                      setIsValid(false);
                      return;
                    }
                    if (quote.status === QUOTE_STATUS.WON ? handleValidationMargin(v) : handleValidationPriceCurrency(Number(v), priceCurrency.currency)) {
                      setFieldValue(quote.status === QUOTE_STATUS.WON ? 'margin': 'price', Number(v));
                    }
                  }}
                  endIcon={
                    quote.status === QUOTE_STATUS.WON ? quote.currency :
                      <InputAdornment position='end'>
                        <Select
                          id='currency'
                          variant='standard'
                          defaultValue={priceCurrency.currency}
                          disableUnderline
                          onChange={(value) => {
                            handleValidationPriceCurrency(priceCurrency.price, value.target.value as string);
                            setFieldValue('currency', value.target.value);
                          }}
                          IconComponent={KeyboardArrowDownIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            autoFocus: true,
                            getContentAnchorEl: null,
                            classes: {
                              paper: classes.selectPaper
                            }}}>
                          {Object.values(CURRENCY_TYPE).map(currency => <MenuItem key={currency} value={currency}> {currency} </MenuItem>)}
                        </Select>
                      </InputAdornment>
                  }
                  className={clsx({
                    [classes.priceMarginResolverTitle]: quote.status === QUOTE_STATUS.OPEN
                  })}/>
                <Stack direction='column' className={classes.quotePriceButtonWrapper}>
                  <Stack direction='row' spacing={1} className={classes.margin0}>
                    <ActionButton
                      text={<CheckIcon />}
                      isLoading={isQuotePriceUpdating || isQuoteMarginUpdating}
                      disabled={ !(isValid && dirty) || (isQuotePriceUpdating || isQuoteMarginUpdating)}
                      handleClick={handleClickEventResolver} />
                    <ActionButton
                      text={<XMark />}
                      variant='secondary'
                      disabled={isQuotePriceUpdating || isQuoteMarginUpdating}
                      handleClick={() => setIsEditable(false)} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Formik>
    </Stack >);
};