import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  container: {
    ...theme.typography.bodyRegularS,
    padding: '0.5rem 0rem',
    verticalAlign: 'center'
  },
  secondaryText: {
    color: theme.palette.neutral.neutral400,
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.4rem',
    width: '100%',
    '& button': {
      ...theme.typography.bodyRegularXS,
      backgroundColor: theme.palette.neutral.neutral100,
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: theme.palette.primary.primary200
      }
    },
    '& ul': {
      gap: '2px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.primary700,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.primary200,
        color: theme.palette.common.black,
      }
    }
  },
}));