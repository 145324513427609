import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyle = makeStyles((theme: ThemeDefault) => ({
  blueText: {
    color: theme.palette.primary.primary700,
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-end',
    marginTop: '3rem',
    width: '100%',
  },
}));
