import React from 'react';

import { SelectField } from 'components';
import { LOST_STATUS_LABELS, REJECTION_STATUS_LABELS } from 'enums';

export const LostReasonField = () => {
  const reasons = Object.entries(LOST_STATUS_LABELS).map(reason => {
    return { id: reason[0], label: reason[1] };
  }).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectField id='reason' label='Select reason' options={reasons} />
  );
};

export const RejectionReasonField = () => {
  const reasons = Object.entries(REJECTION_STATUS_LABELS).map(reason => {
    return { id: reason[0], label: reason[1] };
  }).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectField id='reason' label='Select reason' options={reasons} />
  );
};