import React, { useContext, useState, useEffect } from 'react';
import { auth, googleProvider } from '../../../firebase';

import { useGetCurrentUserQuery } from 'services';
import { DEFAULT_FILTER_VALUES } from 'enums';

const PASSWORD = 'password';
const GOOGLE = 'google.com';

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [ initialized, setInitialized ] = useState(false);
  const [ googleUnAuthMsg, setGoogleUnAuthMsg ] = useState('');
  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState();

  const { data: currentUserData } = useGetCurrentUserQuery(loggedIn);

  useEffect(() => {
    if (currentUserData?.data) {
      setCurrentUser(currentUserData.data);
      setInitialized(true);
    }
  }, [currentUserData]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user && user.providerData[0].providerId.includes(GOOGLE) && checkDomain(user.email)) {
        setGoogleUnAuthMsg('');
        setLoggedIn(true);
      } else if (user && user.providerData[0].providerId.includes(PASSWORD)) {
        setGoogleUnAuthMsg('');
        setLoggedIn(true);
      } else {
        removeUnauthorisedGoogleUser(user);
        setLoggedIn(false); // added to trigger a page rerender
        setInitialized(true);
      }

    });

    if (!localStorage.getItem('appliedFilters')) {
      localStorage.setItem('appliedFilters', JSON.stringify(DEFAULT_FILTER_VALUES));
    }

    return unsubscribe;
  }, []);

  const checkDomain = (email) => {
    return window.REACT_APP_ALLOWED_DOMAINS.includes(email.split('@')[1]);
  };

  const removeUnauthorisedGoogleUser = async (user) => {
    if (user && !checkDomain(user.email) && user.providerData[0].providerId.includes(GOOGLE)) {
      await auth.currentUser.delete();
      setGoogleUnAuthMsg('You don\'t have access to this page');
    }
    if (user && checkDomain(user.email)) {
      setGoogleUnAuthMsg('');
    }
  };

  const login = async (email, password) => {
    setGoogleUnAuthMsg('');
    const credentials = await auth.signInWithEmailAndPassword(email, password);
    localStorage.setItem('accessToken', credentials.user.za);
    localStorage.setItem('appliedFilters', JSON.stringify(DEFAULT_FILTER_VALUES));
    return credentials;
  };

  const logout = () => {
    setLoggedIn(false);
    setCurrentUser(null);
    localStorage.setItem('appliedFilters', JSON.stringify(DEFAULT_FILTER_VALUES));
    return auth.signOut();
  };

  const loginGoogle = () => {
    return auth.signInWithPopup(googleProvider);
  };

  const value = {
    currentUser,
    googleUnAuthMsg,
    initialized,
    login,
    logout,
    loginGoogle
  };

  return (
    <AuthContext.Provider value={value}>
      {initialized && children}
    </AuthContext.Provider>
  );
};
