import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  baseBoxStyle: {
    transition: 'max-height 0.25s ease-in',
    overflow: 'hidden'
  },
  iconBase: {
    color: theme.palette.primary.primary700,
    fontSize: '.8rem',
    transition: 'all 0.2s ease-in'
  },
  iconRotationClosed: {
    transform: 'rotate(90deg)'
  },
  iconRotationOpen: {
    transform: 'rotate(-90deg)'
  },
  removeMaxHeight: {
    maxHeight: 0
  },
  setMaxHeight: {
    maxHeight: '500px'
  },
  showStopsText: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '.5rem',
    paddingTop: '0.5rem',
    width: 'fit-content',
    '& p': {
      ...theme.typography.bodyMediumXS,
      color: theme.palette.primary.primary700,
      width: 'fit-content',
    }
  },
}));