import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './Collapse.css';

type CollapseProps = {
  children: ReactNode,
  showText: string,
  hideText: string,
};
export const Collapse = ({ children, showText, hideText } : CollapseProps) => {
  const classes = useStyles();
  const [ isOpen, setisOpen ] = useState<boolean>(false);
  return (
    <>
      <Box className={classes.showStopsText} onClick={() => setisOpen(!isOpen)}>
        <Typography>{isOpen ? hideText : showText}</Typography>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={clsx(classes.iconBase, {
            [classes.iconRotationOpen]: isOpen,
            [classes.iconRotationClosed]: !isOpen
          })}/>
      </Box>
      <Box className={clsx(classes.baseBoxStyle, {[classes.setMaxHeight]: isOpen, [classes.removeMaxHeight]: !isOpen})}>
        {isOpen && children}
      </Box>
    </>
  );
};