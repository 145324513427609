import { QUOTE_STATUS, DEFAULT_PAGE_MARGIN } from 'enums';

export const getPriceColor = (quoteStatus) => {
  switch (quoteStatus) {
    case QUOTE_STATUS.WON:
      return '#10B981';
    case QUOTE_STATUS.EXPIRED:
    case QUOTE_STATUS.LOST:
      return '#F87171';
    default:
      return '#111827';
  }
};

export const calculateIndicatorPosition = (parentElement, childElement, indicatorElement) => {
  // we are using this to set position of filter container arrow under the button
  const parentRect = parentElement.getBoundingClientRect();
  const childRect = childElement.getBoundingClientRect();
  const leftSpace = Math.round(childRect.left - parentRect.left + childElement.clientWidth / 2);
  indicatorElement.style.left = `calc(${leftSpace}px - ${DEFAULT_PAGE_MARGIN})`;
};
