import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import { Layout, NavigationButton } from 'components';
import { PAGE_TYPE } from 'enums';
import { Params } from 'types';
import { UploadWorkingCopyForm } from './UploadWorkingCopyForm/UploadWorkingCopyForm';

import { useStyles } from './UploadWorkingCopyPage.css';

type UploadWorkingCopyPageProps = {
  type: string,
};

export const UploadWorkingCopyPage = ({ type }: UploadWorkingCopyPageProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { id: workingCopyId } = useParams<Params>();

  const getPageTitle = () => {
    let title = '';
    switch (type) {
      case PAGE_TYPE.CREATE:
        title = 'Upload Working Copies';
        break;
      case PAGE_TYPE.EDIT:
        title = `Edit Working Copy \u2022 ${workingCopyId || ''}`;
        break;
    }
    return title;
  };

  return (
    <Layout title={<NavigationButton size='small' content='Back' urlTo='/working-copies'/>}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant='h2' className={classes.title}>
            {getPageTitle()}
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UploadWorkingCopyForm onCancel={() => history.push('/working-copies')}/>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
