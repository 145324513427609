export const CUSTOMER_PERMISSIONS = {
  RETRIEVE: 'customer.view_customer',
  CREATE: 'customer.add_customer',
  UPDATE: 'customer.change_customer',
  DELETE: 'customer.delete_customer',
  MERGE: 'customer.merge_customer',
  PRICE_RULES_RETRIEVE: 'customer.view_price_prediction_rules',
  PRICE_RULES_UPDATE: 'customer.change_price_prediction_rules',
  PRICE_RULES_DUPLICATE: 'customer.duplicate_price_prediction_rules',
};

export const GLOBAL_RULE_PERMISSIONS = {
  RULESET_RETRIEVE: 'customer.view_globalrule',
  RULESET_UPDATE: 'customer.change_globalrule',
};