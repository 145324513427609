import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1rem',
    alignItems: 'center'
  },
  headerButton: {
    '& .MuiButton-label': {
      '& .MuiButton-startIcon': {
        '& svg': {
          '& path': {
            fill: theme.palette.neutral.neutral0,
          },
        }
      }
    }
  }
}));