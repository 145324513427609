import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  searchBar: {
    border: '1px solid #ccc',
    borderRadius: 5,
    height: 54,
    minHeight: '3rem',
    boxShadow: 'none',
    '&:focus-within': {
      borderColor: '#0d6ba4',
    },
    '&:hover:not(:focus-within)': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  }
});