import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { FilterSetUpsertPayload, FilterParamsType, FilterSet, Opts } from 'types';
import { API_URL } from '../config';

const FILTER_SETS_QUERY_KEY = 'filters';
const FILTER_SETS_API_URL = '/v1/filters';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  filterSets: {
    list: (filterSource: number) => axios.get(`${API_URL}${FILTER_SETS_API_URL}/?filter_source=${filterSource}`),
    create: (payload: FilterSetUpsertPayload) => axios.post(`${API_URL}${FILTER_SETS_API_URL}/`, payload),
    update: ({id, payload} : {id: number, payload: FilterSetUpsertPayload}) => axios.put(`${API_URL}${FILTER_SETS_API_URL}/${id}/`, payload),
    delete: (id: number) => axios.delete(`${API_URL}${FILTER_SETS_API_URL}/${id}/`),
    overwrite: (payload: { target_filter_set: number, source_filter_values: FilterParamsType }) => axios.post(`${API_URL}${FILTER_SETS_API_URL}/overwrite/`, payload),
  }
};

export const useFilterSetsQuery = (filterSource: number) => {
  return useQuery<AxiosResponse<FilterSet[]>>([ FILTER_SETS_QUERY_KEY, {filterSource}],
                                              () => api.filterSets.list(filterSource)
  );
};

export const useFilterSetCreateMutation = (queryClient: QueryClient, opts: Opts<FilterSet, {error: string}>) =>
  useMutation(api.filterSets.create, {
    onSuccess: response => {
      opts.onSuccess(response);
      queryClient.invalidateQueries(FILTER_SETS_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useFilterSetUpdateMutation = (queryClient: QueryClient, opts: Opts<FilterSet, {error: string}>) =>
  useMutation(api.filterSets.update, {
    onSuccess: response => {
      opts.onSuccess(response);
      queryClient.invalidateQueries(FILTER_SETS_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDeleteFilterSetMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.filterSets.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(FILTER_SETS_QUERY_KEY);
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useOverwriteFilterSetMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.filterSets.overwrite, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(FILTER_SETS_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });
