import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
    '&$error': {
      marginBottom: 12,
      '&::first-letter': {
        textTransform: 'uppercase'
      }
    }
  },
  error: {},
  formControl: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '90px'
  },
  selectContainer: {
    height: '2.5rem',
    width: '13rem',
    '& .MuiSelect-root': {
      padding: '0.5rem 1rem',
    },
  },
  select: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.primary200}`,
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral700,
    height: '100%',
    width: '-webkit-fill-available',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        borderWidth: 1,
      },
    },
    '& .MuiInputBase-input': {
      padding: '0.5rem 2rem 0.5rem 1rem',
    },
    '& svg': {
      padding: '0 1rem',
    }
  },
  selectPaper: {
    ...theme.typography.bodyRegularS,
    border: `1px solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    marginTop: '0.75rem',
    maxHeight: 'calc(100vh / 2)',
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
    '& ul': {
      padding: 0,
    },
    '& li': {
      ...theme.typography.bodyRegularS,
      padding: '0.5rem 1rem',
    },
  },
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
}));