import React, { useMemo } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';

import { ActionButton } from 'components';
import { FormCustomer, FormFuelSheet, FormQuote } from 'types';

import { useFieldStyles } from 'components/Modals/fields.css';
import { useStyles } from './FormModalContent.css';

type FormModalContentProps<T> = {
   initialValues: unknown,
   validationSchema?: unknown,
   innerRef?: React.MutableRefObject<FormikProps<FormQuote | FormCustomer | FormFuelSheet>>,
   children: ((context: FormikProps<T>) => React.ReactNode[] | React.ReactNode ) | React.ReactNode,
   isActionInProgress?: boolean,
   primaryButtonLabel?: string,
   validateOnMount?: boolean,
   validateOnChange?: boolean,
   noValidate?: boolean,
   onCancel: () => void,
   onSubmit?: (payload: unknown) => void,
   enableDivider?: boolean,
   enableReinitialize?: boolean
}

export const FormModalContent = <T, >({
  initialValues,
  validationSchema,
  validateOnMount,
  validateOnChange,
  noValidate = false,
  innerRef,
  children,
  isActionInProgress,
  primaryButtonLabel = 'Confirm',
  enableDivider = true,
  enableReinitialize = true,
  onCancel,
  onSubmit = null
}: FormModalContentProps<T>) => {
  const classes = useStyles();
  const fieldClasses = useFieldStyles();
  const isBrowseMode: boolean = useMemo(() => onSubmit === null, [onSubmit]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      validateOnChange={validateOnChange}
      enableReinitialize={enableReinitialize}
      innerRef={innerRef}
      onSubmit={onSubmit}>
      {(context) => (
        <Form className={fieldClasses.contentForm} noValidate={noValidate}>
          {typeof children === 'function' ? children(context as FormikProps<T>) : children}
          {enableDivider && <Divider className={classes.divider} />}
          { !isBrowseMode &&
          <Grid container justify='flex-end' className={fieldClasses.actions}>
            <Grid item>
              <ActionButton handleClick={onCancel} variant='secondary' text='Cancel' />
            </Grid>
            <Grid item>
              <ActionButton
                type='submit'
                variant='primary'
                isLoading={isActionInProgress}
                disabled={isBrowseMode || !context.isValid || isActionInProgress}
                text={primaryButtonLabel}/>
            </Grid>
          </Grid>
          }
        </Form>
      )}
    </Formik>
  );
};