import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Divider, Grid, Link } from '@mui/material';
import clsx from 'clsx';

import { CollapsibleWidget, CreateUpdateRuleModal, ModalComponent, SadStates, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { usePricingRulesEditorConfigQuery } from 'services';
import { ActionLog, ActionType, ContexValueType, QuoteExecutionLogData, Rule, RulesetExecutionLog} from 'types';

import { useStyles } from './RulesExecutionLog.css';

type RulesExecutionLogProps = Omit<RulesetExecutionLog<QuoteExecutionLogData>, 'created_at' | 'title' | 'result' | 'additional_data'>;

export const RulesExecutionLog = ({ruleset= null, triggered_rules, rule_action_logs = null} : RulesExecutionLogProps) => {
  const classes = useStyles();
  const [ selectedRule, setSelectedRule ] = useState<Rule>(null);

  const { isError, isLoading, isSuccess, data } = usePricingRulesEditorConfigQuery();
  const { data: ruleEditorConfiguration } = data || {};

  const operandMap: Map<string, string> = useMemo(() => {
    return (ruleEditorConfiguration?.operands ?? []).reduce((map, operand) => {
      map.set(operand.name, operand.display_name);
      return map;
    }, new Map<string, string>());

  }, [ruleEditorConfiguration]);

  const getActionDescription = (actionLog: ActionLog) => {

    const formatValue = (value: ContexValueType) => {
      if (typeof value === 'number') {
        value = Math.round(value);
      }
      return value;
    };

    if (actionLog === null) {
      return '';
    }
    let displayName = 'Unknown Action';
    const context_change = actionLog.context_change;
    switch (actionLog.action_type) {
      case ActionType.SETTER:
        displayName = `Set ${operandMap.get(context_change?.field_name) || context_change?.field_name} to $${formatValue(context_change?.new_value)}`;
        break;
      case ActionType.STOP_RULE_GROUP_EXECUTION:
        displayName = 'Rule group execution ended';
        break;
      case ActionType.STOP_RULESET_EXECUTION:
        displayName = 'Rule execution ended';
        break;
      case ActionType.STOP_RULESET_EXECUTION_NO_REPLY:
        displayName = 'No Reply';
        break;
      case ActionType.STOP_RULESET_EXECUTION_REJECT:
        displayName = 'Reject';
        break;
    }
    return displayName;
  };

  const getTriggredInfo = (rule: Rule) => {
    let actionDescription = '/';
    let triggredIcon = faTimesCircle;

    for (const item of triggered_rules) {
      if (item as unknown as string === rule.id) {
        if (rule_action_logs && rule_action_logs[rule.id]) {
          if (rule_action_logs[rule.id].length === 0) {
            actionDescription = getActionDescription(rule_action_logs[rule.id][0]);
          } else {
            actionDescription = rule_action_logs[rule.id]?.map((rule_action_log) => (
              getActionDescription(rule_action_log)
            )).join(', ');
          }
        } else {
          actionDescription = 'No rule action logs avalable';
        }
        triggredIcon = faCheckCircle;
        break;
      }
    }

    return (
      <Grid
        container spacing={1}
        direction='row'
        className={classes.container}>
        <Grid item xs={10}>{actionDescription}</Grid>
        <Grid item xs={2}><FontAwesomeIcon icon={triggredIcon} className={clsx({
          [classes.icon]: true,
          [classes.triggered]: triggredIcon === faCheckCircle,
          [classes.notTriggered]: triggredIcon === faTimesCircle,
        })}/>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CollapsibleWidget headerTitle='Rules'>
        <SadStates states={[
          {
            when: ruleset === null,
            render: () => <WidgetNoResultsState message='No rules available.'/>
          },
          {
            when: isSuccess && Object.keys(ruleEditorConfiguration).length === 0,
            render: () => <WidgetNoResultsState message='No rules editor config available.'/>
          },
          {
            when: isError,
            render: () => <WidgetNoResultsState message='Something went wrong when trying to get rules editor config.'/>
          },
        ]}>
          <Grid
            container spacing={1}
            direction='row'
            className={clsx({
              [classes.container]: true,
              [classes.secondaryText]: true,
            })}>
            <Grid item xs={6}>Name</Grid>
            <Grid item xs={6}>Action</Grid>
          </Grid>
          <Divider/>
          <SadStates states={[
            {
              when: isLoading,
              render: () => <WidgetLoadingState/>
            },
          ]}>
            <Grid className={classes.scrollableContent}>
              {
                ruleset?.map((rule) => (
                  <div key={rule.id}>
                    <Grid container spacing={1} direction='row' className={classes.container}>
                      <Grid item xs={6} className={classes.text}>
                        <Link onClick={() => setSelectedRule(rule)} className={classes.link} underline='none'>{rule.name}</Link>
                      </Grid>
                      <Grid item xs={6}>
                        {getTriggredInfo(rule)}
                      </Grid>
                    </Grid>
                    <Divider/>
                  </div>
                ))
              }
            </Grid>
          </SadStates>
        </SadStates>
      </CollapsibleWidget>
      <ModalComponent
        message={`Show Rule \u2022 ${selectedRule?.name ?? ''}`}
        isOpen={!!selectedRule}
        modalWidth='xl'
        onCancel={() => {
          setSelectedRule(null);
        }}
        disableEnforceFocus={true}>
        {<CreateUpdateRuleModal rules={ruleset} rule={selectedRule} ruleEditorConfiguration={ruleEditorConfiguration} onCancel={() => setSelectedRule(null)} />}
      </ModalComponent>
    </>
  );
};