import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import { FieldArrayRenderProps, FormikErrors } from 'formik';
import { DraggableProvided } from 'react-beautiful-dnd';

import { LocationSearch } from 'components';
import { STOP_ACTIVITY_TYPES } from 'enums';
import { FormQuote, InitialStop, Location, Stop } from 'types';

import { useStyles } from './StopDisplay.css';

type StopDisplayProps = {
  errors: FormikErrors<FormQuote>,
  setErrors: (errors: FormikErrors<FormQuote>) => void,
  values: FormQuote,
  setFieldValue: (field: string, value: {stops: (Stop | InitialStop)[]}, shouldValidate?: boolean) => void,
  provided: DraggableProvided,
  activity_type: number,
  index: number,
  fields: FieldArrayRenderProps
};

export const StopDisplay = ({errors, setErrors, values, setFieldValue, provided, activity_type, index, fields } : StopDisplayProps) => {
  const label = activity_type === STOP_ACTIVITY_TYPES.PICKUP ? 'Pickup Location' : 'Delivery Location';
  const error = errors['lane'] && typeof errors['lane'] === 'object' && errors['lane']['stops'] === 'object' ? errors['lane']['stops'][index] : null;
  let errorText;
  if (error && typeof error === 'string') {
    errorText = error;
  } else {
    errorText = ' ';
  }
  const disabledDeleteStop = !!error || values?.lane?.stops?.length === 2;
  const classes = useStyles({ disabledDelete: disabledDeleteStop});

  const onDelete = () => {
    if (disabledDeleteStop) {
      return;
    }
    fields.remove(index);
    setErrors({...errors});
  };

  return (
    <Stack
      direction='column'
      ref={provided.innerRef}
      {...provided.draggableProps}>
      <Stack direction='row' className={classes.fullWidth}>
        {provided &&
          <Stack {...provided.dragHandleProps} className={classes.icon}>
            <DragIndicatorIcon className={classes.dragIndicatorIcon}/>
          </Stack>}
        <FormControl error={!!error} fullWidth>
          <LocationSearch
            defaultValue={values.lane.stops[index].address}
            label={label}
            alignedLabel={false}
            hasError={!!error}
            onSelect={(location: Location) => {
              const newFields = [...values.lane.stops];
              Object.keys(location).map(key => {
                newFields[index][`${key}`] = location[key];
              });
              setFieldValue('lane', {
                stops: newFields
              });
            }} />
          {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
        <Stack className={clsx(classes.icon, classes.deleteIconContainer)}>
          <FontAwesomeIcon icon={faTrashAlt} onClick={onDelete} />
        </Stack>
      </Stack>
      <Stack className={classes.fullWidth}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.lane.stops[index].drop_trailer}
              onChange={(_, checked) => {
                const newFields = [...values.lane.stops];
                newFields[index].drop_trailer = checked;
                setFieldValue('lane', {
                  stops: newFields
                });
              }}
              icon={<CheckBoxOutlineBlankTwoToneIcon fontSize='small' viewBox='3 3 18 18' />} />
          }
          label='Drop Trailer'
          className={classes.dropTrailerLabel}/>
      </Stack>
    </Stack>
  );
};
