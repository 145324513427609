import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { AddAwardLaneToAward, DeleteAwardLanes, Opts, PaginatedQueryResult, Award, AwardLane, AwardLaneDetail, UpdateAward, UpdateAwardLane, DeleteAwards } from 'types';

import { API_URL } from '../config';

const AWARDS_API_URL = '/v1/awards';
const AWARDS_QUERY_KEY = 'awards';
const AWARD_LANES_QUERY_KEY = 'award-lanes';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  awards: {
    list: (filters: string, size: number, page: number, ordering: string) => axios.get(`${API_URL}${AWARDS_API_URL}/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
    get: (id: number) => axios.get(`${API_URL}${AWARDS_API_URL}/${id}`),
    bulkDelete: (payload: DeleteAwards) => axios.post(`${API_URL}${AWARDS_API_URL}/delete-multiple/`, payload),
    update: ({ id, payload }: { id:number, payload: UpdateAward }) => axios.put(`${API_URL}${AWARDS_API_URL}/${id}/`, payload),
    getLaneById: (awardId: number, awardLaneId: number) => axios.get(`${API_URL}${AWARDS_API_URL}/${awardId}/lanes/${awardLaneId}`),
    getLanes: (awardId: number, filters?: string, size?: number, page?: number, ordering?: string) => axios.get(`${API_URL}${AWARDS_API_URL}/${awardId}/lanes/?${filters}&limit=${size}&offset=${page}&ordering=${ordering}`),
    updateLane: ({ awardId, awardLaneId, payload }: { awardId: number, awardLaneId: number, payload: UpdateAwardLane }) => axios.post(`${API_URL}${AWARDS_API_URL}/${awardId}/lanes/${awardLaneId}/update/`, payload),
    deleteLanes: ({ awardId, payload }: { awardId: number, payload: DeleteAwardLanes }) => axios.post(`${API_URL}${AWARDS_API_URL}/${awardId}/lanes/delete/`, payload),
    addAwardLaneToAwardSheet: ({ awardId, payload }: { awardId: number, payload: AddAwardLaneToAward }) => axios.post(`${API_URL}${AWARDS_API_URL}/${awardId}/add-lane/`, payload)
  }
};

export const useAwardsQuery = (filters: string, size: number, page: number, ordering: string, queryOptions = {}) => {
  return useQuery<PaginatedQueryResult<Award>>([ AWARDS_QUERY_KEY, { filters, size, page, ordering }],
                                               () => api.awards.list(filters, size, page, ordering),
                                               { ...queryOptions, enabled: !!filters, keepPreviousData: true }
  );
};

export const useGetAwardLaneById = (awardId: number, awardLaneId: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<AwardLaneDetail>>([ AWARD_LANES_QUERY_KEY, awardId, awardLaneId ], () => api.awards.getLaneById(awardId, awardLaneId), { ...queryOptions, enabled: !!awardId && !!awardLaneId });
};

export const useAwardLanesQuery = (awardId: number, filters: string, size: number, page: number, ordering: string, queryOptions = {}) => {
  return useQuery<PaginatedQueryResult<AwardLane>>([ AWARD_LANES_QUERY_KEY, { awardId, filters, size, page, ordering }],
                                                   () => api.awards.getLanes(awardId, filters, size, page, ordering),
                                                   { ...queryOptions, enabled: !!awardId, keepPreviousData: true }
  );
};

export const useGetAwardById = (id: number, queryOptions = {}) => {
  return useQuery<AxiosResponse<Award>>([ AWARDS_QUERY_KEY, id ], () => api.awards.get(id), { ...queryOptions, enabled: !!id });
};

export const useDeleteAwardsMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.awards.bulkDelete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(AWARDS_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useEditAwardMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.awards.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(AWARDS_QUERY_KEY);
      opts.onSuccess();
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useUpdateAwardLaneMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.awards.updateLane, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(AWARD_LANES_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useDeleteAwardLanesMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.awards.deleteLanes, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(AWARD_LANES_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useAddAwardLaneToAwardMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.awards.addAwardLaneToAwardSheet, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(AWARD_LANES_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });