import * as Yup from 'yup';

import { VALIDATION_REQUIRED_MSG } from 'enums';
import Reference from 'yup/lib/Reference';

const removeEmptyRateTypeValues = (field1: number, field2: number, field3: number) => {
  const fieldArray = [ field1, field2, field3 ];
  const fieldValues = fieldArray.filter(element => {
    return element !== null && element !== undefined;
  });
  return fieldValues;
};

export const getUpdateAwardLaneValidationSchema = () => {
  const getRateTypeValidationSchema = Yup.object().shape({
    equipment: Yup.string().required(VALIDATION_REQUIRED_MSG),
    estimated_miles: Yup.number().
      positive('Estimated miles must be a positive number').
      nullable(),
    estimated_volume: Yup.number()
      .positive('Estimated volume must be a positive number')
      .integer('Estimated volume must be an integer value')
      .nullable(),
    min_rate: Yup.number()
      .positive('Min rate value must be a positive number')
      .nullable()
      .when('max_rate', {
        is: (value: number) => value > 0,
        then: schema => schema.lessThan(Yup.ref('max_rate') as Reference<number>, 'Min rate should be lower then max rate')
      }),
    max_rate: Yup.number()
      .positive('Max rate value must be a positive number')
      .nullable()
      .when('min_rate', {
        is: (value: number) => value > 0,
        then: schema => schema.moreThan(Yup.ref('min_rate') as Reference<number>, 'Max rate should be greater then min rate')
      }),
    line_haul: Yup.number().positive('Line haul value must be a positive number').nullable().test({
      name: 'onlyOneRequired',
      test: function(value) {
        const fieldValues = removeEmptyRateTypeValues(value, this.parent.rpm, this.parent.flat_rate);
        if (fieldValues.length === 1) {
          return true;
        }
        return this.createError({
          path: 'line_haul',
          message: VALIDATION_REQUIRED_MSG,
        });
      }
    }),
    rpm: Yup.number().positive('RPM value must be a positive number').nullable().test({
      name: 'onlyOneRequired',
      test: function(value) {
        const fieldValues = removeEmptyRateTypeValues(value, this.parent.line_haul, this.parent.flat_rate);
        if (fieldValues.length === 1) {
          return true;
        }
        return this.createError({
          path: 'rpm',
          message: VALIDATION_REQUIRED_MSG,
        });
      }
    }),
    flat_rate: Yup.number().positive('Flat rate value must be a positive number').nullable().test({
      name: 'onlyOneRequired',
      test: function(value) {
        const fieldValues = removeEmptyRateTypeValues(value, this.parent.line_haul, this.parent.rpm);
        if (fieldValues.length === 1) {
          return true;
        }
        return this.createError({
          path: 'flat_rate',
          message: VALIDATION_REQUIRED_MSG,
        });
      }
    }),
  }, [
    [ 'min_rate', 'max_rate' ]
  ]);

  return getRateTypeValidationSchema;
};

export const getAddAwardLaneToAwardValidationSchema = () => Yup.object().shape({
  lane: Yup.object().shape({
    stops: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          activity_type: Yup.number(),
          address: Yup.string().required(VALIDATION_REQUIRED_MSG)
        }).test({
          name: 'selectError',
          message: 'An error occured while selecting the location.',
          test: value => {
            const fieldsNotProvidedInGeoCode = ['facility'];
            return Object.keys(value).filter(property => !fieldsNotProvidedInGeoCode.includes(property))
              .every(element => value[element] !== undefined);
          }
        })
      )
  }),
  equipment: Yup.string().required(VALIDATION_REQUIRED_MSG),
  estimated_miles: Yup.number().
    positive('Estimated miles must be a positive number').
    nullable(),
  estimated_volume: Yup.number()
    .positive('Estimated volume must be a positive number')
    .integer('Estimated volume must be an integer value')
    .nullable(),
  min_rate: Yup.number()
    .positive('Min rate value must be a positive number')
    .nullable()
    .when('max_rate', {
      is: (value: number) => value > 0,
      then: schema => schema.max(Yup.ref('max_rate') as Reference<number>, 'Min rate should be lower then max rate')
    }),
  max_rate: Yup.number()
    .positive('Max rate value must be a positive number')
    .nullable()
    .when('min_rate', {
      is: (value: number) => value > 0,
      then: schema => schema.min(Yup.ref('min_rate') as Reference<number>, 'Max rate should be greater then min rate')
    }),
  line_haul: Yup.number().positive('Line haul value must be a positive number').nullable().test({
    name: 'onlyOneRequired',
    test: function (value) {
      const fieldValues = removeEmptyRateTypeValues(value, this.parent.rpm, this.parent.flat_rate);
      if (fieldValues.length === 1) {
        return true;
      }
      return this.createError({
        path: 'line_haul',
        message: VALIDATION_REQUIRED_MSG,
      });
    }
  }),
  rpm: Yup.number().positive('RPM value must be a positive number').nullable().test({
    name: 'onlyOneRequired',
    test: function (value) {
      const fieldValues = removeEmptyRateTypeValues(value, this.parent.line_haul, this.parent.flat_rate);
      if (fieldValues.length === 1) {
        return true;
      }
      return this.createError({
        path: 'rpm',
        message: VALIDATION_REQUIRED_MSG,
      });
    }
  }),
  flat_rate: Yup.number().positive('Flat rate value must be a positive number').nullable().test({
    name: 'onlyOneRequired',
    test: function (value) {
      const fieldValues = removeEmptyRateTypeValues(value, this.parent.line_haul, this.parent.rpm);
      if (fieldValues.length === 1) {
        return true;
      }
      return this.createError({
        path: 'flat_rate',
        message: VALIDATION_REQUIRED_MSG,
      });
    }
  }),
}, [
  [ 'min_rate', 'max_rate' ]
]);
