import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation, QueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { API_URL } from '../config';
import { FormFuelSheet, FuelSheet, FuelSheetDetail, Opts, PaginatedQueryResult } from 'types';

const FUEL_SHEETS_QUERY_KEY = 'fuel-sheet';
const FUEL_SHEETS_API_URL = '/v1/fuel-sheets';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  fuelSheets: {
    get: (id: number) => axios.get(`${API_URL}${FUEL_SHEETS_API_URL}/${id}`),
    list: (id: number, size: number, page: number) => axios.get(`${API_URL}${FUEL_SHEETS_API_URL}/?customer=${id}&limit=${size}&offset=${page}`),
    delete: (ids: number | number[]) => axios.post(`${API_URL}${FUEL_SHEETS_API_URL}/delete_multiple/?fuel-sheets=${ids}`),
    update: ({id, region, fuel_price_delay} : FormFuelSheet) => axios.patch(`${API_URL}${FUEL_SHEETS_API_URL}/${id}/`, {region, fuel_price_delay}),
  }
};

export const useGetFuelSheetDetails = (fuelSheetId: number) => {
  return useQuery<AxiosResponse<FuelSheetDetail>>([ FUEL_SHEETS_QUERY_KEY, fuelSheetId ], () => api.fuelSheets.get(fuelSheetId), { enabled: !!fuelSheetId });
};

export const useGetFuelSheetsByCustomerId = (customerId: number, size: number, page: number, queryOptions = {}) => {
  return useQuery<PaginatedQueryResult<FuelSheet>>([ FUEL_SHEETS_QUERY_KEY, customerId, size, page ], () => api.fuelSheets.list(customerId, size, page), { ...queryOptions, enabled: !!customerId, });
};

export const useDeleteFuelSheetsMutation = (queryClient: QueryClient, opts: Opts<unknown, {error: string}>) =>
  useMutation(api.fuelSheets.delete, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(FUEL_SHEETS_QUERY_KEY);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });

export const useUpdateFuelSheetMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.fuelSheets.update, {
    onSuccess: () => {
      opts.onSuccess();
      queryClient.invalidateQueries(FUEL_SHEETS_QUERY_KEY);
    },
    onError: (error: AxiosError<unknown>) => {
      if (opts.onError) {
        opts.onError(error);
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });