import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  closeButton: {
    backgroundColor: theme.palette.neutral.neutral0,
    borderRadius: '5px',
    height: '1.5rem',
    width: '1.5rem',
    '& .MuiIconButton-root:hover': {
      backgroundColor: theme.palette.neutral.neutral0,
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.875rem',
  },
  dialog: {
    '& .MuiPaper-rounded': {
      borderRadius: 10
    }
  },
  modalTitle: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.875rem',
    backgroundColor: theme.palette.neutral.neutral0,
  },
  titleText: {
    lineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));