import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import { ActionButton, ModalComponent } from 'components';
import { FILE_CATEGORY, SUCCESSFULLY_UPLOADED_FILE_MESSAGE } from 'enums';
import { useImportFileMutation } from 'services';

import { useFieldStyles } from '../fields.css';
import { useStyle } from './ImportMarketStrengthModal.css';

type ImportMarketStrengthModalContentProps = {
    onCancel: () => void
};

const ImportMarketStrengthModalContent = ({ onCancel }: ImportMarketStrengthModalContentProps) => {
  const fieldClasses = useFieldStyles();
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>();
  const [ file, setFile ] = useState<File>(null);
  const { mutate: importFile, isLoading: isImportingFile } = useImportFileMutation({
    onSuccess: () => {
      onCancel();
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files[0]);
  };

  const getMarketStrengthImportFormData = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('type', file.type);
    formData.append('category', String(FILE_CATEGORY.MARKET_STRENGTH));
    return formData;
  };

  const handleImportMarketStrength = async () => {
    if (!file) {
      toast.error('Please select a csv file for import.');
      return;
    }
    importFile(getMarketStrengthImportFormData());
  };

  return (
    <Grid>
      <Grid className={classes.content}>
        <p className={classes.importText}>{file ? file.name : 'Please upload a csv file'}</p>
        <input
          ref={inputRef}
          type='file'
          accept='.csv'
          onChange={handleInputChange}
          style={{ display: 'none' }} />
        <ActionButton
          text='Upload'
          startIcon={<PublishIcon />}
          handleClick={() => inputRef.current.click()} />
      </Grid>
      <Grid container justify='flex-end' className={fieldClasses.actionsOnly}>
        <Grid item>
          <ActionButton variant='secondary' text='Cancel' handleClick={onCancel}/>
        </Grid>
        <Grid item>
          <ActionButton
            text='import'
            handleClick={handleImportMarketStrength}
            disabled={file === null || isImportingFile}
            isLoading={isImportingFile}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

type ImportMarketStrengthModalProps = {
    isModalOpen: boolean,
    onCancel: () => void
};

export const ImportMarketStrengthModal = ({ isModalOpen, onCancel }: ImportMarketStrengthModalProps) => {
  return (
    <ModalComponent
      message='Import Market Strength'
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <ImportMarketStrengthModalContent onCancel={onCancel} />
    </ModalComponent>
  );
};
