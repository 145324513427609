import { AwardLane, Column } from 'types';

export const AwardLanesTableConfig : Column [] = [
  { id: 'origin', label: 'Origin', sortable: true, orderingField: 'origin'},
  {
    id: 'origin_live_handling', label: 'Origin Live/Drop', sortable: true, orderingField: 'origin_live_handling', customRender(awardLane: AwardLane) {
      return awardLane.origin_live_handling ? 'Live' : 'Drop';
    }
  },
  { id: 'destination', label: 'Destination', sortable: true, orderingField: 'destination' },
  {
    id: 'destination_live_handling', label: 'Destination Live/Drop', sortable: true, orderingField: 'destination_live_handling', customRender(awardLane: AwardLane) {
      return awardLane.destination_live_handling ? 'Live' : 'Drop';
    }
  },
  { id: 'equipment_type', label: 'Equipment Type', sortable: true, orderingField: 'equipment_type' },
  { id: 'estimated_miles', label: 'Miles', sortable: true, orderingField: 'estimated_miles'},
  { id: 'estimated_volume', label: 'Volume', sortable: true, orderingField: 'estimated_volume'},
  { id: 'line_haul', label: 'Line Haul', sortable: true, orderingField: 'line_haul'},
  { id: 'rpm', label: 'RPM', sortable: true, orderingField: 'rpm'},
  { id: 'flat_rate', label: 'Flat Rate', sortable: true, orderingField: 'flat_rate'},
  { id: 'min_rate', label: 'Min Rate', sortable: true, orderingField: 'min_rate'},
  { id: 'max_rate', label: 'Max Rate', sortable: true, orderingField: 'max_rate'},
];