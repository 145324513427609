import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Stack } from '@mui/material';

import { STOP_ACTIVITY_TYPES } from 'enums';
import { StoppingPoint } from 'types';

import { useStyles } from './StopsTooltip.css';

type StopsTooltipProps = {
  stops: StoppingPoint[]
}

export const StopsTooltip = ({stops}: StopsTooltipProps) => {
  const classes = useStyles();
  const pickupNumber = stops.filter((stop: StoppingPoint) => stop.activity_type === STOP_ACTIVITY_TYPES.PICKUP).length;
  const deliveryNumber = stops.filter((stop: StoppingPoint) => stop.activity_type === STOP_ACTIVITY_TYPES.DELIVERY).length;
  const sortedStops = stops.sort((a: StoppingPoint, b: StoppingPoint) => a.order - b.order);

  return (
    <Tooltip
      classes={{tooltip: classes.tooltip}}
      placement='bottom-start'
      title={
        sortedStops.map((stop: StoppingPoint, index: number) => {
          const activityType = stop.activity_type === STOP_ACTIVITY_TYPES.PICKUP ? 'pickup' : 'delivery';
          return (
            <Stack direction='row' key={stop.order} className={classes.text}>
              {index+1}. {stop.location.name} (<em>{activityType}</em>)
            </Stack>
          );
        })
      }>
      <Button size='small' variant='outlined' className={classes.stopsButton} onClick={((e) => e.stopPropagation())}>
        <FontAwesomeIcon icon={faArrowUp}/>
        {pickupNumber}
        <FontAwesomeIcon icon={faArrowDown}/>
        {deliveryNumber}
      </Button>
    </Tooltip>
  );
};