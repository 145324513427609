export enum SHIPPER_COST_TYPE {
  ALLIN,
  LINEHAUL,
  LINEHAUL_WITH_FUEL_SURCHARGE,
}

export const SHIPPER_COST_TYPE_LABELS: Record<number, string> = {
  [SHIPPER_COST_TYPE.ALLIN]: 'All In',
  [SHIPPER_COST_TYPE.LINEHAUL]: 'Line Haul',
  [SHIPPER_COST_TYPE.LINEHAUL_WITH_FUEL_SURCHARGE]: 'Line Haul + Fuel Surcharge'
};

export enum SHIPPER_FORM_GROUP {
  GENERAL_INFORMATION,
  CONTACT_INFORMATION,
  SHIPPER_CONFIGURATION,
}

export const SHIPPER_FORM_GROUP_LABELS: Record<number, string> = {
  [SHIPPER_FORM_GROUP.GENERAL_INFORMATION]: 'General information',
  [SHIPPER_FORM_GROUP.CONTACT_INFORMATION]: 'Contact information',
  [SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION]: 'Shipper configuration'
};
