import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

const setterEditorStyles = makeStyles((theme: ThemeDefault) => ({
  'setter-node-wrapper': {
    'margin': '0.5rem',
    marginTop: 0,
    background: theme.palette.primary.primary100,
    border: `1px solid ${theme.palette.primary.primary500}`,
    borderRadius: '0.5rem',
  },
  'setter-node-wrapper__header': {
    'padding': '0.5rem !important',
    'height': '2rem',
  },
  'setter-node-wrapper__subheader': {
    'display': 'flex',
  },
  'setter-node-wrapper__header-control': {
    'backgroundColor': 'white',
    'height': '2rem',
    'minWidth': '10rem',
  },
  'setter-node-wrapper__subheader-content': {
    'width': '10rem',
    'marginLeft': '0.2rem',
  },
  'setter-node-wrapper__children': {
    'margin': '0.5rem !important',
    'marginTop': '0 !important',
    'padding': '0 !important',
    // 'padding': '0.01rem !important',
    // 'backgroundColor': 'white',
    'border-radius': '5px',
  },
  'setter-node-wrapper__body': {},
}));

export default setterEditorStyles;
