import React, { useState, useEffect, useCallback } from 'react';
import { stringify } from 'qs';
import { useHistory, useParams } from 'react-router-dom';

import { AccessControl, ActionButton, DeleteAwardLanesModal, FilterComponent, Layout, TableComponent } from 'components';
import { AWARD_PERMISSIONS } from 'permissions';
import { DeleteIcon } from 'resources';
import { useGetAwardById, useAwardLanesQuery } from 'services';
import { Params, AwardLane, AwardLaneFilter } from 'types';

import { AwardLanesTableConfig } from './components/AwardLanesTableConfig';
import { AwardLanesTableMenu } from './components/AwardLanesTableMenu';

import { FILTER_PAGE_SOURCE } from 'enums';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { NSearch } from 'components/Filter';
import { getPageAppliedFilters, setPageAppliedFilters } from 'helpers';

import { useStyles } from './AwardLanesPage.css';

export const AwardLanesPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.AWARD_LANE);

  const mapSelectedFiltersToQuery = (origin: string, destination: string) => {
    return { origin, destination };
  };

  const [ origin, setOrigin ] = useState<string>(appliedFiltersAndSearch ? appliedFiltersAndSearch.origin : '');
  const [ destination, setDestination ] = useState<string>(appliedFiltersAndSearch ? appliedFiltersAndSearch.destination : '');
  const [ page, setPage ] = useState<number>(0);
  const [ filter, setFilter ] = useState<AwardLaneFilter>(mapSelectedFiltersToQuery(origin, destination) || {});
  const [ ordering, setOrdering ] = useState<string>('');
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(10);
  const [ selectedRows, setSelectedRows ] = useState<AwardLane[]>([]);
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState<boolean>(false);

  const { id } = useParams<Params>();
  const awardId = Number(id);

  useEffect(() => {
    const query = mapSelectedFiltersToQuery(origin, destination);
    debounceFilters(query);
  }, [ origin, destination ]);

  useEffect(() => {
    setPage(0);
    setSelectedRows([]);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce(query => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.AWARD_LANE, null, query);
    }, 300),
    []
  );

  const { isLoading: isAwardLoading, isSuccess: isAwardSuccess, data: AwardData } = useGetAwardById(awardId);
  const { data: award } = AwardData || {};
  const { isFetching: isLanesFetching, isError: lanesError, isSuccess: lanesSuccess, data: lanesData } = useAwardLanesQuery(awardId, stringify(filter), rowsPerPage, page * rowsPerPage, ordering );
  const { data: lanes } = lanesData || {};

  return (
    <Layout goBackTo='/awards' title={isAwardSuccess && `${award.name} - Lanes`}>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.AWARD_LANE}
        showSearch={false}
        showFilters={false}>
        <Grid container className={classes.header}>
          <Grid item className={classes.searchWrapper}>
            <NSearch
              value={origin}
              placeholder='Search for origin'
              handleChange={setOrigin}/>
            <NSearch
              value={destination}
              placeholder='Search for destination'
              handleChange={setDestination}/>
          </Grid>
          <Grid item>
            <AccessControl permissions={[AWARD_PERMISSIONS.ADD_AWARD_LANE_TO_AWARD]}>
              <ActionButton
                text='New lane'
                variant='primary'
                disabled={isAwardLoading}
                isLoading={isAwardLoading}
                handleClick={() => history.push(`/awards/${award?.id}/lanes/create`)}
                startIcon={<FontAwesomeIcon icon={faPlus}/>}
                className={classes.headerButton}/>
            </AccessControl>
          </Grid>
        </Grid>
      </FilterComponent>
      <TableComponent
        columns={AwardLanesTableConfig}
        isLoading={isLanesFetching}
        isError={lanesError}
        isSuccess={lanesSuccess}
        tableData={lanes?.results}
        areRowsSelectable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        page={page}
        rows={lanes?.count}
        pageRows={rowsPerPage}
        setOrdering={ordering => setOrdering(ordering)}
        defaultOrder={'asc'}
        defaultOrderBy={'origin'}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        bulkMenuConfig={[
          {
            icon: DeleteIcon,
            permissions: [AWARD_PERMISSIONS.DELETE_AWARD_LANE],
            label: 'Delete',
            handleClick: () => setIsDeleteModalOpen(true),
          },
        ]}
        renderMenu={(lane: AwardLane) => {
          return (
            <AwardLanesTableMenu lane={lane} awardId={awardId} onActionSucces={() => setSelectedRows([])}/>
          );
        }}/>
      <DeleteAwardLanesModal
        onCancel={() => setIsDeleteModalOpen(false)}
        awardId={award?.id}
        selectedAwardLanes={selectedRows}
        isModalOpen={isDeleteModalOpen}
        onDeleteSuccess={() => setSelectedRows([])}/>
    </Layout>
  );
};