import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  generalInfo: {
    boxSizing: 'border-box',
    flex: 6,
    height: '100%',
    padding: '1rem 1.8rem',
    paddingBottom: 0,
    width: '100%',
  },
  shippersContainer: {
    display: 'flex',
    marginBottom: '1.5rem',
  },
}));