import axios, { AxiosResponse } from 'axios';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { Equipment, Opts } from 'types';
import { API_URL } from '../config';

const EQUIPMENT_QUERY_KEY = 'equipment';
const EQUIPMENT_API_URL = '/v1/equipment';

const UNKNOWN_ERROR_MESSAGE = 'An unknown error occured. Please contact support or try again later.';

export const api = {
  equipment: {
    get: (id: number) => axios.get(`${API_URL}${EQUIPMENT_API_URL}/${id}/`),
    list: () => axios.get(`${API_URL}${EQUIPMENT_API_URL}/`),
    get_equipment_types: () => axios.get(`${API_URL}${EQUIPMENT_API_URL}/equipment_types/`),
    set_equipment_type: ({ id, type } : {id: number, type: string}) => axios.patch(`${API_URL}${EQUIPMENT_API_URL}/${id}/`, { type }),
  }
};

export const useGetEquipmentQuery = () => {
  return useQuery<AxiosResponse<Equipment[]>>([EQUIPMENT_QUERY_KEY], () => api.equipment.list());
};

export const useEquipmentQuery = (id: number) => {
  return useQuery([ EQUIPMENT_QUERY_KEY, id ], () => api.equipment.get(id));
};

export const useGetEquipmentTypesQuery = () => {
  return useQuery<AxiosResponse<Equipment[]>>([EQUIPMENT_QUERY_KEY], () => api.equipment.get_equipment_types());
};

export const useSetEquipmentTypeMutation = (queryClient: QueryClient, opts: Opts) =>
  useMutation(api.equipment.set_equipment_type, {
    onSuccess: () => {
      opts.onSuccess();
    },
    onError: () => {
      if (opts.onError) {
        opts.onError();
      } else {
        toast.error(UNKNOWN_ERROR_MESSAGE);
      }
    }
  });