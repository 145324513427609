import React from 'react';
import { Route } from 'react-router-dom';

import { AccessControl } from '..';

type ProtectedRouteProps = {
  path: string;
  permissions: string[];
  children: React.ReactNode
};

export const ProtectedRoute = ({path, permissions, children}: ProtectedRouteProps) => (
  <Route exact path={path} component={() => <AccessControl permissions={permissions} isPageProtected>{children}</AccessControl>} />
);