import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  select: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral700,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        borderWidth: 1,
      },
      // TODO: Deal with this later
      // '.MuiSelect-nativeInput': {
      // },
    },
  },
  selectPaper: {
    ...theme.typography.bodyRegularS,
    ...scrollStyle as CSSProperties,
    border: `1px solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    marginTop: '0.25rem',
    maxHeight: 'calc(100vh / 2)',
    overflowY: 'scroll',
    padding: 0,
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.primary100,
      color: theme.palette.primary.primary700,
    },
    '& .MuiCheckbox-colorSecondary.Mui-unchecked': {
      color: theme.palette.primary.primary500,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
    '& ul': {
      padding: 0,
    },
    '& li': {
      ...theme.typography.bodyRegularS,
      height: '2.5rem',
    },
  },
  selectMenuItem: {
    ...theme.typography.bodyM,
    height: '2.5rem',
    boxSizing: 'border-box',
    '& .MuiButtonBase-root': {
      padding: 0
    },
    '& svg': {
      fill: theme.palette.primary.primary700,
      marginRight: '.8rem'
    },
    '& .MuiTypography-body1': {
      ...theme.typography.bodyRegularS,
    },
  },
  circuralProgressStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  placeholder: {
    ...theme.typography.bodyMediumS,
    color: theme.palette.neutral.neutral400,
  },
}));
