import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, FuelSheetDetailsModal, ModalComponent, TableMenu } from 'components';
import { FUEL_SHEET_PERMISSIONS } from 'permissions';
import { ArrowIcon, DeleteIcon, Pencil } from 'resources';
import { useDeleteFuelSheetsMutation } from 'services';
import { FuelSheet } from 'types';
import { FuelSheetUpdateForm } from './FuelSheetUpdateForm';

type DeleteFuelSheetProps = {
  fuelSheet: FuelSheet
  onCancel: () => void,
  onDeleteSuccess: () => void
};

const DeleteFuelSheet = ({ fuelSheet, onCancel, onDeleteSuccess } : DeleteFuelSheetProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteFuelSheet, isLoading } = useDeleteFuelSheetsMutation(queryClient, {
    onSuccess: () => {
      onDeleteSuccess();
      onCancel();
      toast.success('Fuel sheet successfully deleted.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
      onCancel();
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={() => deleteFuelSheet(fuelSheet.id)}
      onCancel={onCancel}/>
  );
};

type FuelSheetTableMenuProps = {
  fuelSheet: FuelSheet,
  onActionSuccess: () => void
};

export const FuelSheetTableMenu = ({ fuelSheet, onActionSuccess }: FuelSheetTableMenuProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const [ isViewFuelSheetModalOpen, setIsViewFuelSheetModalOpen ] = useState<boolean>(false);
  const [ isUpdateFuelSheetModalOpen, setIsUpdateFuelSheetModalOpen ] = useState<boolean>(false);
  const [ isDeleteFuelSheetModalOpen, setIsDeleteFuelSheetModalOpen ] = useState<boolean>(false);

  const viewFuelSheetHandler = () => {
    setIsViewFuelSheetModalOpen(true);
    setAnchorEl(null);
  };

  const editFuelSheetHandler = () => {
    setIsUpdateFuelSheetModalOpen(true);
    setAnchorEl(null);
  };

  const deleteFuelSheetHandler = () => {
    setIsDeleteFuelSheetModalOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [FUEL_SHEET_PERMISSIONS.RETRIEVE],
      text: 'More details',
      icon: ArrowIcon,
      onClick: viewFuelSheetHandler,
    },
    {
      permissions: [FUEL_SHEET_PERMISSIONS.UPDATE],
      text: 'Edit',
      icon: Pencil,
      onClick: editFuelSheetHandler,
    },
    {
      permissions: [FUEL_SHEET_PERMISSIONS.DELETE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteFuelSheetHandler,
    },
  ], [fuelSheet]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>

      <ModalComponent
        message='Edit Fuel Sheet'
        isOpen={isUpdateFuelSheetModalOpen}
        onCancel={() => setIsUpdateFuelSheetModalOpen(false)}>
        <FuelSheetUpdateForm fuelSheet={fuelSheet} onCancel={() => setIsUpdateFuelSheetModalOpen(false)} onUpdateSuccess={onActionSuccess}/>
      </ModalComponent>

      <ModalComponent
        message={`Are you sure you want to delete fuel sheet "${fuelSheet.region}"?`}
        isOpen={isDeleteFuelSheetModalOpen}
        onCancel={() => setIsDeleteFuelSheetModalOpen(false)}>
        <DeleteFuelSheet fuelSheet={fuelSheet} onCancel={() => setIsDeleteFuelSheetModalOpen(false)} onDeleteSuccess={onActionSuccess}/>
      </ModalComponent>

      <FuelSheetDetailsModal fuelSheetId={fuelSheet.id} isModalOpen={isViewFuelSheetModalOpen} onCancel={() => {
        setIsViewFuelSheetModalOpen(false);
        onActionSuccess();
      }}/>
    </>
  );
};
