import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  reserveSpace: boolean;
};

export const useStyles = makeStyles<ThemeDefault, Props>((theme: ThemeDefault) => ({
  labelRoot: {
    color: theme.palette.neutral.neutral400,
    marginBottom: '0.5rem',
  },
  labelMedium: {
    ...theme.typography.bodyMediumXS
  },
  labelSmall: {
    ...theme.typography.bodyRegularXS,
  },
  link: {
    '& p': {
      color: theme.palette.primary.primary700
    }
  },
  valueRoot: {
    boxOrient: 'vertical',
    color: theme.palette.common.black,
    display: '-webkit-box',
    height: ({ reserveSpace }: Props) => reserveSpace ? '2.2rem' : 'auto',
    lineClamp: 2,
    marginBottom: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  },
  valueLarge: {
    ...theme.typography.bodyM,
  },
  valueMedium: {
    ...theme.typography.bodyRegularS,
  },
  valueSmall: {
    ...theme.typography.bodyMediumXS,
  },
}));