import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { useFieldStyles } from 'components/Modals/fields.css';
import { ActionButton } from 'components/ActionButton/ActionButton';

type ConfirmationDialogProps = {
    message?: string | React.ReactNode;
    primaryButtonLabel?: string;
    cancelButtonLabel?: string;
    isActionInProgress?: boolean;
    onSubmit: () => void;
    onCancel: () => void;
};

export const ConfirmationDialog = ({
  message,
  onSubmit,
  onCancel,
  primaryButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  isActionInProgress = false,
} : ConfirmationDialogProps) => {
  const fieldClasses = useFieldStyles();

  return (
    <Box>
      { message ? <p className={fieldClasses.content}>{message}</p> : null }
      <Box className={clsx(fieldClasses.removePadding, fieldClasses.actionsOnly)}>
        <ActionButton handleClick={onCancel} variant='secondary' text={cancelButtonLabel} />
        <ActionButton
          handleClick={onSubmit}
          isLoading={isActionInProgress}
          text={primaryButtonLabel}
          disabled={isActionInProgress} />
      </Box>
    </Box>
  );
};