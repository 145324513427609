import { loginBackgroundImg } from 'resources';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  background: {
    height: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(0deg, rgba(5, 100, 179, 0.59) 0%, rgba(5, 100, 179, 0.59) 100%), url(${loginBackgroundImg}), lightgray 50%`
  },
  contentWrapper: {
    padding: '50px 100px',
    borderRadius: 15,
    background: theme.palette.neutral.neutral0,
    [theme.breakpoints.down('xs')]: {
      padding: '50px 30px',
    }
  },
  input: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral700,
    display: 'flex',
    width: 400,
    maxHeight: 40,
    padding: '16px 20px',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #E0E2E7',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
    [theme.breakpoints.down('xs')]: {
      width: 200
    }
  },
  inputHasError: {
    border: `1px solid ${theme.palette.error.main}`,
    background: theme.palette.neutral.neutral0,
  },
  errorMsg: {
    color: theme.palette.error.main,
    ...theme.typography.bodyRegularXS,
    marginTop: 5
  },
  togglePasswordImg: {
    position: 'absolute',
    top: 40,
    right: 20,
    cursor: 'pointer'
  },
  label: {
    ...theme.typography.bodyRegularS,
    marginBottom: 5
  },
  errorPopup: {
    marginTop: 30,
    borderRadius: 8,
    border: `1px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    '& > div.MuiAlert-icon': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '10px 0',
      marginRight: 12,
    },
    '& > div.MuiAlert-action': {
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'flex-start',
      padding: '5px 0',
      marginLeft: 15,
    },
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
    [theme.breakpoints.down('xs')]: {
      width: 200
    }
  },
  errorPopupText: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
  },
  alertWrapper: {
    gap: 10
  },
  loginButton: {
    marginTop: 30,
    width: '100%',
    padding: '15px 20px',
  },
  googleButton: {
    margin: '16px 10px 0 10px',
    padding: '15px 20px',
    width: '100%',
    borderRadius: 2,
    background: theme.palette.neutral.neutral0,
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.17), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)',
  },
  signUpWithGoogleText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    marginLeft: 20,
    textTransform: 'none'
  },
  separator: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 30
  },
  separatorText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400
  },
  dividerStyles: {
    backgroundColor: theme.palette.neutral.neutral400,
    border: '0.5px',
    width: '45%',
    margin: '0 10px'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: 10
  },
  footerText: {
    color: theme.palette.neutral.neutral0,
    textAlign: 'center',
    ...theme.typography.bodyRegularXS
  }
}));