import { TIME_FRAME_TYPES_LABELS } from 'enums';
import { formatDateTime } from 'helpers';
import { Column, WorkingCopy } from 'types';

export const workingCopyTableColumns : Column[] = [
  {id: 'name', label: 'Working Copy Name', sortable: true, orderingField: 'name'},
  {id: 'customer_name', label: 'Shipper', sortable: true, orderingField: 'customer_name'},
  {id: 'round', label: 'Round', sortable: true, orderingField: 'round'},
  {id: 'account_manager_name', label: 'Account Manager', sortable: true, orderingField: 'account_manager_name'},
  {id: 'time_frame', label: 'Time Frame', sortable: true, orderingField: 'time_frame', customRender: (workingCopy: WorkingCopy) => TIME_FRAME_TYPES_LABELS[workingCopy.time_frame]},
  {id: 'created_at', label: 'Upload Time', orderingField: 'created_at', sortable: true, customRender: (workingCopy: WorkingCopy) => formatDateTime(workingCopy.created_at)},
];