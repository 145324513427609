import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  tooltip: {
    ...theme.typography.bodyXXS,
    display: 'inline-flex',
    padding: '0.5rem 1rem',
    alignItems: 'flex-start',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral.neutral700,
    boxShadow: `0rem 0.5rem 0.5rem 0rem ${theme.palette.primary.primary200}`,
  },
  popper: {
    zIndex: 10,
  }
}));