import { makeStyles} from '@material-ui/core/styles';

import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  defaultBox: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.info.main,
    backgroundColor: theme.palette.neutral.neutral0,
    border: `1px solid ${theme.palette.primary.primary500}`,
    width: 'auto',
    minHeight: '2.5rem',
    borderRadius: '0.5rem',
    padding: '0 1rem',
    position: 'relative',
    gap: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  infoBox: {
    backgroundColor: theme.palette.primary.primary100,
  },
}));