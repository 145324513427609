import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  hazmatIcon: {
    width: '2rem',
    height: '2rem',
    padding: '0px'
  },
  secondaryText: {
    color: theme.palette.neutral.neutral400,
  },
  customer: {
    width: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  quoteId: {
    color: theme.palette.primary.primary700,
    '&:hover': {
      color: theme.palette.primary.primary500,
    }
  }
}));