import React, { useEffect, useState } from 'react';
import { Checkbox, FormHelperText, TextField, Typography, debounce } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@mui/material';
import clsx from 'clsx';

import { Option } from 'types';

import { useStyles } from './Autocomplete.css';

type AutocompleteFilterProps = {
  options: Option[],
  onChange: (value: React.SetStateAction<string | number | (string | number)[]>) => void,
  multiple?: boolean,
  defaultValue?: number | string | (string | number)[] | null,
  label?: string,
  placeholder?: string,
  isHeader?: boolean,
  loading: boolean,
  error?: string,
  reserveErrorMsgSpace?: boolean,
  freeSolo?: boolean,
};

type ValueType = string | number | (string | number)[] | Option | Option[] | null;

export const AutocompleteFilter = ({
  options,
  onChange,
  multiple = false,
  defaultValue,
  label = '',
  placeholder,
  isHeader = false,
  loading,
  error,
  reserveErrorMsgSpace = false,
  freeSolo = false,
}: AutocompleteFilterProps) => {
  const classes = useStyles(isHeader);

  const [ value, setValue ] = useState<ValueType>(multiple ? [] : null);
  const [ inputValue, setInputValue ] = useState<string>('');
  const [ isInitialized, setIsInitialized ] = useState<boolean>(false);
  const [ selectedValues, setSelectedValues ] = useState<Option[]>([]);
  const [ filteredOptions, setFilteredOptions ] = useState<Option[]>([]);
  const [ newOptions, setNewOptions ] = useState<Option[]>(options);

  const handleSelection = (newValue: Option | Option[]) => {
    setSelectedValues(selectedValues.concat(newValue));
    setValue(newValue);
    setIsInitialized(true);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    setIsInitialized(true);
  };

  useEffect(() => {
    if (isInitialized) {
      if (multiple) {
        onChange((value as Option[])?.map((c: Option) => c.id) || []);
      } else {
        onChange(freeSolo ? inputValue : (value as Option)?.id);
      }
    }
  }, [ JSON.stringify(value), inputValue, isInitialized ]);

  useEffect(() => {
    if (!options || options.length === 0) {
      return;
    }

    const temporaryOptions = [...options].concat(selectedValues.length > 0 && selectedValues);
    const distinctiveOptions = [...new Map(temporaryOptions.filter(Boolean).map(option => [ option.id, option ])).values()];

    setNewOptions(distinctiveOptions);

    if (!multiple && defaultValue) {
      const defaultOption = temporaryOptions.find(option => freeSolo ? option.label === defaultValue : option.id === defaultValue);
      setValue(defaultOption || defaultValue);
      setInputValue(freeSolo ? defaultValue.toString() : defaultOption.label);
      setIsInitialized(true);
    }

    if (multiple && Array.isArray(defaultValue)) {
      if (!defaultValue.includes('all') && defaultValue.length > 0 ) {
        const newValues = defaultValue.map(value => {
          const option = temporaryOptions.find(option => option.id === value);
          return {id: option?.id, label: option?.label};
        });
        setValue(newValues);
        setSelectedValues(newValues);
      }
    }
  }, [JSON.stringify(options)]);

  const filterOptions = createFilterOptions({
    stringify: (option: Option) => option.label,
  });

  const debouncedFilterOptions = debounce((filtered: Option[]) => {
    setFilteredOptions(filtered);
  }, 0);

  const handleFilterOptions = (options: Option[], state: FilterOptionsState<Option>) => {
    const filtered = filterOptions(options, state);
    // Temporary workaround to set state with debounce
    debouncedFilterOptions(filtered);
    return filtered;
  };

  return (
    <div className={clsx({
      [classes.root]: true,
      [classes.errorField]: !!error
    })}>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Autocomplete
        filterOptions={handleFilterOptions}
        multiple={multiple}
        forcePopupIcon={!multiple}
        limitTags={1}
        options={newOptions.sort((a: Option, b: Option) => a.label?.localeCompare(b.label))}
        value={value}
        onChange={(event, newValue) => handleSelection(newValue as Option | Option[])}
        inputValue={inputValue}
        onInputChange={(event, newValue) => handleInputChange(newValue)}
        freeSolo={freeSolo}
        disableCloseOnSelect={multiple}
        loading={loading}
        getOptionLabel={option => (option as Option)?.label || option as string || ''}
        getOptionSelected={(option, value) => (option as Option).id === (value as Option).id}
        classes={{
          listbox: classes.listbox,
          option: classes.option,
          noOptions: classes.noOption,
          paper: freeSolo && filteredOptions.length === 0 ? classes.hiddenElement : classes.paper,
        }}
        renderOption={(option, { selected }) => (
          <>
            {multiple && (
              <Checkbox
                style={{ marginRight: 8 }}
                checked={selected} />
            )}
            {(option as Option).label || option}
          </>
        )}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} className={classes.placeholder} />
        )} />
      {reserveErrorMsgSpace ? <FormHelperText>{error || ' '}</FormHelperText> : error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
};
