import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { useStyles } from './TableSortIcon.css';

export const TableSortIcon = ({active, order} : {active: boolean, order: 'asc' | 'desc'}) => {
  const classes = useStyles();
  if (!active) {
    return <FontAwesomeIcon icon={faSort} className={classes.icon}/>;
  }
  const icon = order === 'asc' ? faSortUp : faSortDown;
  return <FontAwesomeIcon icon={icon} className={classes.activeIcon}/>;
};
