import React from 'react';
import { AxiosResponse } from 'axios';
import { QueryObserverResult, RefetchOptions, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { EmailField, FormModalContent, ServiceTypeField } from 'components';
import {
  QUOTE_COST_TYPE,
  QUOTE_SERVICE_TYPE,
  QUOTE_SOURCE,
  QUOTE_STATUS,
} from 'enums';
import {
  useMarkQuoteMutation,
  useRespondMutation
} from 'services';
import { PriceCurrency, QuoteDetails, TransplaceAuction } from 'types';
import { getValidationSchema } from './validation';

type MarkQuoteRespondedModalProps = {
  quote: QuoteDetails,
  isDirect?: boolean,
  priceCurrency: PriceCurrency,
  onCancel: () => void,
  refetchTransplaceAuction?: (options?: RefetchOptions) => Promise<QueryObserverResult<AxiosResponse<TransplaceAuction>, unknown>>
};

export const MarkQuoteRespondedModal = ({ quote, isDirect, priceCurrency, onCancel, refetchTransplaceAuction } : MarkQuoteRespondedModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: markAsResponded, isLoading: isMarkingQuote } = useMarkQuoteMutation(
    queryClient,
    {
      onSuccess: () => {
        onCancel();
        toast.success('Quote successfully marked as Responded');
      }
    }
  );
  const { mutate: respond, isLoading: isRespondingQuote } = useRespondMutation(
    queryClient,
    {
      onSuccess: () => {
        if (quote.quote_source === QUOTE_SOURCE.TRANSPLACE) {
          refetchTransplaceAuction();
        }
        onCancel();
        toast.success('Response successfully sent');
      }
    }
  );

  const handleSubmit = ({ email, service_type, cost_type }: { email?: string, service_type?: QUOTE_SERVICE_TYPE, cost_type?: QUOTE_COST_TYPE }) => {
    if (isDirect) {
      respond({
        id: quote.id,
        price: priceCurrency.price,
        email,
        service_type: +service_type,
        cost_type: +cost_type,
      });
    } else {
      markAsResponded({
        id: quote.id,
        status: QUOTE_STATUS.RESPONDED,
        price: priceCurrency.price,
        currency: priceCurrency.currency,
        cost_type: cost_type,
      });
    }
  };

  const getInitialValues = () => {
    if (isDirect) {
      if (quote.quote_source === QUOTE_SOURCE.QQT_UI
         || quote.quote_source === QUOTE_SOURCE.QQT_API) {
        return {
          email: quote.customer_email
        };
      } else {
        return {
          cost_type: quote.customer_cost_type,
          service_type: QUOTE_SERVICE_TYPE.TL
        };
      }
    }

    return {};
  };

  return (
    <FormModalContent
      initialValues={getInitialValues()}
      validationSchema={isDirect && (quote.quote_source === QUOTE_SOURCE.QQT_UI || quote.quote_source === QUOTE_SOURCE.QQT_API) && getValidationSchema}
      validateOnMount
      enableDivider={false}
      isActionInProgress={isMarkingQuote || isRespondingQuote}
      onCancel={onCancel}
      onSubmit={handleSubmit}>
      {() => (
        <>
          {(isDirect && (quote.quote_source === QUOTE_SOURCE.QQT_UI || quote.quote_source === QUOTE_SOURCE.QQT_API)) && <EmailField />}
          {(isDirect && (quote.quote_source !== QUOTE_SOURCE.QQT_UI && quote.quote_source !== QUOTE_SOURCE.QQT_API)) &&
          <>
            <ServiceTypeField />
          </>
          }
        </>
      )}
    </FormModalContent>
  );
};