import * as Yup from 'yup';
import { VALIDATION_REQUIRED_MSG } from 'enums';

export const getUploadWorkingCopyValidationSchema = (workingCopyId: number) => Yup.object({
  file: workingCopyId ? Yup.mixed() : Yup.mixed().required(VALIDATION_REQUIRED_MSG),
  name: Yup.string().required(VALIDATION_REQUIRED_MSG),
  customer: Yup.string().required(VALIDATION_REQUIRED_MSG),
  round: Yup.number().min(1, 'Min value is 1').required(VALIDATION_REQUIRED_MSG),
  account_manager: Yup.string().required(VALIDATION_REQUIRED_MSG),
  time_frame: Yup.string().required(VALIDATION_REQUIRED_MSG),
});