import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Pagination } from '@mui/material';
import clsx from 'clsx';

import { CollapsibleWidget, ConditionalWrapper, SadStates, TooltipComponent, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { DEFAULT_ROWS_PER_PAGE } from 'enums';
import { formatDateToDashboardPageFormat } from 'helpers';
import { useQuoteCarrierPreferenceQuery } from 'services';
import { Params } from 'types';

import { useStyles } from './CarrierPreference.css';

export const CarrierPreference = () => {
  const classes = useStyles();
  const { id } = useParams<Params>();
  const [ page, setPage ] = useState<number>(1);

  const { isError, isLoading, isSuccess, data } = useQuoteCarrierPreferenceQuery(Number(id), (page-1) * DEFAULT_ROWS_PER_PAGE);
  const { data: carriers } = data || {};

  return (
    <CollapsibleWidget headerTitle='Carrier Lane Preference Matches'>
      <SadStates states={[
        {
          when: isSuccess && carriers.results.length === 0,
          render: () => <WidgetNoResultsState message='No preferences available.'/>
        },
        {
          when: isError,
          render: () => <WidgetNoResultsState message='Something went wrong when trying to get carrier preferences.'/>
        },
      ]}>
        <Grid
          container spacing={1}
          direction='row'
          className={clsx({
            [classes.container]: true,
            [classes.secondaryText]: true,
          })}>
          <Grid item xs={4}>Carrier</Grid>
          <Grid item xs={5}>Contacts</Grid>
          <Grid item xs={3}>Last shipment / tracking</Grid>
        </Grid>
        <Divider/>
        <SadStates states={[
          {
            when: isLoading,
            render: () => <WidgetLoadingState/>
          },
        ]}>
          {
            carriers?.results.map((carrier) => (
              <div key={carrier.id}>
                <Grid container spacing={1} direction='row' className={classes.container}>
                  <Grid item xs={4}>
                    <TooltipComponent title={carrier.company_name}>
                      <div className={classes.text}>{carrier.company_name}</div>
                    </TooltipComponent>
                    <div>{carrier.dot_number || '/'}</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div>{carrier.contact_phone || '/'}</div>
                    <ConditionalWrapper
                      condition={!!carrier.contact_email}
                      wrapper={(children) =>
                        <TooltipComponent title={carrier.contact_email}>
                          {children}
                        </TooltipComponent>
                      }>
                      <div className={classes.text}>{carrier.contact_email || '/'}</div>
                    </ConditionalWrapper>
                  </Grid>
                  <Grid item xs={3}>
                    <ConditionalWrapper
                      condition={!!carrier.tracking_provider}
                      wrapper={(children) =>
                        <TooltipComponent title={carrier.tracking_provider}>
                          {children}
                        </TooltipComponent>
                      }>
                      <div>{carrier.tracking_provider || '/'}</div>
                    </ConditionalWrapper>
                    <div className={classes.secondaryText}>{formatDateToDashboardPageFormat(carrier.last_activity) || '/'}</div>
                  </Grid>
                </Grid>
                <Divider/>
              </div>
            ))
          }
        </SadStates>
        <Pagination
          className={classes.pagination}
          size='small'
          shape='rounded'
          variant='outlined'
          count={Math.ceil(carriers?.count / DEFAULT_ROWS_PER_PAGE) || 0}
          onChange={(e, value) => setPage(value)}
          page={page}/>
      </SadStates>
    </CollapsibleWidget>
  );
};