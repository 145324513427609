import React, { useMemo, useState } from 'react';
import { CircularProgress, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Grid, Tooltip, Typography } from '@mui/material';

import { faChevronDown, faChevronUp, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccessControl, ConditionalWrapper, DeleteFilterSetModal, ModalComponent, NewFilterSetModal, OverwriteFilterSetModal } from 'components';
import { FILTER_SET_SOURCE } from 'enums';
import { FILTER_SET_PERMISSIONS } from 'permissions';
import { DeleteIcon, Pencil } from 'resources';
import { useFilterSetsQuery } from 'services';
import { FilterParamsType, FilterSet, MenuItem as MenuItemType } from 'types';

import { useStyles } from './SaveFilterSets.css';

type SaveFilterSetsProps = {
  filterSource: FILTER_SET_SOURCE,
  filterParams: FilterParamsType,
  selectedFilterSet: FilterSet,
  setFilterParams: (paramValue: FilterParamsType) => void,
  setSelectedFilterSet: (filterSet: FilterSet) => void,
}

export const SaveFilterSets = ({
  filterSource,
  filterParams,
  selectedFilterSet,
  setFilterParams,
  setSelectedFilterSet,
}: SaveFilterSetsProps) => {
  const classes = useStyles();

  const [ anchorEl, setAnchorEl ] = useState<SVGSVGElement>(null);
  const isMenuOpen = !!anchorEl;
  const [ isSaveModalOpen, setIsSaveModalOpen ] = useState<boolean>(false);
  const [ isOverwriteModalOpen, setIsOverwriteModalOpen ] = useState<boolean>(false);
  const [ isRenameModalOpen, setIsRenameModalOpen ] = useState<boolean>(false);
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState<boolean>(false);
  const [ targetFilterSet, setTargetFilterSet ] = useState<FilterSet>(null);

  const { isLoading: isLoadingFilterSets, data: filterSetsData } = useFilterSetsQuery(filterSource);
  const { data: filterSets } = filterSetsData || {};

  const saveFilterSetsActions = useMemo(() => [
    {
      permissions: [FILTER_SET_PERMISSIONS.SAVE],
      text: 'Save as...',
      onClick: () => setIsSaveModalOpen(true),
    },
  ], [ filterSource, selectedFilterSet ]);

  const handleSelectFilterSet = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, filterSet: FilterSet) => {
    e.preventDefault();
    setSelectedFilterSet(filterSet);
    setFilterParams(filterSet.filter_values);
    setAnchorEl(null);
  };

  const handleDeleteFilterSet = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, filterSet: FilterSet) => {
    e.stopPropagation();
    setTargetFilterSet(filterSet);
    setIsDeleteModalOpen(true);
  };

  const handleRenameFilterSet = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, filterSet: FilterSet) => {
    e.stopPropagation();
    setTargetFilterSet(filterSet);
    setIsRenameModalOpen(true);
  };

  const onDeleteSuccess = () => {
    if (targetFilterSet.id === selectedFilterSet?.id) {
      setSelectedFilterSet(null);
    }
    setTargetFilterSet(null);
  };

  const onCancelRename = () => {
    setTargetFilterSet(null);
    setIsRenameModalOpen(false);
  };

  const onCancelDelete = () => {
    setTargetFilterSet(null);
    setIsDeleteModalOpen(false);
  };

  const onUpsertSuccess = (newFilterSet: FilterSet) => {
    // selectedFilterSet state should be updated after successful creation and rename of the selected filter set
    if (!targetFilterSet || selectedFilterSet?.id === targetFilterSet?.id) {
      setSelectedFilterSet(newFilterSet);
    }
  };

  return (
    <>
      <Grid className={classes.saveFiltersIconsContainer} >
        <AccessControl permissions={[FILTER_SET_PERMISSIONS.SAVE]}>
          <FontAwesomeIcon
            icon={faSave}
            className={classes.saveFiltersIcon}
            onClick={() => selectedFilterSet ? setIsOverwriteModalOpen(true) : setIsSaveModalOpen(true)} />
        </AccessControl>
        <AccessControl permissions={[ FILTER_SET_PERMISSIONS.RETRIEVE, FILTER_SET_PERMISSIONS.SAVE ]}>
          <FontAwesomeIcon
            icon={isMenuOpen ? faChevronUp : faChevronDown}
            onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => setAnchorEl(e.currentTarget)}
            className={classes.saveFiltersMenuArrowIcon} />
        </AccessControl>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          className: classes.menu
        }}
        variant='menu'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <AccessControl permissions={[FILTER_SET_PERMISSIONS.SAVE]}>
          <Grid className={classes.filterSetsActionsContainer}>
            {saveFilterSetsActions.map( (action: MenuItemType, index: number) => (
              <ConditionalWrapper
                condition={!!action.permissions}
                wrapper={(children) => <AccessControl permissions={action.permissions}>{children}</AccessControl>}
                key={index}>
                <>
                  <MenuItem
                    onClick={(e) => action.onClick?.(e)}
                    className={classes.menuItem}>
                    <ListItemText>{action.text}</ListItemText>
                  </MenuItem>
                </>
              </ConditionalWrapper>
            ))}
          </Grid>
        </AccessControl>
        <AccessControl permissions={[FILTER_SET_PERMISSIONS.RETRIEVE]}>
          <MenuItem className={classes.divider}>
            <ListItemText>Saved filter sets</ListItemText>
          </MenuItem>
          <Grid className={classes.savedFilterSetsContainer}>
            {isLoadingFilterSets ?
              <MenuItem className={classes.divider}><CircularProgress /></MenuItem> :
              filterSets.length === 0 ?
                <MenuItem className={classes.disabledMenuItem}>
                  <ListItemText>No saved filter sets</ListItemText>
                </MenuItem> :
                filterSets.map( (item: FilterSet, index: number) => (
                  <MenuItem
                    key={index}
                    onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => handleSelectFilterSet(e, item)}
                    className={classes.menuItem}
                    selected={item.id === selectedFilterSet?.id}>
                    <ListItemText>
                      <Tooltip title={item.name}>
                        <Typography className={classes.menuItemText}>
                          {item.name}
                        </Typography>
                      </Tooltip>
                    </ListItemText>
                    <AccessControl permissions={[FILTER_SET_PERMISSIONS.SAVE]}>
                      <Grid className={classes.menuItemIconContainer}>
                        <Pencil onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => handleRenameFilterSet(e, item)}/>
                        <DeleteIcon onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => handleDeleteFilterSet(e, item)}/>
                      </Grid>
                    </AccessControl>
                  </MenuItem>
                ))}
          </Grid>
        </AccessControl>
      </Menu>

      <ModalComponent
        message={targetFilterSet ? 'Rename filter set' : 'New filter set'}
        isOpen={targetFilterSet ? isRenameModalOpen : isSaveModalOpen}
        modalWidth='xs'
        onCancel={() => targetFilterSet ? onCancelRename() : setIsSaveModalOpen(false)}>
        <NewFilterSetModal
          filterSource={targetFilterSet ? targetFilterSet.filter_source: filterSource}
          filterParams={targetFilterSet ? targetFilterSet.filter_values: filterParams}
          targetFilterSet={targetFilterSet}
          onCancel={() => targetFilterSet ? onCancelRename() : setIsSaveModalOpen(false)}
          onUpsertSuccess={onUpsertSuccess}/>
      </ModalComponent>

      {selectedFilterSet &&
        <ModalComponent
          message={`By saving changes you will overwrite "${selectedFilterSet.name}"`}
          isOpen={isOverwriteModalOpen}
          modalWidth='xs'
          onCancel={() => setIsOverwriteModalOpen(false)}>
          <OverwriteFilterSetModal
            selectedFilterSetId={selectedFilterSet.id}
            sourceFilterValues={filterParams}
            onCancel={() => setIsOverwriteModalOpen(false)}/>
        </ModalComponent>
      }

      {targetFilterSet &&
        <ModalComponent
          message={`Are you sure you want to delete filter set "${targetFilterSet.name}"?`}
          isOpen={isDeleteModalOpen}
          onCancel={onCancelDelete}>
          <DeleteFilterSetModal
            filterSetId={targetFilterSet.id}
            onActionSuccess={onDeleteSuccess}
            onCancel={onCancelDelete}/>
        </ModalComponent>}
    </>
  );
};