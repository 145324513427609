import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => {
  const datepicketCommonStyles = {
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.neutral0,
    display: 'flex',
    height: '2.5rem',
    justifyContent: 'space-between',
    zIndex: 10,
    '& > div.react-datepicker-popper': {
      width: 'auto',
      zIndex: 10
    },
  };
  return {
    primary: {
      ...datepicketCommonStyles,
      boxSizing: 'border-box',
      border: '1px solid transparent',
      borderRadius: '8px',
      '& input': {
        ...theme.typography.bodyRegularS,
        color: theme.palette.neutral.neutral400,
        '&::placeholder': {
          color: theme.palette.neutral.neutral400,
          opacity: 1,
          textOverflow: 'ellipsis'
        }
      },
      '& > div': {
        paddingLeft: '.5rem',
        width: '100%',
        '& input': {
          width: '100%',
        }
      },
      '&:focus-within': {
        borderColor: theme.palette.info.main,
      },
      '&:hover:not(:focus-within)': {
        borderColor: theme.palette.info.main,
      },
      '& button': {
        '&:after': {
          display: 'none'
        }
      }
    },
    iconContainer: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0%, -50%)'
    },
    additionalPadding: {
      paddingLeft: '1.4rem'
    }
  };
});