import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  markWon: {
    '&.MuiButtonBase-root': {
      ...theme.typography.bodyRegularS,
      '& .MuiListItemIcon-root': {
        color: theme.palette.success.main,
      },
      '&:hover': {
        color: theme.palette.success.main,
        '& svg': {
          '& path': {
            fill: theme.palette.success.main,
          }
        }
      }
    },
  },
  markLost: {
    '&.MuiButtonBase-root': {
      ...theme.typography.bodyRegularS,
      '& .MuiListItemIcon-root': {
        color: theme.palette.error.main,
      },
      '&:hover': {
        color: theme.palette.error.main,
        '& svg': {
          '& path': {
            fill: theme.palette.error.main,
          }
        }
      }
    },
  },
}));