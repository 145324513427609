export const apiUrl = () => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (r.test(window.REACT_APP_API_ENDPOINT)) {
    return window.REACT_APP_API_ENDPOINT;
  } else {
    return window.location.origin + window.REACT_APP_API_ENDPOINT;
  }
};

export const config = {
  isTraining: window.REACT_APP_IS_QA
};

export const API_URL = apiUrl();
