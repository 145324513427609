import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  icon: {
    fontSize: '0.875rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menu: {
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      height: 'auto',
      marginTop: '40px',
      width: '12.5rem',
    },
  },
  menuHeader: {
    backgroundColor: theme.palette.secondary.secondary700,
    color: theme.palette.neutral.neutral0,
    padding: '10px 15px',
    '& > li': {
      ...theme.typography.bodyRegularS,
      padding: '2px 0',
      '& p': {
        lineClamp: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
    },
    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondary.secondary700,
    },
    '& .MuiMenuItem-root': {
      [theme.breakpoints.down('sm')]: {
        minHeight: '10px',
      },
    }
  },
  menuOptions: {
    color: theme.palette.secondary.secondary700,
    '& > li': {
      ...theme.typography.bodyRegularS,
      gap: 10,
      padding: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.primary100,
      },
    },
  },
}));