import { v4 as uuidv4 } from 'uuid';
import { Action, ActionConfig, ActionType, Expression, ExpressionType, Literal, Operation, Value, ValueType, Variable, } from 'types';

const initLiteralValue = (resultType: ValueType): Value => {
  switch (resultType) {
    case ValueType.BOOLEAN:
      return false;
    case ValueType.NUMBER:
      return 0;
    case ValueType.STRING:
    default:
      return '';
  }
};

export const initLiteral = (resultType: ValueType, priority = 1): Expression => {
  return {
    id: uuidv4(),
    priority,
    type: ExpressionType.LITERAL,
    value: initLiteralValue(resultType),
  };
};

export const initExpression = (
  resultType: ValueType,
  type: ExpressionType,
  previousExpression: Expression,
): Expression => {
  const expression: Partial<Expression> = {
    id: uuidv4(),
    priority: previousExpression.priority,
    type,
  };

  switch (expression.type) {
    case ExpressionType.LITERAL:
      return <Literal>{
        ...expression,
        value: initLiteralValue(resultType),
      };
    case ExpressionType.VARIABLE:
      return <Variable>{
        ...expression,
        value: null,
      };
    case ExpressionType.OPERATION:
    default:
      return <Operation>{
        ...expression,
        name: null,
        args: [],
      };
  }
};

export const initAction = (
  actionsConfig: ActionConfig,
  priority = 1,
): Action => {
  const action: Partial<Action> = {
    priority,
    name: actionsConfig.name,
  };

  let action_data;
  switch (actionsConfig.action_type) {
    case ActionType.SETTER:
      action_data = {
        expression: initLiteral(actionsConfig.config.result_type as ValueType),
      };
      break;
    default:
      action_data = null;
  }

  return <Action> {
    ...action, action_data
  };
};