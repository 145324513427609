import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, Tooltip, useTheme } from '@material-ui/core';

import { AccessControl, Icons } from 'components';

import { ThemeDefault } from 'types/ThemeDefaultTypes';
import { SidebarButtonConfig } from './SidebarConfig';

import { useStyles } from './SidebarButton.css';

type SidebarButtonProps = Omit<SidebarButtonConfig, 'urlRoutes'|'priority'> & {
  active: boolean;
}

export const SidebarButton =({active, permissions, urlTo, tooltip, iconName}: SidebarButtonProps) => {
  const classes = useStyles();
  const theme = useTheme() as ThemeDefault;
  const activeColor = theme.palette.primary.primary700;
  const neutralColor = theme.palette.secondary.secondary700;

  return (
    <AccessControl permissions={permissions}>
      <ListItem button component={Link} to={urlTo} className={classes.listItemButton} disableGutters>
        <Tooltip title={tooltip} classes={{ tooltip: classes.lightTooltip }}>
          <ListItemIcon className={classes.listItemIcon}>
            <Icons iconName={iconName} strokeColor={active ? activeColor : neutralColor} fillColor={active ? activeColor : neutralColor}/>
          </ListItemIcon>
        </Tooltip>
      </ListItem>
    </AccessControl>
  );
};