import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  icon: {
    color: theme.palette.secondary.secondary200,
  },
  activeIcon: {
    color: theme.palette.secondary.secondary500,
  },
}));