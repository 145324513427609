import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  actionButton: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '1rem',
    '& div': {
      alignItems: 'center',
      border: `1px solid ${theme.palette.secondary.secondary500}`,
      borderRadius: '8px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      padding: '.75rem'
    },
    '& svg': {
      color: theme.palette.neutral.neutral700,
      fill: theme.palette.neutral.neutral700,
    }
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '3rem',
    position: 'absolute',
    right: '.5rem',
    top: '4.875rem',
  },
  headerButton: {
    height: '40px'
  },
  loadingSpinnerBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    '& .MuiCircularProgress-svg': {
      color: theme.palette.primary.primary700,
    }
  },
  logInfoContainer: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  titleText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
  },
  switchBox: {
    alignItems: 'center',
    display: 'flex',
    '& p': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral700
    }
  },
  switchCustom: {
    width: '36px',
    height: '14px',
    padding: 0,
    marginLeft: '10px',
    borderRadius: 8,
    display: 'flex',
    overflow: 'visible',
    backgroundColor: theme.palette.secondary.secondary500,
    '& .MuiSwitch-switchBase': {
      color: theme.palette.neutral.neutral100,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: theme.palette.neutral.neutral400,
      },
      '&:hover': {
        backgroundColor: 'none'
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      borderRadius: 8,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      display: 'none'
    },
  },
  tabHeader: {
    color: theme.palette.common.black,
    marginTop: '.5rem',
    minHeight: 'unset',
    '& button': {
      ...theme.typography.bodyRegularS,
      height: '2rem',
      minHeight: 'unset',
      minWidth: 'unset',
      width: '8rem',
      textWrap: 'nowrap',
    },
    '& .MuiTabs-flexContainer': {
      gap: '2rem',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.primary700,
    },
    '& .Mui-selected': {
      color: theme.palette.primary.primary700,
    },
    '& .MuiTab-textColorInherit': {
      opacity: 1
    },
    '& .MuiTab-wrapper': {
      ...theme.typography.bodyRegularS,
      alignItems: 'flex-start',
      whiteSpace: 'nowrap',
      width: 'auto'
    }
  },
  tabPanel: {
    ...scrollStyle as CSSProperties,
    padding: 0,
  },
  valueText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.common.black,
  },
}));
