import { FilterConfigType, FilterType, Location } from 'types';

export enum FILTER_SET_SOURCE {
  QUOTE = 1,
  AWARD = 2,
  INTEGRATION_LOG = 3,
  WORKING_COPY = 4,
  FILE = 5,
}

export enum FILTER_PAGE_SOURCE {
  QUOTE = 'Quote',
  SHIPPER = 'Shipper',
  SHIPPER_RULE = 'ShipperRule',
  AWARD = 'Award',
  AWARD_LANE = 'AwardLane',
  INTEGRATION_LOG = 'IntegrationLog',
  WORKING_COPY = 'WorkingCopy',
  GLOBAL_RULE = 'GlobalRule',
  FILE = 'File',
}

export const DEFAULT_FILTER_VALUES = {
  [FILTER_PAGE_SOURCE.QUOTE]: {
    'createdBy': ['all'],
    'createdAt': {
      'fromDate': null as Date,
      'toDate': null as Date,
    },
    'search': '',
    'shipper': ['all'],
    'status': ['all'],
    'equipment': ['all'],
    'respondedBy': ['all'],
    'origin': null as Location,
    'destination': null as Location,
  },
  [FILTER_PAGE_SOURCE.SHIPPER]: {
    'search': '',
  },
  [FILTER_PAGE_SOURCE.SHIPPER_RULE]: {
    'search': '',
  },
  [FILTER_PAGE_SOURCE.AWARD]: {
    'search': '',
    'shipper': ['all'],
  },
  [FILTER_PAGE_SOURCE.AWARD_LANE]: {
    'origin': '',
    'destination': '',
  },
  [FILTER_PAGE_SOURCE.INTEGRATION_LOG]: {
    'createdAt': {
      'fromDate': null as Date,
      'toDate': null as Date,
    },
    'search': '',
    'shipper': ['all'],
    'integration': ['all'],
    'statusCode': ['all'],
  },
  [FILTER_PAGE_SOURCE.WORKING_COPY]: {
    'search': '',
    'shipper': ['all'],
  },
  [FILTER_PAGE_SOURCE.GLOBAL_RULE]: {
    'search': '',
  },
  [FILTER_PAGE_SOURCE.FILE]: {
    'category': ['all'],
    'uploadedBy': ['all'],
    'search': '',
    'status': ['all'],
  },
};

export const QUOTES_FILTER_CONFIG: FilterConfigType[] = [
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'shipper',
    placeholder: 'Shippers',
    initialValue: ['all'],
    enableSearch: true
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'status',
    placeholder: 'Status',
    initialValue: ['all']
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'equipment',
    placeholder: 'Equipment',
    initialValue: ['all']
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'createdBy',
    placeholder: 'Created by',
    initialValue: ['all'],
    enableSearch: true
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'respondedBy',
    placeholder: 'Responded by',
    initialValue: ['all'],
    enableSearch: true
  },
  {
    type: FilterType.DateRange,
    paramsProperty: 'createdAt',
    placeholder: 'From - To',
    initialValue: {
      fromDate: null,
      toDate: null,
    }
  },
  {
    type: FilterType.LocationSearch,
    paramsProperty: 'origin',
    placeholder: 'Origin',
    initialValue: null,
  },
  {
    type: FilterType.LocationSearch,
    paramsProperty: 'destination',
    placeholder: 'Destination',
    initialValue: null,
  },
];

export const INTEGRATION_LOGS_FILTER_CONFIG: FilterConfigType[] = [
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'statusCode',
    placeholder: 'Status Code',
    initialValue: ['all'],
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'integration',
    placeholder: 'Integration',
    initialValue: ['all'],
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'shipper',
    placeholder: 'Shippers',
    initialValue: ['all'],
    enableSearch: true
  },
  {
    type: FilterType.DateRange,
    paramsProperty: 'createdAt',
    placeholder: 'From - To',
    initialValue: {
      fromDate: null,
      toDate: null,
    }
  },
];

export const FILE_FILTER_CONFIG: FilterConfigType[] = [
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'uploadedBy',
    placeholder: 'Uploaded by',
    initialValue: ['all'],
    enableSearch: true
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'status',
    placeholder: 'Status',
    initialValue: ['all']
  },
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'category',
    placeholder: 'Category',
    initialValue: ['all']
  },
];

export const AWARDS_FILTER_CONFIG: FilterConfigType[] = [
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'shipper',
    placeholder: 'Shippers',
    initialValue: ['all'],
    enableSearch: true
  },
];

export const WORKING_COPIES_FILTER_CONFIG: FilterConfigType[] = [
  {
    type: FilterType.MultiSelect,
    paramsProperty: 'shipper',
    placeholder: 'Shippers',
    initialValue: ['all'],
    enableSearch: true
  },
];
