import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  text: {
    ...theme.typography.bodyM,
    color: theme.palette.inactive.main,
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    justifyContent: 'center'
  },
  skeleton: {
    height: '1.5rem',
    borderRadius: '8px',
    background: theme.palette.neutral.neutral100,
  },
}));