import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { SadStates, TableErrorState, TableLoadingState, TableNoResultsState } from 'components';
import { handleRequestSort } from 'helpers';
import {
  Column,
  DataType,
  Identified,
  TableDataType,
} from 'types';
import { BulkMenu, BulkMenuOption } from './BulkMenu/BulkMenu';
import { TablePagination } from './TablePagination/TablePagination';
import { TableSortIcon } from './TableSortIcon/TableSortIcon';

import { useStyles } from './TableComponent.css';

type TableComponentProps = {
  columns: Column[],
  isLoading: boolean,
  isSuccess: boolean,
  isError?: boolean,
  page?: number,
  renderMenu?: (param: DataType) => React.ReactNode,
  bulkMenuConfig?: BulkMenuOption[],
  areRowsSelectable: boolean,
  pageRows?: number,
  tableData: TableDataType,
  tableId?: string,
  rows?: number,
  selectedRows?: TableDataType | Identified[],
  setOrdering?: (ordering: React.SetStateAction<string>) => void,
  defaultOrderBy?: string,
  defaultOrder?: 'asc' | 'desc',
  setPage?: React.Dispatch<React.SetStateAction<number>>,
  setPageRows?: React.Dispatch<React.SetStateAction<number>>,
  isTablePaginated?: boolean,
  setSelectedRows?: React.Dispatch<React.SetStateAction<TableDataType | Identified[]>>,
  rowClickHandler?: (dataId: number) => void,
  isDragAndDropDisabled?: boolean,
  getElementDragDisabled?: (obj: DataType) => boolean,
  handleDragAndDrop?: (sourceIndex: number, destinationIndex: number) => void,
}

export const TableComponent = ({
  columns,
  tableData,
  isLoading,
  isSuccess,
  isError = false,
  areRowsSelectable,
  rows,
  pageRows,
  setPageRows,
  page,
  setPage,
  selectedRows,
  setSelectedRows,
  setOrdering,
  defaultOrderBy,
  defaultOrder,
  renderMenu,
  bulkMenuConfig,
  rowClickHandler,
  tableId = 'id',
  isTablePaginated = true,
  isDragAndDropDisabled = true,
  getElementDragDisabled,
  handleDragAndDrop,
}: TableComponentProps) => {

  const [ order, setOrder ] = useState<'asc' | 'desc'>(defaultOrder);
  const [ orderBy, setOrderBy ] = useState<string>(defaultOrderBy);
  const numberOfColumns = areRowsSelectable ? columns.length + 2 : columns.length + 1;
  const classes = useStyles();
  const key = tableId as keyof DataType;

  useEffect(() => {
    const orderSign = order === 'asc' ? '' : '-';
    setOrdering ? setOrdering(orderSign + orderBy) : null;
    setSelectedRows && setSelectedRows([]);
  }, [ order, orderBy ]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    setSelectedRows && setSelectedRows([]);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageRows(Number(event.target.value));
    setPage(0);
    setSelectedRows && setSelectedRows([]);
  };

  const handleCheckboxSelect = (data: DataType) => {

    let newSelectedRows = [...selectedRows];
    if (isRowSelected(data[key])) {
      newSelectedRows = selectedRows.filter((row) => row[key] !== data[key]);
    } else {
      newSelectedRows.push(data);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !result.source || !handleDragAndDrop) {
      return;
    }
    handleDragAndDrop(result.source.index, result.destination.index);
  };

  const isRowSelected = (id: unknown) => {
    return selectedRows.some((c) => c[key] === id);
  };

  return (
    <SadStates states={[
      {
        when: isSuccess && tableData.length === 0,
        render: () => <TableNoResultsState/>
      },
    ]}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader size='medium'>
            <TableHead>
              <TableRow>
                { selectedRows?.length > 1 && bulkMenuConfig ?
                  <BulkMenu
                    numberOfColumns={numberOfColumns}
                    config={bulkMenuConfig}
                    checked={selectedRows.length === tableData.length}
                    indeterminate={selectedRows.length < tableData.length && selectedRows.length !== 0}
                    handleChange={(e, checked) => {
                      const newSelectedRows= checked ? [...tableData] : [];
                      setSelectedRows(newSelectedRows);
                    }}/> :
                  <>
                    { areRowsSelectable && <TableCell className={classes.headerCell}/>}
                    { columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.alignment || 'left'}
                        color='primary'
                        sortDirection={orderBy === column.id ? order : false}
                        className={classes.headerCell}>
                        { column.sortable ?
                          <TableSortLabel
                            className={classes.sortLabel}
                            IconComponent={() => <TableSortIcon active={orderBy === column.orderingField} order={order}/>}
                            active={orderBy === column.orderingField}
                            direction={orderBy === column.orderingField ? order : 'asc'}
                            onClick={() => {
                              setOrder(handleRequestSort(column.orderingField, orderBy, order));
                              setOrderBy(column.orderingField);
                            }}>
                            {column.label}
                          </TableSortLabel>
                          : column.label
                        }
                      </TableCell>
                    ))}
                    {renderMenu && <TableCell className={classes.headerCell}/>}
                  </>
                }
              </TableRow>
            </TableHead>
            <Droppable droppableId='table-rows' isDropDisabled={isDragAndDropDisabled}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  <SadStates states={[
                    {
                      when: isLoading,
                      render: function renderLoadingState() {
                        return <TableLoadingState colSpan={numberOfColumns}/>;
                      }
                    },
                    {
                      when: isError,
                      render: function renderErrorState() {
                        return <TableErrorState classes={classes} colSpan={numberOfColumns}/>;
                      }
                    }
                  ]}>
                    {
                      isSuccess &&
                        tableData.map((data, index: number) => (
                          <Draggable draggableId={data.id.toString()} index={index} key={data[key]} isDragDisabled={isDragAndDropDisabled || (getElementDragDisabled && getElementDragDisabled(data))}>
                            {(provided) => (
                              <TableRow
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                hover
                                tabIndex={-1}
                                key={data[key]}
                                className={clsx({
                                  [classes.tableRow]: true,
                                  [classes.pointer]: !!rowClickHandler})}
                                onClick={rowClickHandler ? () => rowClickHandler(Number(data.id)) : null}>
                                { areRowsSelectable &&
                                <TableCell className={classes.checkboxTableCell}>
                                  <Checkbox
                                    checked={isRowSelected(data[key])}
                                    color='primary'
                                    onClick={(e) => {
                                      handleCheckboxSelect(data);
                                      e.stopPropagation();
                                    }}/>
                                </TableCell>
                                }
                                { columns.map(column => (
                                  <TableCell key={column.id}>
                                    {column.customRender ? column.customRender(data) : data[column.id as keyof DataType]}
                                  </TableCell>
                                ))}
                                { renderMenu ?
                                  <TableCell
                                    align='right'
                                    onClick={(e) => e.stopPropagation()}>
                                    {renderMenu(data)}
                                  </TableCell>
                                  : null
                                }
                              </TableRow>
                            )}
                          </Draggable>
                        ))
                    }
                  </SadStates>
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
      { isTablePaginated &&
      <TablePagination
        count={rows}
        page={page+1}
        rowsPerPage={pageRows}
        onChangePage={(e, page) => handleChangePage(e, page-1)}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]}/>
      }
    </SadStates>
  );
};