import React from 'react';
import DatePicker from 'react-datepicker';
import { InputBase } from '@material-ui/core';

import { calendarInputIcon } from 'resources';
import { DateRangeType } from 'types';

import { useStyles } from './NDateRangePicker.css';
import 'react-datepicker/dist/react-datepicker.css';

type CustomInputProps = {
  value?: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  addCalendarIcon?: boolean,
  placeholderText: string
};

const CustomDatePickerInput = ({ value, onClick, placeholderText, addCalendarIcon = false } : CustomInputProps) => {
  const classes = useStyles();
  return (
    <>
      {
        addCalendarIcon &&
        <label className={classes.iconContainer}>
          <img src={calendarInputIcon}/>
        </label>
      }
      <InputBase className={addCalendarIcon && classes.additionalPadding} placeholder={placeholderText} onClick={onClick} value={value}/>
    </>
  );
};

type DateRangePickerProps = {
  value: DateRangeType,
  setValue: (dateRange: DateRangeType) => void,
  showTime?: boolean,
  variant?: string,
};

export const NDateRangePicker = ({ setValue, value, showTime, variant='primary' } : DateRangePickerProps) => {
  const timeProps = showTime ? {
    'showTimeInput': true,
    'timeInputLabel': 'Time:',
    'shouldCloseOnSelect': false,
    'dateFormat': 'MM/dd/yyyy h:mm aa'
  } : null;
  const classes = useStyles();

  const setDateValue = (property: string, newValue: Date) => {
    setValue({
      ...value,
      [property]: newValue
    });
  };

  const handleFromPlaceholder = () => {
    if (!value.fromDate && value.toDate) {
      return 'From -';
    }
    if (!value.fromDate && !value.toDate) {
      return 'From - To';
    }
    return '';
  };

  const handleToPlaceholder = () => {
    if (value.fromDate && !value.toDate) {
      return 'To';
    }
    return '';
  };

  return (
    <div className={classes[variant as keyof typeof classes]}>
      <DatePicker
        customInput={<CustomDatePickerInput placeholderText={handleFromPlaceholder()} addCalendarIcon={true}/>}
        selected={value.fromDate}
        onChange={(dateValue) => setDateValue('fromDate', dateValue)}
        selectsStart
        isClearable
        startDate={value.fromDate}
        endDate={value.toDate}
        enableTabLoop={false}
        {...timeProps}/>
      <DatePicker
        customInput={<CustomDatePickerInput placeholderText={handleToPlaceholder()}/>}
        selected={value.toDate}
        onChange={(dateValue) => setDateValue('toDate', dateValue)}
        selectsEnd
        isClearable
        startDate={value.fromDate}
        endDate={value.toDate}
        minDate={value.fromDate}
        enableTabLoop={false}
        {...timeProps}/>
    </div>
  );
};