import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import { FormikErrors } from 'formik';

import { LocationSearch } from 'components';
import { STOP_ACTIVITY_TYPES } from 'enums';
import { Location, AwardLaneForm, Stop } from 'types';

import { useStyles } from './AwardLaneStopDisplay.css';

export type AwardLaneStopDisplayProps = {
  errors: FormikErrors<AwardLaneForm>,
  values: AwardLaneForm,
  setValues: (values: AwardLaneForm, shouldValidate?: boolean) => void,
  activity_type: number,
  index: number,
};

export const AwardLaneStopDisplay = ({ errors, values, setValues, activity_type, index }: AwardLaneStopDisplayProps) => {
  const classes = useStyles();
  const label = activity_type === STOP_ACTIVITY_TYPES.PICKUP ? 'Pickup Location' : 'Delivery Location';
  const error = (errors?.lane?.stops?.[index] as FormikErrors<(Stop)>)?.address || '';

  return (
    <Grid container alignItems='center' alignContent='flex-end' spacing={1} className={classes.stops}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl error={!!error} fullWidth>
          <LocationSearch
            defaultValue={values?.lane?.stops[index]?.address}
            label={label}
            hasError={!!error}
            onSelect={(location: Location) => {
              const newFields: Stop[] = [...values.lane.stops];
              Object.keys(location).forEach((key) => {
                newFields[index][`${key}`] = location[key];
              });
              setValues({ ...values });
            }} />
          {error && <FormHelperText className={classes.errorMsg}>{error}</FormHelperText>}
          <Grid item xs='auto'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.lane.stops[index].drop_trailer}
                  onChange={(_, checked) => {
                    const newFields = [...values.lane.stops];
                    newFields[index].drop_trailer = checked;
                    setValues({ ...values});
                  }}
                  icon={<CheckBoxOutlineBlankTwoToneIcon fontSize='small' viewBox='3 3 18 18' />} />
              }
              label='Drop Trailer' className={classes.dropTrailerLabel} />
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};