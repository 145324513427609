import React from 'react';
import { Grid } from '@material-ui/core';

import { QuoteDetails as QuoteDetailsType } from 'types';

import { formatDateToDashboardPageFormat } from 'helpers';
import { Collapse } from '../Collapse/Collapse';
import { ShipperDetailItem } from '../ShipperDetailItem/ShipperDetailItem';

import { useStyles } from './QuoteInfo.css';

type QuoteInfoProps = {
  quote: QuoteDetailsType
}

export const QuoteInfo = ({ quote } : QuoteInfoProps) => {
  const classes = useStyles();
  return (
    <Collapse hideText='Hide' showText='More Details'>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Declared Value'
            value={quote.declared_value?.toString()}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Weight (lbs)'
            value={quote.weight?.toString()}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Team'
            value={quote.team ? 'Yes' : 'No'}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Effective date'
            value={formatDateToDashboardPageFormat(quote.effective_date)}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Expiration Date'
            value={formatDateToDashboardPageFormat(quote.expiration_date)}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Load Number'
            value={quote.load_number}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Created By'
            value={quote.created_by_name}
            valueSize='sm' />
        </Grid>
        <Grid item xs={4}>
          <ShipperDetailItem
            label='Pallets'
            value={quote.pallet_count?.toString()}
            valueSize='sm' />
        </Grid>
      </Grid>
    </Collapse>
  );
};
