import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  checkbox: {
    '& svg': {
      height: '1rem',
      width: '1rem',
    }
  },
  divider: {
    margin: '.5rem 0',
  },
  headerItem: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '2rem',
    '& span': {
      padding: 0
    },
    '& .MuiPaper-root': {
      marginTop: '-0.75rem',
    },
    '& .Mui-checked': {
      '& svg': {
        fill: theme.palette.primary.primary700
      }
    },
  },
  headerItemText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.common.black,
    cursor: 'pointer',
    marginLeft: '.625rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  historyFiltersWrapper: {
    display: 'flex',
    '& button': {
      marginRight: 10
    },
    paddingBottom: '0.5rem'
  },
  pagination: {
    '& button': {
      ...theme.typography.bodyRegularXS,
      backgroundColor: theme.palette.neutral.neutral100,
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: theme.palette.primary.primary200
      }
    },
    '& ul': {
      gap: '2px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.primary700,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.primary200,
        color: theme.palette.common.black,
      }
    }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.4rem',
    width: '100%',
  },
  selectItem: {
    alignItems: 'center',
    marginLeft: 'auto',
    minWidth: '8.5rem',
    color: theme.palette.neutral.neutral100,
    '& span': {
      padding: 0
    },
    '& .MuiPaper-root': {
      marginTop: '-0.75rem',
    },
    '& .Mui-checked': {
      '& svg': {
        fill: theme.palette.primary.primary700
      }
    },
    '& .MuiInputBase-root': {
      borderColor: theme.palette.neutral.neutral400,
      borderRadius: '8px'
    },
  },
}));

