import { ThemeDefault } from 'types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  actionSection: {
    display: 'flex',
    gap: '0.5rem',
    height: '2.5rem',
  },
  buttonIcon: {
    fontSize: '.85rem !important'
  },
  idSection: {
    alignItems: 'center',
    display: 'flex',
    gap: '1.125rem',
    '& .MuiChip-label': {
      overflow: 'unset',
    },
  },
  mainInfoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    width: '100%',
  },
  quoteInfoHeader: {
    flex: 1,
  },
  quoteDate: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.neutral.neutral400
  },
  infoBox: {
    marginBottom: '1.25rem',
  },
}));