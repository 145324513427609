export const QUOTE_PERMISSIONS = {
  RETRIEVE: 'quote.view_quote',
  CREATE: 'quote.add_quote',
  UPDATE: 'quote.change_quote',
  MARK_QUOTE: 'quote.mark_quote',
  DUPLICATE_QUOTE: 'quote.duplicate_quote',
  DELETE: 'quote.delete_quote',
  RESPOND: 'quote.send_response',
  REJECT: 'quote.send_response',
  UPDATE_MARGIN: 'quote.update_margin',
};