import React, { useState } from 'react';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import { Grid } from '@material-ui/core';

import { EquipmentIcon, ModalComponent, SetEquipmentTypeModal, TooltipComponent} from 'components';
import { useAuth } from 'contexts';
import { checkPermissions } from 'helpers';
import { EQUIPMENT_PERMISSIONS } from 'permissions';
import { dropTrailerBadge, quoteHazmatIcon } from 'resources';
import { Quote, QuoteDetails as QuoteDetailsType } from 'types';

import { useStyles } from './QuoteEquipment.css';

type QuoteEquipmentProps = {
  quote: Quote,
  refetchQuote: (options?: RefetchOptions) => Promise<QueryObserverResult<AxiosResponse<QuoteDetailsType>, unknown>>
};

export const QuoteEquipment = ({ quote, refetchQuote }: QuoteEquipmentProps) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const [ isEquipmentTypeModalOpen, setIsEquipmentTypeModalOpen ] = useState(false);

  const handleQuoteEquipmentClick = () => {
    if (!quote.equipment_type && checkPermissions(currentUser.permissions, [EQUIPMENT_PERMISSIONS.UPDATE])) {
      setIsEquipmentTypeModalOpen(true);
    }
  };

  return (
    <>
      <Grid container spacing={1} alignItems='center'>
        <Grid item className={classes.quoteStops} onClick={handleQuoteEquipmentClick}>
          <EquipmentIcon
            equipmentType={quote.equipment_type}
            equipmentName={quote.equipment_name}
            disabled={!!quote.equipment_type || !checkPermissions(currentUser.permissions, [EQUIPMENT_PERMISSIONS.UPDATE])} />
        </Grid>
        {
          quote.lane.stops.some(stop => !stop.live_handling) &&
            <Grid item className={classes.quoteStops}>
              <TooltipComponent title='Drop Trailer'>
                <img src={dropTrailerBadge} />
              </TooltipComponent>
            </Grid>
        }
        {
          quote.hazmat &&
            <Grid item>
              <TooltipComponent title='Hazmat'>
                <img src={quoteHazmatIcon}/>
              </TooltipComponent>
            </Grid>
        }
      </Grid>
      <ModalComponent
        message={`Set equipment type for "${quote.equipment_name}"`}
        isOpen={isEquipmentTypeModalOpen}
        onCancel={() => setIsEquipmentTypeModalOpen(false)}>
        <SetEquipmentTypeModal equipmentId={quote.equipment} onCancel={() => setIsEquipmentTypeModalOpen(false)} refetchQuote={refetchQuote} />
      </ModalComponent>
    </>
  );
};