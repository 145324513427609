import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: 10,
  },
  formControl: {
    width: '100%',
    '&.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5,
    },
    '& > div > div.MuiOutlinedInput-input': {
      padding: 9,
    },
    '& > div > .MuiSelect-iconOutlined': {
      right: 18
    }
  }
}));