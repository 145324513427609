import axios from 'axios';
import { useQuery } from 'react-query';

import { API_URL } from '../config';

export const rule_engine_items_api = {
  items: {
    list: (list_api_url: string, filters: string, size?: number, offset?: number, ordering?: string) => axios.get(`${API_URL}/v1/${list_api_url}/?${filters}&limit=${size}&offset=${offset}&ordering=${ordering}`),
  }
};

export const useGetRuleEngineItemsQuery = (list_api_url: string, input?: string, size?: number, offset?: number, ordering? : string) => {
  return useQuery(
    [ 'items', list_api_url, input, size, offset, ordering ],
    () => rule_engine_items_api.items.list(list_api_url, `search=${input}`, size, offset, ordering),
    { enabled: input ? !!input : true, keepPreviousData: true }
  );
};