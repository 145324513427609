export enum WORKING_COPY_TYPES {
  PENDING,
  PROCESSED,
  PROCESSED_WITH_ERRORS
}

export const WORKING_COPY_TYPES_LABEL: Record<string, string> = {
  [WORKING_COPY_TYPES.PENDING]: 'Pending',
  [WORKING_COPY_TYPES.PROCESSED]: 'Processed',
  [WORKING_COPY_TYPES.PROCESSED_WITH_ERRORS]: 'Processed with errors',
};

export enum TIME_FRAME_TYPES {
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  OTHER
}

export const TIME_FRAME_TYPES_LABELS: Record<string, string> = {
  [TIME_FRAME_TYPES.ONE_MONTH]: 'One month',
  [TIME_FRAME_TYPES.THREE_MONTHS]: 'Three months',
  [TIME_FRAME_TYPES.SIX_MONTHS]: 'Six months',
  [TIME_FRAME_TYPES.ONE_YEAR]: 'One year',
  [TIME_FRAME_TYPES.OTHER]: 'Other',
};