import * as Yup from 'yup';

import { MAX_FUEL_PRICE_DELAY, VALIDATION_REQUIRED_MSG } from 'enums';

export const getFuelSheetValidationSchema = (fuelSheetId: number) => Yup.object({
  file: fuelSheetId ? Yup.mixed() : Yup.mixed().required(VALIDATION_REQUIRED_MSG),
  region: Yup.string().required(VALIDATION_REQUIRED_MSG),
  fuel_price_delay: Yup.number()
    .required(VALIDATION_REQUIRED_MSG)
    .min(0, 'Fuel price delay must be greater than or equal to 0')
    .max(MAX_FUEL_PRICE_DELAY, `Fuel price delay must be less than or equal to ${MAX_FUEL_PRICE_DELAY}`),
});