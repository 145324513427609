import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    height: '2.5rem',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon > *:first-child': {
        fontSize: '0.875rem'
      },
    },
  },
  actionsWrapper: {
    display: 'flex',
    gap: 20,
  },
}));