import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, ModalComponent } from 'components';
import { useDeleteFuelSheetsMutation } from 'services';

type DeleteFuelSheetsModalContentProps = {
    selectedFuelSheets: {id: number}[],
    onDeleteSuccess: () => void,
    onCancel: () => void,
  };

const DeleteFuelSheetsContentModal = ({
  selectedFuelSheets,
  onDeleteSuccess,
  onCancel
} : DeleteFuelSheetsModalContentProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteFuelSheets, isLoading } = useDeleteFuelSheetsMutation(queryClient, {
    onSuccess: () => {
      toast.success('Fuel sheet(s) successfully deleted.');
      onDeleteSuccess();
      onCancel();
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const deleteFuelSheetsHandler = () => {
    const ids = selectedFuelSheets.map(fuelSheetIds => fuelSheetIds.id);
    deleteFuelSheets(ids);
  };

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={deleteFuelSheetsHandler}
      onCancel={onCancel}/>
  );
};

type DeleteFuelSheetsModalProps = {
  selectedFuelSheets: {id: number}[],
  isModalOpen: boolean,
  onCancel: () => void,
  onDeleteSuccess: () => void
};

export const DeleteFuelSheetsModal = ({ isModalOpen, onCancel, selectedFuelSheets, onDeleteSuccess } : DeleteFuelSheetsModalProps) => {
  return (
    <ModalComponent
      message={`Are you sure you want to delete ${selectedFuelSheets.length} fuel sheets?`}
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <DeleteFuelSheetsContentModal selectedFuelSheets={selectedFuelSheets} onDeleteSuccess={onDeleteSuccess} onCancel={onCancel}/>
    </ModalComponent>
  );
};