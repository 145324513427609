import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles(((theme: ThemeDefault) => ({
  divider: {
    backgroundColor: theme.palette.primary.primary200,
    margin: '0.25rem 0',
    width: '100%',
  },
  moreOptions: {
    display: 'flex',
  },
  title: {
    width: '12rem',
  },
  collapsed: {
    color: theme.palette.neutral.neutral700,
    '& svg': {
      color: theme.palette.neutral.neutral700,
    },
  },
  notCollapsed: {
    color: theme.palette.primary.primary700,
    '& svg': {
      color: theme.palette.primary.primary700,
    },
  },
})));
