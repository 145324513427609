import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, ModalComponent } from 'components';
import { useDeleteAwardsMutation } from 'services';

type DeleteAwardsModalContentProps = {
    selectedAwards: {id: number}[],
    onDeleteSuccess: () => void,
    onCancel: () => void,
  };

const DeleteAwardsContentModal = ({
  selectedAwards,
  onDeleteSuccess,
  onCancel
} : DeleteAwardsModalContentProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteAwards, isLoading } = useDeleteAwardsMutation(queryClient, {
    onSuccess: () => {
      onDeleteSuccess();
      onCancel();
      toast.success('Awards successfully deleted.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
    }
  });

  const deleteAwardsHandler = () => {
    const ids = selectedAwards.map(awardIds => awardIds.id);
    deleteAwards({ awards: ids });
  };

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={deleteAwardsHandler}
      onCancel={onCancel}/>
  );
};

type DeleteAwardsModalProps = {
  selectedAwards: { id: number }[],
  message: string,
  isModalOpen: boolean,
  onCancel: () => void,
  onDeleteSuccess: () => void
};

export const DeleteAwardsModal = ({ selectedAwards, message, isModalOpen, onCancel, onDeleteSuccess } : DeleteAwardsModalProps) => {
  return (
    <ModalComponent
      message={message}
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <DeleteAwardsContentModal selectedAwards={selectedAwards} onDeleteSuccess={onDeleteSuccess} onCancel={onCancel}/>
    </ModalComponent>
  );
};