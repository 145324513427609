import { INTEGRATION_TYPES_LABEL } from 'enums';
import { formatDateTime, generateStatusTitle } from 'helpers';
import { Column, IntegrationLog } from 'types';

export const integrationLogTableColumns : Column[] = [
  {id: 'created_at', label: 'Timestamp', sortable: true, orderingField: 'created_at', customRender: (log: IntegrationLog) => formatDateTime(log.created_at)},
  {id: 'status_code', label: 'Status Code', sortable: false, customRender: (log: IntegrationLog) => generateStatusTitle(log.status_code)},
  {id: 'targeted_api', label: 'Targeted API', sortable: false},
  {id: 'integration', label: 'Integration', sortable: false, customRender: (log: IntegrationLog) => INTEGRATION_TYPES_LABEL[log.integration]},
  {id: 'shipper_name', label: 'Shipper', sortable: false, customRender: (log: IntegrationLog) => log.shipper_name}
];