import React, { FC } from 'react';
import { OutlinedInput, Checkbox } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { ValueType, Value } from 'types';

import expressionEditorStyles from 'components/RuleEngine/ExpressionEditor.css';

interface EditorProps<T extends Value> {
  value: T;
  onUpdate: (value: T) => void;
  styles: ClassNameMap;
  isReadOnly?: boolean
}

interface ValueEditorProps {
  value: Value;
  onUpdate: (value: Value) => void;
  valueType: ValueType;
  isReadOnly?: boolean
}

const BooleanEditor: FC<EditorProps<boolean>> = ({ styles, value, onUpdate, isReadOnly=false }) => {
  return (
    <Checkbox
      className={styles['expression-node-wrapper__header-control']}
      checked={value}
      onChange={e => onUpdate(e.target.checked)}
      readOnly={isReadOnly}/>
  );
};

const NumberEditor: FC<EditorProps<number>> = ({ styles, value, onUpdate, isReadOnly=false }) => {
  return (
    <OutlinedInput
      className={styles['expression-node-wrapper__header-control']}
      type='number'
      defaultValue={value}
      onChange={e => onUpdate(Number(e.target.value))}
      readOnly={isReadOnly} />
  );
};

const StringEditor: FC<EditorProps<string>> = ({ styles, value, onUpdate, isReadOnly=false }) => {
  return (
    <OutlinedInput
      className={styles['expression-node-wrapper__header-control']}
      type='text'
      defaultValue={value}
      onChange={e => onUpdate(e.target.value)}
      readOnly={isReadOnly} />
  );
};

const ValueEditor: FC<ValueEditorProps> = ({ value, onUpdate, valueType, isReadOnly=false }) => {
  const styles = expressionEditorStyles();
  return (
    <>
      {(() => {
        switch (valueType) {
          case ValueType.BOOLEAN:
            return (<BooleanEditor styles={styles} value={value as boolean} onUpdate={onUpdate} isReadOnly={isReadOnly}/>);
          case ValueType.NUMBER:
            return (<NumberEditor styles={styles} value={value as number} onUpdate={onUpdate} isReadOnly={isReadOnly}/>);
          case ValueType.STRING:
            return (<StringEditor styles={styles} value={value as string} onUpdate={onUpdate} isReadOnly={isReadOnly}/>);
        }
      })()}
    </>
  );
};

export default ValueEditor;
